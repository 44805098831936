<template>
  <div>

    <template v-if="$route.meta.requiresAuth">

        <div id="agencySwitcher" class="btn-group d-print-none me-3">

          <div class="dropdown" id="searchAgencies">

              <button id="searchAgenciesButton" class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              @click="getAgencies"
              >
                <i class="fa fa-landmark-dome"></i> 
                <template v-if="current_agency && current_agency.name">
                  {{ current_agency.name }}
                </template>

              </button>

                <div
                  class="dropdown-menu p-2"
                  :class="{ 'show': isDropdownOpen }"
                  aria-labelledby="searchAgenciesButton"
                  style="overflow-y: auto; max-height: 600px; width: 400px;"
                >
                  <p class="dropdown-header">Switch Agency</p>

                  <form @submit.prevent="handleSubmit">
                    <div class="input-group">
                      <label
                        class="visually-hidden"
                        for="search_agencies"
                      >Search</label>
                      <input
                        id="search_agencies"
                        v-model="search_agencies"
                        type="text"
                        class="form-control"
                        placeholder="Search agencies..."
                        @keydown.down.prevent="navigateList('down')"
                        @keydown.up.prevent="navigateList('up')"
                        @keydown.enter.prevent="handleEnter"
                        @keydown.esc="closeDropdown"
                      >
                      <button
                        class="btn btn-outline-primary"
                        type="submit"
                        :disabled="processing"
                      >
                        <template v-if="!processing">
                          <i class="fa fa-search"></i> <span class="visually-hidden">Search</span>
                        </template>
                        <template v-if="processing">
                          <loader :isLoading="processing" />
                        </template>
                      </button>
                    </div>

                    <template v-if="isGlobalDomain">
                      <div class="mt-2">
                        <fieldset>
                          <legend class="mb-0 fs-6">Region</legend>
                          <template v-for="region in regions">
                            <div class="form-check form-check-inline">
                              <input
                                :id="region"
                                v-model="agencies_region"
                                type="radio"
                                :name="region"
                                :value="region"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label"
                                :for="region"
                              >
                                {{ region }}
                              </label>
                            </div>
                          </template>
                        </fieldset>
                      </div>
                    </template>

                    <div class="mt-2">
                      <label for="agencies_sort" class="visually-hidden">Sort</label>
                      <select id="agencies_sort" class="form-select" v-model="agencies_sort" @change="getAgencies" :disabled="processing">
                        <template v-for="agency_sort in agency_sorts">
                          <option :value="agency_sort.value">Sort by {{ agency_sort.title }}</option>
                        </template>
                      </select>
                    </div>

                  </form>

                  <template v-if="agencies && agencies.length > 0">
                    <a
                      v-for="(agency, index) in agencies"
                      :key="agency.uuid"
                      class="dropdown-item d-flex align-items-center justify-content-between"
                      :class="{ 
                        'active': isActiveAgency(agency),
                        'keyboard-selected': currentIndex === index 
                      }"
                      @click="switchAgency(agency)"
                      ref="agencyItems"
                    >
                      <span :class="isPrivacyMode ? 'privacy-mode' : ''">
                        {{ agency.name }}<br>
                        <small class="text-muted">{{ agency.reference }} {{ agency.region }}</small>
                      </span>
                      <template v-if="agency.status == 0">
                        <span class="badge bg-danger rounded-pill ms-2">INACTIVE</span>
                      </template>
                      <template v-if="agency.status == 2">
                        <span class="badge bg-success rounded-pill ms-2">NEW</span>
                      </template>
                    </a>

                  </template>
                </div>

              </div><!--AgencyDropdown-->

          <div class="dropdown">
            <button class="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-globe"></i>
            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <p class="dropdown-header">Switch Region</p>
              <template v-for="region in regions">
                <template v-if="region != $root.current_region">
                <a
                    class="dropdown-item"
                    :href="'https://'+region+'.ecourtdate.com'"
                    target="_blank"
                >
                  <template v-if="region == 'app'">
                    main
                  </template>
                  {{ region }}</a>
                </template>
              </template>
            </div>
          </div>

        </div><!--Group-->

      </template>
</div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            agencies: [],
            processing: false,
            search_agencies: '',
            agencies_region: '',
            agencies_sort: 'name_asc',
            agency_sorts: [
              {title: 'Name a-z', value: 'name_asc'},
              {title: 'Name z-a', value: 'name_desc'},
              {title: 'Reference a-z', value: 'reference_asc'},
              {title: 'Reference z-a', value: 'reference_desc'},
              {title: 'Status active', value: 'status_desc'},
              {title: 'Status inactive', value: 'status_asc'}
            ],
            regions: ['app', 'us-east', 'us-west', 'us-south', 'us-texas', 'staging'],
            current_agency: {},
            isDropdownOpen: false,
            currentIndex: -1,
        }
    },

    computed: {

        isGlobalDomain() {

            var localURL = process.env.VUE_APP_API_URL;

            if(localURL != null) {
                return false;
            }

            const host = window.location.host;
            const parts = host.split('.');

            if (parts[0] === 'app') {
              return true;
            }

            return false;
        },

    },

    created() {
      this.getCurrentAgency();

      // Close dropdown when clicking outside
      document.addEventListener('click', (e) => {
        if (!this.$el.contains(e.target)) {
          this.closeDropdown();
        }
      });

      window.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.key === 'k' || e.metaKey && e.key === 'k') {
          e.preventDefault();
          document.getElementById('searchAgenciesButton').click();
          document.getElementById('search_agencies').focus();
          this.isDropdownOpen = true;
        }
      });
    },


    methods: {

      getCurrentAgency() {

        if(this.settings && this.settings.name) {
          if(this.current_agency && this.current_agency.uuid === this.settings.uuid) {
            return;
          }
        }

        this.$http.get('/settings')
        .then(response => {
          if(response && response.data) {
            this.current_agency = response.data;
          }
        })
      },

        getAgencies() {
            this.processing = true;
            this.isDropdownOpen = true;
            this.currentIndex = -1;
            this.agencies = [];

            var sort = 'name';
            var direction = 'asc';

            if(this.agencies_sort == 'name_asc') {
              sort = 'name';
              direction = 'asc';
            }

            if(this.agencies_sort == 'name_desc') {
              sort = 'name';
              direction = 'desc';
            }

            if(this.agencies_sort == 'reference_asc') {
              sort = 'reference';
              direction = 'asc';
            }

            if(this.agencies_sort == 'reference_desc') {
              sort = 'reference';
              direction = 'desc';
            }

            if(this.agencies_sort == 'status_asc') {
              sort = 'status';
              direction = 'asc';
            }

            if(this.agencies_sort == 'status_desc') {
              sort = 'status';
              direction = 'desc';
            }

            this.$http.get('/agencies?limit=25&slim=y&fields=uuid,name,reference,region,status&search='+this.search_agencies+'&region='+this.agencies_region+'&sort='+sort+'&sortDirection='+direction)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.agencies = response.data;
                }
            })
        },

        switchAgency (agency) {

          this.$http.post('switch_agency/'+agency.uuid+'?region='+agency.region)
          .then(response => {
            if (response && response.status === 200) {

              this.clearAgencySession();

              location.reload();
            }
          })
        },

        clearAgencySession() {
          
          var fields = ['fields', 'timezone', 'agency', 'send_mode', 'settings'];

          fields.forEach(field => {
            localStorage.removeItem(field);
          })

        },

        isActiveAgency(agency) {

            if(agency.uuid === localStorage.getItem('agency')) {
                return true;
            }

            return false;
        },

        handleSubmit(e) {
          e.preventDefault();
          this.getAgencies();
          // Keep dropdown open
          this.isDropdownOpen = true;
        },

        navigateList(direction) {
          if (this.agencies.length === 0) return;
          
          if (direction === 'down') {
            if (this.currentIndex === -1 || this.currentIndex === this.agencies.length - 1) {
              this.currentIndex = 0;
            } else {
              this.currentIndex++;
            }
          } else {
            if (this.currentIndex === -1 || this.currentIndex === 0) {
              this.currentIndex = this.agencies.length - 1;
            } else {
              this.currentIndex--;
            }
          }
          
          // Scroll item into view if needed
          if (this.currentIndex >= 0 && this.$refs.agencyItems) {
            this.$refs.agencyItems[this.currentIndex]?.scrollIntoView({
              block: 'nearest',
              behavior: 'smooth'
            });
          }
        },

        handleEnter() {
          if (this.currentIndex >= 0 && this.agencies[this.currentIndex]) {
            this.switchAgency(this.agencies[this.currentIndex]);
          } else {
            this.getAgencies();
          }
        },

        closeDropdown() {
          this.isDropdownOpen = false;
          this.currentIndex = -1;
        },

    },
    
}
</script>

<style scoped>
.highlighted {
  background-color: var(--bs-primary);
  color: white;
}

.dropdown-item.highlighted small {
  color: #f8f9fa !important;
}

#searchAgencies {
  position: relative;
}

.dropdown-menu.show {
  position: absolute;
  right: auto;
  left: 0;
  top: 100%;
  margin-top: 2px;
  transform: none !important;
}

/* Add styles for keyboard navigation */
.keyboard-selected {
  background-color: var(--bs-primary);
  color: white !important;
}

.keyboard-selected small {
  color: #f8f9fa !important;
}

.keyboard-selected:hover {
  background-color: var(--bs-primary);
  color: white !important;
}
</style>
<template>
  <div>

    <page-tabs page="clients" />
    
    <div class="row">
      <div class="col-sm-12 col-md-3">

        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                      <template v-if="!client.first_name || !client.last_name">
                        {{ client.name }}
                      </template>
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-end">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    :disabled="processing"
                    @click="closeClient()"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="content" class="form-label">Comment</label>
                <textarea id="content" class="form-control" rows="5" v-model="form.content" required></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->            

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
            </p>

            <div class="horizontal-scroll">
              <table class="table table-striped table-hover">
                <sort-columns
                  :columns="columns"
                  :queries="queries"
                  :processing="processing"
                  :base_url="$route.meta.base_url"
                />

                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit" /> Edit
                        </a>
                      </td>
                      <td>{{ record.content }}</td>
                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>
                      <td>{{ record.case }}</td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>

              <template v-if="!processing && records && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {
    mixins: [ queries ],
    data () {
        return {
            processing: false,
            form: {},
            total: 0,
            records: [],
            queries: {},
            columns: [
                { label: 'Edit'},
                { key: 'content', label: 'Content'},
                { key: 'client', label: 'Client'},
                { key: 'case', label: 'Case'},
                { key: 'created_by', label: 'Creator'},
                { key: 'created_at', label: 'Created'}
            ],
            client: {},
            search_client: {}
        }
    },

    mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();
        self.getRecords();

    });

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.form.client = uuid;

       self.searchRecords();

    });

    },

    created () {
        this.resetQueries();
        this.resetRecords();
        this.resetForm();
        this.setURLParams();
        this.getClient();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

            if(this.queries.client) {
              this.form.client = this.queries.client;
            }

        },

        resetForm() {
          this.form = {
            client: null,
            content: null
          }
        },

        resetQueries() {
          this.queries = {
            client: null,
            client_reference: null,
            slim: true,
            limit: 10,
            skip: 0,
            sort: 'created_at',
            sortDirection: 'desc'
          }
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        resetRecords() {
          this.records = [];
          this.total = 0;
          this.queries.skip = 0;
        },

        getTotal() {
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status) {
              this.total = response.data;
              this.setTitle();
            }
          })
        },

        getRecords() {
          this.processing = true;

          this.getTotal();
          this.updateURL();
          
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.status) {
              this.records = response.data;
              this.processing = false;
            }
          })
        },

        postForm() {
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.status) {

              if(response.status === 201) {
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' added'
                });
                this.records.unshift(response.data);
                this.total++;
                this.resetForm();

                if(this.queries.client) {
                  this.form.client = this.queries.client;
                }
              }

              this.processing = false;
            }
          })
        },

        getClient() {

            if(!this.queries.client) {
                this.client = {};
                this.getRecords();
                return;
            }

            var queries = {
              slim: true,
              fields: 'uuid,client_reference,first_name,last_name,name,group,type'
            }

            if(this.queries.client_reference) {
              queries.ref = this.queries.client_reference;
            }

            this.$http.get(this.buildQueries('/clients/'+this.queries.client, queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.uuid) {
                      this.client = response.data;
                      this.form.client = this.client.uuid;
                      this.getRecords();
                    }

                }
            })
        },

        resetClient() {
          this.form.client = null;
          this.queries.client = null;
          this.client = {};
        },

        closeClient() {
          this.resetClient();
          this.searchRecords();
        },



    }
}
</script>

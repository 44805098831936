<template>
  <div>

    <page-tabs page="cases" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>
            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="firm_name">Firm Name</label>
                <input
                  id="firm_name"
                  v-model="form.firm_name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>
              <div class="form-group">
                <label for="bar_number">Bar Number</label>
                <input
                  id="bar_number"
                  v-model="form.bar_number"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="first_name">First Name</label>
                <input
                  id="first_name"
                  v-model="form.first_name"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="last_name">Last Name</label>
                <input
                  id="last_name"
                  v-model="form.last_name"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="phone">Phone</label>
                <input
                  id="phone"
                  v-model="form.phone"
                  type="tel"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  v-model="form.email"
                  type="email"
                  class="form-control"
                >
              </div>
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">
              <p class="card-title">
                Search {{ $route.meta.title }}s
              </p>

              <div class="form-group">
                <label for="bar_number">Bar Number</label>
                <input
                  id="bar_number"
                  v-model="queries.bar_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="firm_name">Firm Name</label>
                <input
                  id="firm_name"
                  v-model="queries.firm_name"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="phone">Phone</label>
                <input
                  id="phone"
                  v-model="queries.phone"
                  type="tel"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  v-model="queries.email"
                  type="email"
                  class="form-control"
                >
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-end">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Edit</th>
                  <th>Bar Number</th>
                  <th>Firm Name</th>
                  <th>Attorney</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
              </thead>

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.bar_number }}
                      </td>
                      <td>{{ record.firm_name }}</td>
                      <td>{{ record.first_name }} {{ record.last_name }}</td>
                      <td>{{ record.phone }}</td>
                      <td>{{ record.email }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
              </div><!--Responsive-->

              <template v-if="records && records.length == 0">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found
                </div>
              </template>
            
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

        <div class="row my-3">
          <div class="col-12 text-center">

              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>    

          </div><!--Col-->
        </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries'

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: true,
            records: [],
            queries: {},
            total: 0,
            form: {}
        }
    },
    created () {
        this.resetQueries();
        this.getRecords();
        this.resetForm();
    },

    methods: {

      resetQueries() {
        this.queries = {
          sort: 'created_at',
          sortDirection: 'desc',
          search: '',
          limit: 10
        }
      },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getRecords () {

            this.updateURL();

            this.getTotal();
            this.processing = true
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if (response && response.data) {

                    var self = this;
                    response.data.forEach(data => {
                        self.records.push(data);
                    })

                    this.queries.skip = this.records.length;

                    this.processing = false;
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        resetForm() {
            this.form = {
                firm_name: null,
                bar_number: null,
                first_name: null,
                last_name: null,
                phone: null,
                email: null
            }
        },

        postForm () {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if (response && response.data) {
                        
                    if(response.data.uuid) {
                        this.resetForm();
                        this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' added'
                        });
                        this.records.unshift(response.data);
                        this.total++;
                    }

                    this.processing = false;
                }
                
            })
        }
    }
}
</script>

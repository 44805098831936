<template>
  <div id="main" class="app" @scroll="handleScroll">
    <nav-bar />

    <template v-if="$route.meta.requiresAuth">

      <div class="container-fluid mb-3">

        <template v-if="$root.current_user && $root.current_user.current_agency">
        <template v-if="!$root.current_user.super_admin || $root.current_user.super_admin != 1">
          <template v-if="!$root.current_user.permissions || $root.current_user.permissions.length === 0">
            <div class="row" :hidden="processing">
              <div class="col-12">
                <div class="alert alert-danger my-3 d-print-none" data-html2canvas-ignore>
                  <i class="fa fa-exclamation-triangle"></i> You do not have any permissions assigned to your account. Please contact an administrator to assign permissions to your account.
                </div>
              </div><!--Col-->
            </div><!--Row-->
          </template>
        </template>
      </template>


      <div class="row">
        <div class="col-sm-12 col-md-8">
          <h1 class="m-0 p-0" @click="toggleHelp()">
            <template v-if="['Status', 'Address'].includes($route.meta.title)">
              {{ $route.meta.title }}es
            </template>
            <template v-else-if="['Dashboard', 'Settings', 'Identities', 'Identity', 'Referrals', 'Agency Setup', 'Agency Checklist', 'Availability', 'Billing', 'Training', 'Agencies'].includes($route.meta.title)">
              {{ $route.meta.title }}
            </template>
            <template v-else>
              <span class="text-capitalize">{{ setCustomLabel($route.meta.title) }}</span>s
            </template>
          </h1>
        </div><!--Col-->

        <div class="col-sm-12 col-md-4 d-flex justify-content-end">  
          <agency-switcher />        
          <deactivated-alert />
        </div><!--Col-->
      </div><!--Row-->

      <template v-if="show_help">
        <help-promo :help="help" />
      </template>

      <training-mode />

      </div><!--Container-->
    </template>

    <div class="px-3">
      <app-errors />
      <router-view />
    </div>

    <template v-if="$route.meta.requiresAuth">
      <help-center />
      <footer-bar />

      <template v-if="$route.name != 'report_issue'">
        <div class="report-container ml-3 mb-3 d-print-none" data-html2canvas-ignore>
        <a :href="'/report_issue?url='+setCurrentURL()" target="_blank" class="btn btn-danger d-print-none tooltip-left" data-bs-toggle="tooltip" data-tooltip="Report an Issue" aria-label="Report an Issue" data-html2canvas-ignore><i class="fa fa-exclamation-circle"></i> 
        </a>
        </div>
      </template>
    </template>

    <app-toast ref="toast" />
  </div>
  
</template>
<style scoped>
.report-container {position: absolute; bottom:0;right:10px;z-index:9}
</style>
<script>
import auth from '../mixins/auth';
import queries from '../mixins/queries';

export default {

  mixins: [ auth, queries ],

    data() {
        return {
          processing: false,
          show_help: false,
          preferences: {},
          help: {},
        }
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },

    mounted() {
      this.getPreferences();

      this.$eventBus.$on('preferencesUpdated', this.setUserPreferences);
    },

  created() {

      if(this.$route.meta.requiresAuth) {
        this.getCurrentUser();
        this.getHelp();

        if(localStorage.getItem('theme')) {
          this.setAppTheme();
        }
      }

    },

  methods: {

    getPreferences() {

        if(!this.$route.meta.requiresAuth) {
            return;
        }

      if(this.$route.name == 'no-agencies') {
        return;
      }

      this.processing = true;

      this.$http.get('/users/preferences')
          .then(response => {
            if(response && response.data) {
              localStorage.setItem('preferences', JSON.stringify(response.data));
              this.setUserPreferences(response.data);
              this.processing = false;
            }
          })
    },

    setUserPreferences(preferences) {
      this.preferences = preferences;
      this.$root.preferences = preferences;
      this.setAppTheme();
      this.setAppFields();
    },

    setAppTheme() {

      let theme = this.preferences['theme'] || localStorage.getItem('theme') || 'light';

      localStorage.setItem('theme', theme);

      if (theme == 'auto' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = 'dark';
      }

      if(theme == 'light') {
        theme = 'light';
      }

      document.body.setAttribute('data-bs-theme', theme);
    },

    setAppFields() {
      if(this.preferences['client_fields']) {
        localStorage.setItem('client_fields', JSON.stringify(this.preferences['client_fields']));
        this.$eventBus.$emit('setClientFields');
      }

      if(this.preferences['event_fields']) {
        localStorage.setItem('event_fields', JSON.stringify(this.preferences['event_fields']));
        this.$eventBus.$emit('setEventFields');
      }
    },

    setToolClass(path) {
      if(this.$route.path == '/'+path) {
        return 'btn-outline-primary';
      }

      return 'btn-outline-secondary';
    },

    handleScroll() {
      if (window.scrollY > 100) {
        this.$eventBus.$emit('scrolling', true);
      } else {
        this.$eventBus.$emit('scrolling', false);
      }
    },

    getHelp() {
        this.$axios.get('https://assets.ecourtdate.com/js/app_help.json')
        .then(response => {
            if(response && response.data) {
                this.help = response.data;

                if(!this.help[this.$route.meta.base_url]) {
                  this.show_help = false;
                }
            }
        })
    },

    toggleHelp() {

      if(!this.help[this.$route.meta.base_url]) {
        return;
      }

      this.show_help = !this.show_help;
    },

    setCurrentURL() {
      return window.location.href;
    }


  }
}
</script>

<template>
    <div>
    
      <page-tabs page="bulk_actions" />
      
      <div class="row">
        <div class="col-sm-12 col-lg-3 mb-4">
          <div class="card">
            <div class="card-body">
  
                  <p class="card-title">
                    Add {{ $route.meta.title }}
                  </p>
  
              <form @submit.prevent="postForm">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                  >
                </div>
  
                <div class="form-group">
                  <label for="action">Action Template</label>
                  <select
                    id="action"
                    v-model="form.action"
                    class="form-control text-capitalize"
                    :disabled="processing"
                    required
                  >
                  <option value="" />
                  <template v-for="action in actions">
                    <option :value="action.uuid">
                      {{ action.name }}
                    </option>
                  </template>
                   
                  </select>
                </div>
  
                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Add
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
  
  
          <div class="card mt-3">
            <div class="card-body">
  
              <div class="row">
  
                <div class="col-sm-12 col-md-9">
  
                    <p class="card-title">
                    Search {{ $route.meta.title }}s
                    </p>
  
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-3 text-end">
  
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                    @click="resetSearch()"
                    :disabled="processing"
                    data-bs-toggle="tooltip"
                    data-tooltip="Reset Search"
                  >
                    <i class="fa fa-eraser"></i>
                  </button>
  
                </div><!--Col-->
  
              </div>
  
              <form @submit.prevent="searchRecords">
                <div class="form-group">
                  <label for="search_name">Search</label>
                  <input
                    id="search_name"
                    v-model="queries.search"
                    class="form-control"
                  >
                </div>
  
                <div class="form-group">
                  <label for="search_creator">Creator</label>
                  <select
                    id="search_creator"
                    v-model="queries.created_by"
                    class="form-control"
                    :disabled="processing"
                  >
                    <template v-if="users && users.length > 0">
                      <option value="" />
                      <template v-for="user in users">
                        <option
                          :value="user.email"
                        >
                          {{ user.email }}
                        </option>
                      </template>
                    </template>
                  </select>
                </div>
  
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                        id="search_archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="form-check-input"
                        :disabled="processing"
                        @change="searchRecords"
                      >
                      <label
                        class="form-check-label"
                        for="search_archived"
                      >Archived</label>
                    </div>
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                        id="search_trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="form-check-input"
                        :disabled="processing"
                        @change="searchRecords"
                      >
                      <label
                        class="form-check-label"
                        for="search_trashed"
                      >Trashed</label>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->
  
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="searching || processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-lg-9">
          <div class="row">
            <div class="col-md-12">
  
              <div class="card">
                <div class="card-body">
  
                  <div class="row">
  
                  <div class="col-sm-12 col-md-6">
  
                    <p class="card-title">
                      {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                    </p>
  
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6 text-end">
  
                    <div class="btn-group d-print-none" :hidden="!total">
                      <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                      <button type="button" class="btn btn-secondary" @click="downloadCSV()" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                    </div>
                  </div><!--Col-->
  
                  </div><!--Row-->
  
                  <div class="table-responsive my-3">
  
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <template v-for="column in columns">
                          <th>
                            <template v-if="column.key">
                              <span
                                class="text-capitalize link-hover"
                                @click="sortColumn(column)"
                              >{{ column.label }}</span>
                            </template>
  
                            <template v-if="!column.key">
                              <span class="text-capitalize">{{ column.label }}</span>
                            </template>
  
                            <template v-if="column.key == queries.sort">
                              <button
                                type="button"
                                class="btn btn-outline-secondary btn-sm ml-1"
                                :disabled="processing"
                                @click="updateSortDirection()"
                              >
                                <template v-if="queries.sortDirection == 'asc'">
                                  <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                                </template>
  
                                <template v-if="queries.sortDirection == 'desc'">
                                  <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                                </template>
                              </button>
                            </template>
                          </th>
                        </template>
                      </tr>
                    </thead>
  
                    <tbody>
                      <template v-if="records && records.length">
                        <template v-for="record in records">
                          <tr>
                            <td>
                              <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                                <i class="fa fa-edit"></i> Edit
                              </a>
                            </td>
                            <td>{{ record.name }}</td>
                            <td>
                              <template v-if="record.action">
                                {{ setActionName(record) }}
                              </template>
                            </td>
                            <td>{{ record.frequency }}</td>
                            <td>{{ record.status }}</td>
                            <td>
                              <template v-if="record.start_date">
                                {{ record.start_date | date }}
                              </template>
                            </td>
                            <td>
                              <template v-if="record.end_date">
                                {{ record.end_date | date }}
                              </template>
                              <template v-if="!record.end_date">
                                Ongoing
                              </template>
                            </td>
                            <td>{{ record.created_by }}</td>
                            <td>{{ record.created_at | datetime }}
  
                              <template v-if="record.deleted_at">
                                <span class="badge badge-pill badge-danger">TRASHED</span>
                              </template>
  
                              <template v-if="record.archived_at">
                                <span class="badge badge-pill badge-warning">ARCHIVED</span>
                              </template>
  
                            </td>
                            <td>
                              <template v-if="record.updated_at">
                                {{ record.updated_at | datetime }}
                              </template>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                  </div><!--Responsive-->
  
  
                  <template v-if="records && !records.length && !processing">
                    <div class="alert alert-warning">
                      No {{ $route.meta.title }}s found.
                    </div>
                  </template>
                </div><!--Body-->
              </div><!--Card-->
  
            </div><!--Col-->
          </div><!--Row-->
  
          <template v-if="records && records.length && total > records.length">
            <div class="row mt-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
                >
                  Load more
                </button>
              </div><!--Col-->
            </div><!--Col-->
          </template>
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  
  
  <script>
  import queries from '../mixins/queries';
  import _ from 'lodash';
  
  export default {
  
  mixins: [ queries ],
  
  data() {
  
      return {
          processing: false,
          searching: false,
          records: [],
          users: [],
          queries: {},
          form: {},
          total: 0,
          actions: [],
          columns: [
          {label: 'Edit'},
          {label: 'name', key: 'name'},
          {label: 'action', key: 'action'},
          {label: 'frequency', key: 'frequency'},
          {label: 'status', key: 'status'},
          {label: 'start date', key: 'start_date'},
          {label: 'end date', key: 'end_date'},
          {label: 'creator', key: 'created_by'},
          {label: 'created', key: 'created_at'},
          {label: 'updated', key: 'updated_at'}
          ]
      }
  },
    
  created() {
  
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getUsers();
    this.getBulkActions();
    
  },
  
  methods: {
  
        setTitle() {
            document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },
  
        resetQueries() {
          this.queries = {
            skip: 0,
            limit: 10,
            search: null,
            created_by: null,
            type: null,
            status: null,
            trashed: false,
            archived: false,
            sort: 'created_at',
            sortDirection: 'desc'
          }
        },
  
        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);
  
            var self = this;
  
            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });
        },
  
        resetSearch() {
          this.resetQueries();
          this.searchRecords();
        },
  
        searchRecords() {
          this.resetRecords();
          this.getRecords();
        },
  
        resetRecords() {
          this.records = [];
          this.queries.skip = 0;
          this.total = 0;
        },
  
        resetForm() {
          this.form = {
            name: null,
            action: null,
            frequency: 'monthly',
            status: 'inactive'
          }
        },

        getBulkActions() {
          this.$http.get('/bulk_actions?sort=name&sortDirection=desc&limit=1000&fields=name,uuid')
          .then(response => {
            if(response && response.data) {
              this.actions = response.data;
            }
          })
        },
  
        postForm() {
    
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {
              this.$eventBus.$emit('show-alert', {
                text: 'Creating '+this.$route.meta.title
              });
              this.resetForm();
              this.processing = false;
              if(response.data.uuid) {
                window.location.href = '/'+this.$route.meta.base_url + '/' + response.data.uuid;
              }
            }
            
          })
        },
          
        getRecords() {
  
          this.searching = true;
  
          this.updateURL();
  
          this.getTotal();
  
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {
              
              if(response.data.length) {
  
                var self = this;
  
                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }
  
              this.searching = false;
  
              this.queries.skip = this.records.length;
            }
          })
        },
  
        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },
  
        getTotal() {
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status) {
              this.total = response.data;
              this.setTitle();
            }
          })
        },
  
        getUsers() {
          this.$http.get('/users?slim=true&fields=email&limit=1000&sort=email&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
        },
  
      updateSortDirection() {
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
        
        this.getRecords();
      },
  
      sortColumn(column) {
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },

    setActionName(record) {

      if(!record.action) {
        return;
      }

      var action = _.find(this.actions, {'uuid': record.action});

      if(!action || !action.name) {
        return;
      }

      return action.name;
      },
    } 
  }
  </script>
<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row mb-3 d-print-none" data-html2canvas-ignore>
      <div class="col-sm-12 col-md-4">
        <template v-if="form.name">
          <h1>{{ form.name }}</h1>
        </template>
          <p>
            <router-link :to="{name: 'portals.index'}">Portals</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
          </p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
          <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing"><i class="fa fa-file-pdf"></i> PDF </button>
      </div><!--Col-->


      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4 d-print-none" data-html2canvas-ignore>
          <div class="card">
            <div class="card-body">
              
              <p class="card-title">Edit {{ $route.meta.title }}</p>

              <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name">
              </div>

              <div class="form-group">
                <label for="language">Default Language</label>
                <select
                  id="language"
                  v-model="form.language"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Language
                  </option>
                  <option value="en">English</option>

                  <template v-for="language in languages">

                    <option
                      :value="language.key"
                      :hidden="language.key == 'en'"
                    >
                      {{ language.label }}
                    </option>

                  </template>

                </select>
              </div>

              <div class="form-group">
                <label for="notes" class="form-label">Internal Notes</label>
                <textarea id="notes" class="form-control" v-model="form.notes"></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </form>



            </div><!--Body-->
          </div><!--Card-->

       <template v-if="form.user_agent">
          <user-agent
            :raw="form.user_agent"
            class="my-3"
          />
        </template>

        <template v-if="form.ip_address">
          <ip-address-card :ip="form.ip_address" class="my-3" />
        </template>

          <audit-logs :form="form" class="my-3" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="form.client">
          <client-card
            :client="form.client"
            class="mb-3"
          />
        </template>

        <div class="card mb-3">
          <div class="card-body">

            <p class="card-title">Submission Data</p>

            <form @submit.prevent="postForm()">

            <template v-if="form.data">

              <template v-for="(field, field_index) in form.data">

                <div>

                  <template v-if="!['uuid', 'created_at', 'updated_at', 'archived_at', 'deleted_at', 'agency', 'region'].includes(field_index)">
                    <div class="form-group">
                      <label :for="field_index" class="form-label">{{ field_index }}</label>
                      <input :id="field_index" type="text" class="form-control" v-model="form.data[field_index]">

                      <template v-if="isImageField(field_index)">
                        <img :src="form.data[field_index]" class="img-fluid mt-3" :alt="field_index" />
                      </template>

                    </div>
                  </template>
                </div>

              </template>

            </template>

            <button type="submit" class="btn btn-success" :disabled="processing" hidden>Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card">
          <div class="card-body">

            <p class="card-title">{{ files.length }} Files</p>

            <template v-if="files && files.length">

              <ul class="list-group">

              <template v-for="file in files">

                <li class="list-group-item"><a :href="file.url" target="_blank"><i class="fa fa-link"></i> {{ file.name }}</a> 

                  <template v-if="file.size">
                    <span class="text-muted small m-0 ml-1">{{ file.size | fileSize }}</span>
                  </template>
                  
                  <template v-if="file.type">
                    <span class="badge badge-pill badge-secondary ml-1">{{ file.type }}</span>
                  </template>

                </li>

              </template>

              </ul>

            </template>

            <template v-if="!files.length">

              <p class="text-alert">No Files</p>

            </template>

          </div>
        </div>

       
      </div><!--Col-->
      

      <div class="col-sm-12 col-md-4">

        <template v-if="template && template.uuid">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">{{ template.name }}</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">
                <a :href="'/forms/'+template.uuid" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i> Edit Form</a>
              </div>
            </div>


            <ul class="list-group">

              <template v-if="template && template.name">
                <li class="list-group-item">Name <a :href="'/forms/'+template.uuid">{{ template.name }}</a></li>
              </template>

              <template v-if="template && template.status">
                <li class="list-group-item">Status {{ template.status }}</li>
              </template>

              <template v-if="template && template.description">
                <li class="list-group-item">Description {{ template.description }}</li>
              </template>

              <template v-if="template && template.language">
                <li class="list-group-item">Default Language {{ template.language }}</li>
              </template>

              <template v-if="template && template.total_submissions">
                <li class="list-group-item">Total Submissions {{ template.total_submissions }}</li>
              </template>

            </ul>

          </div><!--Body-->
        </div><!--Card-->

        </template>

        </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data () {
    return {
        processing: false,
        loading: false,
        form: {},
        messages: [],
        portal: {},
        portals: [],
        template: {},
        files: []
    }
},


created () {
    this.resetForm();
    this.getRecord();
},

methods: {


    resetForm() {
      this.form = {
        name: null,
        data: []
      }
    },


    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form = {...this.form, ...response.data};
            this.$eventBus.$emit('show-alert', {
              text: this.$route.meta.title + ' updated'
            });
          }

        }
      })
    },


    getRecord() {
        this.processing = true
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;
                this.processing = false;
                this.getForm();
                this.getFiles();
            }
        })
    },

    getTotalSubmissions() {
      if(!this.template || !this.template.uuid) {
        return;
      }

      this.$http.get('/'+this.$route.meta.base_url+'/total?form='+this.template.uuid)
      .then(response => {
        if(response && response.status) {
          this.template.total_submissions = response.data || 0;
        }
      })
    },

    getFiles() {

      if(!this.form.form) {
        return;
      }

      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/files')
      .then(response => {
        if(response && response.data) {
          this.files = response.data;
        }
      })

    },

    getForm() {

      if(!this.form.form) {
        return;
      }

      this.$http.get('/forms/'+this.form.form)
      .then(response => {
        if(response && response.data) {
          this.template = response.data;
          this.getTotalSubmissions();
        }
      })
    },


  isImageField(index) {
    if(!this.form.data[index]) {
      return false;
    }

    if(typeof this.form.data[index] !== 'string') {
      return false;
    }

    if(this.form.data[index].includes('data:image')) {
      return true;
    }

    return false;
  },

  getPDF() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf', {responseType: 'blob'})
      .then(response => {
        if(response && response.data) {
          this.processing = true;

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.$route.meta.base_url+'-'+this.form.uuid+'.pdf');
          document.body.appendChild(link);
          link.click();

          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.processing = false;
        }
      })
    },

}
}
</script>
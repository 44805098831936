<template>
  <div>

    <page-tabs page="portals" />

    <template v-if="error">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </template>

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Create</p>
       
            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>

              <div class="form-group">
                  <label for="language">Language</label>
                  <select
                    id="language"
                    v-model="form.language"
                    class="form-control"
                    :disabled="processing"
                    required
                  >
                    <option
                      v-for="language in languages"
                      :value="language.key"
                    >
                      {{ language.label }}
                    </option>
                  </select>
                </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->


        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search by Name</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <div class="form-group">
                  <label for="search_language">Language</label>
                  <select
                    id="search_language"
                    v-model="queries.language"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="">Any</option>
                    <option
                      v-for="language in languages"
                      :value="language.key"
                    >
                      {{ language.label }}
                    </option>
                  </select>
                </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select id="status" class="form-control" v-model="queries.status" :disabled="processing">
                  <option value="">Any</option>
                  <option value="draft">Draft</option>
                  <option value="published">Published</option>
                  <option value="private">Private</option>
                </select>
              </div>

              <div class="form-group">
                <label for="flow">Flow</label>
                <select id="flow" class="form-control" v-model="queries.flow" :disabled="processing">
                  <option value="">Any</option>
                  <template v-for="(flow, flow_index) in flows">
                    <option :value="flow.uuid">{{ flow.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="portal">Portal</label>
                <select id="portal" class="form-control" v-model="queries.portal" :disabled="processing">
                  <option value="">Any</option>
                  <template v-for="(portal, portal_index) in portals">
                    <option :value="portal.uuid">{{ portal.name }}</option>
                  </template>
                </select>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.trashed"
                    >
                    <label
                      class="form-check-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.archived"
                    >
                    <label
                      class="form-check-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Import Sample</p>

            <p class="card-text">Import sample forms to get started quickly.</p>

            <ul class="list-group">

              <template v-for="sample in samples">
                <li class="list-group-item list-group-item-action">
                  <h5 class="list-group-item-heading">{{ sample.name }}</h5>
                  <p class="list-group-item-text">{{ sample.description }}</p>

                  <button type="button" class="btn btn-success btn-sm" @click="importSample(sample)">Import</button>
                </li>
              </template>

            </ul>

        </div><!--Body-->
      </div><!--Card-->


      </div><!--Col-->


      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

              </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                        <i class="fa fa-edit"></i> Edit
                      </a>
                    </td>
                    <td>{{ record.name }}</td>
                    <td>{{ record.reference }}</td>
                    <td>
                      <template v-if="record.language">
                        {{ setLanguage(record.language) }}
                      </template>
                    </td>
                    <td>
                      <template v-if="record.status">
                        <span class="badge badge-pill text-uppercase" :class="setStatusClass(record.status)">{{ record.status }}</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.url && record.status != 'draft'">
                        <a :href="setPortalLink(record)" class="btn btn-sm btn-secondary" target="_blank">OPEN PORTAL</a>
                      </template>
                      <template v-if="record.status == 'draft'">
                        <span class="badge badge-secondary">DRAFT</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.flow">
                        {{ setFlowName(record) }}
                      </template>
                    </td>
                    <td>
                      <template v-if="record.portal">
                        {{ setPortalName(record) }}
                      </template>
                    </td>
                    <td>
                      {{ record.opened_at | datetime }}
                    </td>
                    <td>
                      {{ record.expires_at | datetime }}

                      <template v-if="record.expired && record.expired === true">
                        <span class="badge badge-danger">EXPIRED</span>
                      </template>

                      <template v-if="!record.expires_at">
                        <span class="badge badge-secondary">NEVER</span>
                      </template>
                    </td>
                    <td>{{ record.created_at | datetime }}
                    
                      <template v-if="record.archived_at">
                        <span class="badge badge-warning">ARCHIVED</span>
                      </template>

                      <template v-if="record.deleted_at">
                        <span class="badge badge-danger">TRASHED</span>
                      </template>

                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            </div>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="loadMore"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data() {
    return {
        error: '',
        processing: false,
        show_load: false,
        searched: false,
        records: [],
        columns: [
            {label: 'Edit'},
            {key: 'name', label: 'Name'},
            {key: 'reference', label: 'Form Reference'},
            {key: 'language', label: 'Language'},
            {key: 'status', label: 'Status'},
            {key: 'url', label: 'URL'},
            {key: 'flow', label: 'Flow'},
            {key: 'portal', label: 'Portal'},
            {key: 'opened_at', label: 'Opened'},
            {key: 'expires_at', label: 'Expires'},
            {key: 'created_at', label: 'Created'}
        ],
        queries: {},
        total: 0,
        form: {},
        portals: [],
        flows: [],
        samples: [
          {
            name: 'Client Survey',
            description: null,
            language: 'en',
            reference: 'client-survey',
            status: 'public',
            sections: [
                {
                  title: "Survey Form",
                  description: "Fill out the survey to help us improve our services.",
                  fields: [
                    {
                      type: "short_text",
                      title: "Your Name",
                      required: true
                    },
                    {
                      type: "email",
                      title: "Your Email",
                      required: true
                    },
                    {
                      type: "phone",
                      title: "Your Phone"
                    },
                    {
                      type: "long_text",
                      title: "Your Feedback",
                      required: true
                    }
                  ]
                }
            ],
          }
        ]
    }
},


mounted() {

var self = this;

this.$eventBus.$on('refreshRecords', function(view) {

    if(view !== self.$route.meta.base_url) {
        return;
    }

    self.resetRecords();

    self.getRecords();

});

},


created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getPortals();
    this.getFlows();
    this.getLanguages();
},

methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetForm() {
      this.form = {
        name: null,
        reference: null,
        notify: null,
        portal: null,
        status: 'draft',
        language: 'en'
      }
    },

    resetQueries() {
      this.queries = {
        skip: 0,
        limit: 10,
        sort: 'created_at',
        sortDirection: 'desc',
        search: null,
        status: null,
        language: null,
        flow: null,
        portal: null
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;      
    },

    setStatusClass(status) {

      if(status == 'private') {
        return 'badge-danger';
      }

      if(status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    postForm() {
      this.processing = true;

      this.form.notify = this.$root.current_user.email;

      this.form.reference = this.$options.filters.slugString(this.form.name);

      if(this.portals && this.portals.length) {
        this.form.portal = this.portals[0].uuid;
      }

      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(!response.data.uuid) {
            this.$eventBus.$emit('show-alert', {
              text: 'Error creating '+this.$route.meta.title,
              type: 'danger'
            });
            return;
          }

          this.$eventBus.$emit('show-alert', {
            text: this.$route.meta.title + ' created'
          });
          this.records.unshift(response.data);
          this.total++;
          this.resetForm();
          this.processing = false;
          this.$router.push({name: this.$route.meta.base_url+'.edit', params: {id: response.data.uuid}});
        }
      })
    },

    getRecords() {

      this.processing = true;

        this.getTotal();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                this.records = response.data

                if (response.data.length < this.queries.limit) {
                    this.show_load = false
                } else {
                    this.show_load = true
                }

                this.processing = false
            }
        })
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    loadMore() {
      this.processing = true;
      this.show_load = false;

      this.queries.skip = this.records.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {

              if(response.data.length < this.queries.limit) {
                this.show_load = false;
              } else {
                this.show_load = true;
              }

              if(response.data.length > 0) {
                response.data.forEach(r => {
                    this.records.push(r);
                    this.total++;
                })                
              }

              this.processing = false;
          } else {
            this.show_load = false;
          }
        });
    },

    setPortalLink(record)
    {
      if(!record.portal || !record.url) {
        return;
      }

      var portal = this.portals.find(p => p.uuid == record.portal);

      var language = record.language ? record.language : 'en';

      if(portal && portal.link) {
        return 'https://'+portal.link+'.'+portal.domain+'/w/'+record.url+'?lang='+language;
      }

    },

    setFlowName(record) {
      if(!record.flow) {
        return;
      }

      var flow = this.flows.find(f => f.uuid == record.flow);

      if(flow) {
        return flow.name;
      }
    },

    setPortalName(record) {
      if(!record.portal) {
        return;
      }

      var portal = this.portals.find(p => p.uuid == record.portal);

      if(portal) {
        return portal.name;
      }
    },

    getPortals() {
      this.portals = [];
      this.error = '';
      this.$http.get('/portals?limit=100&sort=default&sortDirection=desc&slim=true&fields=name,uuid,link,domain')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;

          if(!response.data.length) {
            this.error = 'No portals found. Create a portal to use forms.';
          }
        }
      })
    },

    getFlows() {
      this.flows = [];
      this.$http.get('/flows?limit=100&sort=default&sortDirection=desc&slim=true&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.flows = response.data;
        }
      })
    },

    searchRecords() {
      this.searched = true;
      this.resetRecords();
      this.getRecords();
    },


      importSample(sample) {
        this.form = sample;
        this.postForm();
      }

}

}
</script>
<template>
    <div>
  
        <page-tabs :page="$route.meta.tabs" />

        <div class="row">
            <div class="col-md-6 mx-auto">


                    <div class="card">
                        <div class="card-body">

                            <template v-if="!training_mode">
                                <button type="button" class="btn btn-success btn-block mb-3" @click="enableTrainingMode" :disabled="processing">Enable Training Mode</button>
                            </template>

                            <template v-else>
                                <button type="button" class="btn btn-danger btn-block mb-3" @click="disableTrainingMode" :disabled="processing">Disable Training Mode</button>
                            </template>

                            <template v-if="training_mode">

                                <template v-if="error">
                                    <p class="text-danger" v-html="error"></p>
                                </template>

                            <template v-if="!error">

                                <div class="d-flex justify-content-between">

                                <h4 class="text-center text-success m-0">Your Score: {{ user_score }}%</h4>
                                <template v-if="loading">
                                    <p class="text-center text-muted">Please wait while we calculate your score...</p>
                                </template>

                                <button type="button" class="btn btn-success btn-sm" @click="downloadCertificate" :disabled="processing || loading"><i class="fa fa-certificate"></i> Download Certificate</button>

                                </div>

                                <button type="button" class="btn btn-sm btn-outline-success mb-3" @click="generateScore" :disabled="loading || processing">Refresh Score</button>


                            <div class="list-group">

                            <template v-for="result in results">
                            
                                <li class="list-group-item mb-3">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-9">
                                            <h5 class="mb-1 fw-bold">{{ result.name }}</h5>
                                        </div>
                                        <div class="col-sm-12 col-md-3 text-end">
                                            <template v-if="result.status == 1">
                                                <span class="badge badge-success">😀 COMPLETE</span>
                                            </template>
                                            <template v-else>
                                                <span class="badge badge-danger">😞 INCOMPLETE</span>
                                            </template>  
                                        </div>
                                    </div>

                                    <template v-if="result.url">
                                        <p class="mb-1">Click on the <a :href="'/'+result.url" class=text-uppercase target="_blank">{{ result.url | rmDashes }}</a> link and follow the Training Tips to complete this task.</p>
                                    </template>

                                    <template v-if="result.description">
                                        <p class="mb-1">{{ result.description }}</p>
                                    </template>
                                </li>
                                
                            </template>

                            </div><!--ListGroup-->

                            </template>

                            </template>

                        </div><!--Body-->
                    </div><!--Card-->

            </div><!--Col-->

        </div><!--Row-->

    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
  data() {
      return {
          processing: false,
          loading: false,
          training_mode: false,
          results: [],
          records: [],
          user_score: 0,
          agency: {},
          error: null
      }
  },
  
  created() {
      document.title = this.$route.meta.title + ' | eCourtDate.com';

      this.setTrainingMode();

      this.getAgency();
  },
  
  methods: {

    setTrainingMode() {
        //get from local storage
        var training_mode = localStorage.getItem('training_mode');

        if(training_mode && training_mode == 'enabled') {
            this.training_mode = true;
        }
    },

    enableTrainingMode() {
        this.training_mode = true;

        //save in local storage
        localStorage.setItem('training_mode', 'enabled');

        this.$router.go();
    },

    disableTrainingMode() {
        this.training_mode = false;

        //save in local storage
        localStorage.setItem('training_mode', 'disabled');

        this.$router.go();
    },

    getAgency() {
        this.$http.get('/settings')
        .then(response => {
            if(response && response.status === 200) {
                this.agency = response.data;

                this.generateScore();
            }
        });
    },

    generateScore() {

        if(!this.$root.current_user || !this.$root.current_user.current_agency) {
            return;
        }

        if(!this.$root.current_user.phone) {
            this.error = 'Click here and enter your phone number to start your training: <a href="/users/current" target="_blank">User Profile</a>.';
            return;
        }

        if(!this.agency || !this.agency.phone) {
            this.error = 'Ensure your agency has a phone number set in the <a href="/agency_phones" target="_blank">Agency Phones</a> page.';
            return;
        }

        if(!this.agency || !this.agency.email) {
            this.error = 'Ensure your agency has an email address set in the <a href="/agency_emails" target="_blank">Agency Emails</a> page.';
            return;
        }

        this.loading = true;

        this.user_score = 0;

        this.results = [];

        this.$http.get('/clients/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {

                //add 10 points if they have created at least one client
                if(response.data > 0) {
                    this.user_score += 10;
                }

                this.results.push({
                    name: 'Create a Client',
                    description: 'Manually create a client in eCourtDate',
                    url: 'clients',
                    status: response.data > 0 ? 1 : 0
                });

                this.countMessages();
            }
        });
    },

    countEventMessages() {
        this.$http.get('/messages/total?search_field=type&search=event&created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {

                if(response.data > 0) {
                    this.user_score += 5;
                }

                this.results.push({
                    name: 'Create an Event',
                    description: 'Manually create an event for a client and ensure that reminders are scheduled',
                    url: 'events',
                    status: response.data > 0 ? 1 : 0
                });

                this.countContacts();

            }
        });
    },

    countMessages() {
        this.$http.get('/messages/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {

                if(response.data > 0) {
                    this.user_score += 10;
                }

                this.results.push({
                    name: 'Send a Message',
                    description: 'Manually send a one-off message to a client',
                    url: 'dashboard',
                    status: response.data > 0 ? 1 : 0
                });

                this.countEventMessages();
            }
        });
    },

    countContacts() {
        this.$http.get('/contacts/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {

                if(response.data > 0) {
                    this.user_score += 5;
                }

                this.results.push({
                    name: 'Create a Contact',
                    description: 'Manually create a contact in eCourtDate',
                    url: 'contacts',
                    status: response.data > 0 ? 1 : 0
                });

                this.countInboundMessages();

                this.countCases();

            }
        });
    },

    countInboundMessages() {
        this.$http.get('/messages/total?from='+this.$root.current_user.email+'&direction=inbound')
        .then(response => {
            if(response && response.status === 200) {
                if(response.data > 0) {
                    this.user_score += 10;
                }

                this.results.push({
                    name: 'Send an Inbound Email',
                    description: 'Send an inbound email from your email to the agency email '+this.settings.email,
                    status: response.data > 0 ? 1 : 0
                });
            }
        });


            this.$http.get('/messages/total?search_field=from&search='+this.$root.current_user.phone+'&direction=inbound&channel=text')
            .then(response => {
                if(response && response.status === 200) {
                    if(response.data > 0) {
                        this.user_score += 10;
                    }

                    this.results.push({
                        name: 'Send an Inbound Text',
                        description: 'Send an inbound text from your phone to the agency phone number '+this.settings.phone,
                        status: response.data > 0 ? 1 : 0
                    });

                }
            });

            this.$http.get('/messages/total?search_field=from&search='+this.$root.current_user.phone+'&direction=inbound&channel=voice')
            .then(response => {
                if(response && response.status === 200) {
                    if(response.data > 0) {
                        this.user_score += 10;
                    }

                    this.results.push({
                        name: 'Make an Inbound Call',
                        description: 'Make a phone call to the agency phone number '+this.settings.phone,
                        status: response.data > 0 ? 1 : 0
                    });

                }
            });
    },

    countCases() {
        this.$http.get('/cases/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {
                if(response.data > 0) {
                    this.user_score += 10;
                }

                this.results.push({
                    name: 'Create a Case',
                    description: 'Manually create a case in eCourtDate',
                    url: 'cases',
                    status: response.data > 0 ? 1 : 0
                });

                this.countBulkActions();
            }
        });
    },

    countBulkActions() {
        this.$http.get('/bulk_actions/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {
                if(response.data > 0) {
                    this.user_score += 10;
                }

                this.results.push({
                    name: 'Create a Bulk Action',
                    description: 'Manually create a bulk action in eCourtDate',
                    url: 'bulk_actions',
                    status: response.data > 0 ? 1 : 0
                });

                // this.loading = false;

                this.countLocations();
            }
        });
    },

    countLocations() {
        this.$http.get('/locations/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {
                if(response.data > 0) {
                    this.user_score += 10;
                }

                this.results.push({
                    name: 'Create a Location',
                    description: 'Manually create a location in eCourtDate',
                    url: 'locations',
                    status: response.data > 0 ? 1 : 0
                });

                this.countFlows();
            }
        });
    },

    countFlows() {
        this.$http.get('/flows/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {
                if(response.data > 0) {
                    this.user_score += 5;
                }

                this.results.push({
                    name: 'Create a Flow',
                    description: 'Manually create a flow in eCourtDate',
                    url: 'flows',
                    status: response.data > 0 ? 1 : 0
                });

                this.countAutoMessages();
            }
        });
    },

    countAutoMessages() {
        this.$http.get('/auto_messages/total?created_by='+this.$root.current_user.email)
        .then(response => {
            if(response && response.status === 200) {
                if(response.data > 0) {
                    this.user_score += 5;
                }

                this.results.push({
                    name: 'Create an Auto Message',
                    description: 'Manually create an auto message in eCourtDate',
                    url: 'auto_messages',
                    status: response.data > 0 ? 1 : 0
                });

                this.loading = false;
            }
        });
    },

    downloadCertificate() {
          this.processing = true;
          this.$http.post('/users/training', {scores: this.results, score: this.user_score}, {responseType: 'blob'})
          .then(response => {
            if(response && response.data) {
              this.processing = true;

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'ecourtdate-training-certificate-'+this.$root.current_user.name+'.pdf');
              document.body.appendChild(link);
              link.click();

              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);

              this.processing = false;
            }
          })
        },

    
  }
  
  }
  </script>
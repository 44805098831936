<template>
    <div>
  
      <page-tabs page="clients" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
  
          <template v-if="client && client.uuid">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-6">
                    <p class="card-title m-0">
                      Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
  
                    </router-link>
                    </p>  
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6 text-end">
                    <button
                        type="submit"
                        class="btn btn-danger btn-sm"
                        :disabled="processing"
                        @click="closeClient"
                    >
                      <i class="fa fa-times" /> <span class="sr-only">Close</span>
                    </button>
                  </div><!--Col-->
                </div><!--Row-->
  
                <ul class="list-group">
                  <template v-if="client.client_reference">
                    <li class="list-group-item">
                      Reference {{ client.client_reference }}
                    </li>
                  </template>
  
                  <template v-if="client.type">
                    <li class="list-group-item">
                      Type {{ client.type }}
                    </li>
                  </template>
  
                  <template v-if="client.group">
                    <li class="list-group-item">
                      Group {{ client.group }}
                    </li>
                  </template>

                  <template v-if="client.language">
                    <li class="list-group-item">
                      Language {{ setLanguage(client.language) }}
                    </li>
                    </template>

                    <template v-if="client.status">
                    <li class="list-group-item">
                      Status {{ client.status }}
                    </li>
                    </template>

                </ul>
              </div><!--Body-->
            </div><!--Card-->
          </template>
  
          <template v-if="client && !client.uuid">
            <client-search />
          </template>
  
  
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Add {{ $route.meta.title }}
              </p>
  
              <form @submit.prevent="postForm()">
  
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    required
                  >
                </div>
  
                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                    id="type"
                    v-model="form.type"
                    class="form-control"
                    :disabled="processing"
                    required>
                    <template v-for="form_type in types">
                      <option
                        :value="form_type"
                      >{{ form_type }}
                      </option>
                    </template>
                  </select>
                </div>
  
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Add
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">
                Search {{  $route.meta.title }}s
              </p>
  
              <form @submit.prevent="searchRecords">
  
                <div class="form-group">
                  <label for="search">Search</label>
                  <input
                      id="search"
                      v-model="queries.search"
                      type="search"
                      class="form-control"
                      placeholder="Search..."
                  >
                </div>
  
                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                      id="type"
                      v-model="queries.type"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="search_type in types">
                      <option :value="search_type">{{ search_type }}</option>
                    </template>
                  </select>
                </div>
  
                <div class="form-group">
                  <label for="created_by">Creator</label>
                  <select
                      id="created_by"
                      v-model="queries.created_by"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="user in users">
                      <option
                          :value="user.email"
                      >
                        {{ user.name }} {{ user.email }}
                      </option>
                    </template>
                  </select>
                </div>
  
  
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                          id="archived"
                          v-model="queries.archived"
                          type="checkbox"
                          class="form-check-input"
                          :disabled="processing"
                      >
                      <label
                          class="form-check-label"
                          for="archived"
                      >Archived</label>
                    </div>
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                          id="trashed"
                          v-model="queries.trashed"
                          type="checkbox"
                          class="form-check-input"
                          :disabled="processing"
                      >
                      <label
                          class="form-check-label"
                          for="trashed"
                      >Trashed</label>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->
  
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
  
          <div class="card">
            <div class="card-body">
  
              <div class="row">
  
                <div class="col-sm-12 col-md-6">
  
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                  </p>
  
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-6 text-end">
  
                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->
  
                </div><!--Row-->
  
              <div class="table-responsive my-3">
  
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <template v-for="column in columns">
                      <th
                        class="text-uppercase"
                      >
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
  
                <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="record in records">
                      <tr>
                        <td>
                          <a :href="$route.meta.base_url+'/'+record.uuid">
                            <i class="fa fa-edit"></i> Edit
                          </a>
                        </td>
                        <td>{{ record.name }}</td>
                        <td>{{ record.type }}</td>
                        <td>{{ record.reference }}</td>
                        <td><span class="badge badge-pill text-uppercase" :class="setStatusClass(record)">{{ record.status }}</span></td>
                        <td>
                            <template v-if="record.expires_on">
                                {{ record.expires_on | datetime }}
                            </template>
                        </td>
                        <td>{{ record.description }}</td>
                        <td>{{ record.created_by }}</td>
                        <td>{{ record.updated_by }}</td>
                        <td>{{ record.created_at | datetime }}</td>
                        <td>{{ record.updated_at | datetime }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
  
                </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No results found <template v-if="client && client.uuid">for {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }} {{ client.client_reference }}</template>
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
  
          <div class="row mt-3">
            <div class="col-12 text-center">
  
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
                >
                  Load more
                </button>
  
            </div><!--Col-->
          </div><!--Row-->
  
          </template>
  
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  import _ from "lodash";
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        downloading: false,
        total: 0,
        queries: {},
        records: [],
        downloads: [],
        form: {},
        client: {},
        columns: [
        { label: 'Edit'},
        { key: 'name', label: 'Name'},
        { key: 'type', label: 'Type'},
        { key: 'reference', label: 'Reference'},
        { key: 'status', label: 'Status'},
        { key: 'description', label: 'Description'},
        { key: 'expires_on', label: 'Expires'},
        { key: 'created_by', label: 'Creator'},
        { key: 'updated_by', label: 'Updated'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
        ],
        users: [],
        types: ['Driver License', 'Proof of Address', 'State ID', 'Passport', 'Passport Card', 'Social Security Card', 'Birth Certificate', 'Marriage Certificate', 'Divorce Certificate', 'Death Certificate', 'Adoption Certificate', 'Naturalization Certificate', 'Military Service', 'Other']
      }
    },
  
    mounted() {
  
      var self = this;
  
  
      this.$eventBus.$on('search_clients', function(uuid) {
  
        self.queries.client = uuid;
  
        self.getClient();
  
      });
  
  
  
    },
  
    created() {
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
  
      if(this.queries.client) {
        this.getClient();
      }
  
      this.getRecords();
      this.getUsers();
    },
  
    methods: {
  
  
      setURLParams() {
  
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },
  
      setStatusClass(record) {
  
        if(record.status == 'invalid') {
          return 'badge-danger';
        }
  
        if(record.status == 'valid') {
          return 'badge-success';
        }
  
        return 'badge-secondary';
      },
  
      resetQueries() {
        this.queries = {
          limit: 10,
          slim: false,
          client: null,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },
  
      resetForm() {
        this.form = {
          reference: null,
          type: 'Driver License',
          client: null,
          notes: null,
          expires_on: null,
          status: null,
        }
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
            this.setTitle();
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },
  
      setTitle() {
        document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },
  
      postForm() {
        this.processing = true;
  
        if(this.client && this.client.uuid) {
          this.form.client = this.client.uuid;
        }

        if(this.form.name && !this.form.reference) {
          this.form.reference = this.form.name;
        }
  
        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;
              this.records.unshift(response.data);
              this.$eventBus.$emit('show-alert', {
                text: this.$route.meta.title + ' created'
              });
            }
  
          }
        })
      },
  
      getUsers() {
        this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
            .then(response => {
              if(response && response.data) {
                this.users = response.data;
              }
            })
      },
  
      searchRecords () {
        this.resetRecords();
        this.getRecords();
      },
  
      getClient() {
  
        if(!this.queries.client) {
          return;
        }
  
        this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,group,type,language,status')
            .then(response => {
              if(response && response.data) {
                this.client = response.data;
                this.resetRecords();
                this.getRecords();
              }
            })
      },
  
      resetClient() {
        this.queries.client = null;
        this.client = {};
      },
  
      closeClient() {
        this.resetClient();
        this.resetRecords();
        this.getRecords();
      },
         
  
    },
  }
  </script>
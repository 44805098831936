<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="title"
    />

    <div class="row mb-3">
      <div class="col-sm-12 col-md-4">
        <template v-if="form.uuid">
          <geolocation-status :client="form.uuid" />
        </template>
        
        <template v-if="form.name">
          <h1 class="m-0" id="clientName"
          :class="isPrivacyMode ? 'privacy-mode' : ''"
          >
            {{ form.name }}
          </h1>
          <p class="text-capitalize"><router-link :to="{name: $route.meta.base_url+'.index'}">{{ title }}s</router-link> / Edit {{  title }}</p>
        </template>
        
        <template v-if="!form.name">
          <h2 class="m-0"
          :class="isPrivacyMode ? 'privacy-mode' : ''"
          >
            {{ form.first_name }} {{ form.middle_name }} {{ form.last_name }}
          </h2>
        </template>

        <template v-if="form.status">
          <disable-status-button :status="form.status"></disable-status-button>
        </template>

        <template v-if="form.client_reference">
          <span
            id="clientReference"
            class="badge badge-pill badge-secondary"
            @click="copyToClipboard(form.client_reference)"
          >{{ form.client_reference }}</span>
        </template>

        <template v-if="form.dob">
          <span class="badge badge-pill badge-secondary">{{ age }}</span>
        </template>

        <template v-if="form.unreachable === true">
          <span class="badge badge-pill badge-danger text-uppercase">Unreachable</span>
        </template>
      </div><!--Col-->
      <div class="col-sm-6 col-md-2">

        <div class="dropdown">
              <button
                type="button"
                id="filesButton"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-if="files && files.length"
              >
                Send File
              </button>
              <div class="dropdown-menu">
                <p class="dropdown-header">
                  Send File
                </p>
                <template v-for="file in files">
                  <a
                    class="dropdown-item"
                    :href="'/files/'+file.uuid+'?client='+form.uuid"
                    target="_blank"
                  >{{ file.name }}</a>
                </template>
              </div>
        </div><!--Dropdown-->

    </div><!--Col-->

      <div class="col-sm-6 col-md-2">

        <div class="dropdown">

              <button
                type="button"
                id="autoMessagesButton"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-if="auto_messages && auto_messages.length"
              >
                Send Auto Message
              </button>
              <div class="dropdown-menu">
                <p class="dropdown-header">
                  Send Auto Message
                </p>
                <template v-for="auto_message in auto_messages">
                  <a
                    class="dropdown-item"
                    @click.prevent="sendAutoMessage(auto_message.uuid)"
                  >{{ auto_message.name }}</a>
                </template>
              </div>

        </div><!--Dropdown-->

    </div><!--Col-->

    <div class="col-sm-6 col-md-4 text-end">

      <div class="btn-group" :hidden="!form.uuid">

        <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>

            <button
              type="button"
              class="btn btn-secondary dropdown-toggle radius-right-0"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :class="isHidden('clients_tools')"
            >
              Tools
            </button>
            <div class="dropdown-menu">

              <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                :class="isHidden('clients_sync')"
                @click="syncRecord(form)"
              >Sync Messages</a>

              <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                :class="isHidden('clients_archive')"
                @click="archiveRecord(form)"
              >Archive</a>
              <a
                v-if="form.archived_at || form.deleted_at"
                class="dropdown-item"
                :class="isHidden('clients_restore')"
                @click="restoreRecord(form)"
              >Restore</a>
              <a
                v-if="!form.deleted_at && !form.archived_at"
                class="dropdown-item"
                :class="isHidden('clients_trash')"
                @click="trashRecord(form)"
              >Trash</a>
              <a
                class="dropdown-item"
                data-bs-target="#mergeClientModal"
                data-bs-toggle="modal"
                :class="isHidden('clients_merge')"
              >Merge</a>
              <a
                class="dropdown-item"
                data-bs-target="#transferClientModal"
                data-bs-toggle="modal"
                @click="getAgencies()"
                :class="isHidden('clients_transfer')"
              >Transfer</a>
              
            </div>

          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            :class="isHidden('clients_save')"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

      </div><!--Group-->

    </div><!--Col-->

    </div><!--Row-->
    <div class="row">
      <div class="col-lg-2 col-md-3">
        <template v-if="$route.params.id">
          <client-tabs :uuid="$route.params.id" />
        </template>
        </div><!--Col-->
      <div class="col-lg-10 col-md-9">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <p class="card-title">{{ title }} Profile</p>
                    </div><!--Col-->
                    <div class="col-sm-12 col-md-6 text-end">
                      <template v-if="!form.archived_at && !form.deleted_at && form.uuid && form.language != 'en'">
                        <!-- <a :href="'/interpreters?title=Assist%20Client&language='+form.language+'&client='+form.uuid" target="_blank" class="btn btn-outline-primary btn-sm"><i class="fa fa-language"></i> Interpreter</a> -->
                      </template>
                    </div><!--Col-->
                  </div>

                  <form
                    @keyup.enter="postForm"
                    @submit.prevent="postForm"
                  >
                    <div class="row">
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                          <label for="client_reference" class="text-capitalize">{{ setFieldTitle('client_reference') }}</label>
                          <input
                            id="client_reference"
                            v-model="form.client_reference"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                          <label for="group" class="text-capitalize">Group</label>
                          <select
                            id="group"
                            class="form-control"
                            v-model="form.group"
                            @change="postForm"
                            :disabled="processing"
                            >
                          <option value=""></option>
                          <template v-for="client_group in clientGroups">
                            <option :value="client_group">{{ client_group }}</option>
                          </template>
                          </select>

                        </div>

                      </div><!--Col-->
                      <div class="col-sm-12 col-md-4">

                        <div class="form-group text-capitalize">
                          <label for="language">Preferred Language</label>
                          <select
                            id="language"
                            v-model="form.language"
                            class="form-control"
                          >
                            <option
                              v-for="language in languages"
                              :value="language.key"
                            >
                              {{ language.label }}
                            </option>
                          </select>
                        </div>

                      </div>
                    </div><!--Row-->

                    <div class="form-group">
                      <label for="name" class="text-capitalize">{{ setFieldTitle('full_name') }}</label>
                      <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="form-control"
                      >
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group text-capitalize">
                          <label for="first_name" class="text-capitalize">{{ setFieldTitle('first_name') }}</label>
                          <input
                            id="first_name"
                            v-model="form.first_name"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group text-capitalize">
                          <label for="middle_name" class="text-capitalize">{{ setFieldTitle('middle_name') }}</label>
                          <input
                            id="middle_name"
                            v-model="form.middle_name"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-sm-12 col-md-4">
                        <div class="form-group text-capitalize">
                          <label for="last_name" class="text-capitalize">{{ setFieldTitle('last_name') }}</label>
                          <input
                            id="last_name"
                            v-model="form.last_name"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="form-group">
                      <label for="aliases">Aliases</label>
                      <input id="aliases" type="text" class="form-control" v-model="form.aliases">
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group vbDatePicker text-uppercase">
                          <label for="dob" class="text-capitalize">{{ setFieldTitle('birth_date') }} <small v-if="form.dob">{{ age }}</small></label>
                          <input
                            id="dob"
                            v-model="form.dob"
                            placeholder="mm/dd/yyyy"
                            type="date"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="type" class="text-capitalize">{{ setFieldTitle('type') }}</label>
                          <input
                            id="type"
                            v-model="form.type"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="birth_place" class="text-capitalize">Birth Place</label>
                          <input
                            id="birth_place"
                            v-model="form.birth_place"
                            class="form-control"
                          >
                        </div>
                      </div><!--Col-->

                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="birth_country">Birth Country</label>
                          <select
                            id="birth_country"
                            v-model="form.birth_country"
                            class="form-control"
                          >
                            <option value="" />
                            <template v-if="countries && countries.length > 0">
                              <template v-for="country in countries">
                                <option
                                  :value="country.code"
                                >
                                  {{ country.code }} - {{ country.name }}
                                </option>
                              </template>
                            </template>
                          </select>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="citizenship">Citizenship</label>
                          <select
                            id="citizenship"
                            v-model="form.citizenship"
                            class="form-control"
                          >
                            <option value="" />
                            <template v-if="countries && countries.length > 0">
                              <template v-for="country in countries">
                                <option
                                  :value="country.code"
                                >
                                  {{ country.code }} - {{ country.name }}
                                </option>
                              </template>
                            </template>
                          </select>
                        </div>
                      </div><!--Col-->

                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="race">Race</label>
                          <select
                            id="race"
                            v-model="form.race"
                            class="form-control"
                          >
                            <option value="" />
                            <option
                              v-for="race in races"
                              :value="race.key"
                            >
                              {{ race.label }}
                            </option>
                          </select>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="gender">Gender</label>
                          <select
                            id="gender"
                            v-model="form.gender"
                            class="form-control"
                          >
                            <option value="" />
                            <option
                              v-for="gender in genders"
                              :value="gender.key"
                            >
                              {{ gender.label }}
                            </option>
                          </select>
                        </div>
                      </div><!--Col-->

                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="marital_status">Marital Status</label>
                          <select
                            id="marital_status"
                            v-model="form.marital_status"
                            class="form-control"
                          >
                            <option value="" />
                            <template v-for="marital_status in marital_statuses">
                              <option :value="marital_status.key">
                                {{ marital_status.label }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div><!--Col-->
                    </div><!--Row-->

                    <div class="form-group">
                      <label for="status">Status</label>
                      <select
                        id="status"
                        v-model="form.status"
                        class="form-control"
                        :disabled="processing"
                        >
                        <option value=""></option>
                        <template v-for="status in statuses">
                          <option
                            :value="status.name"
                          >{{ status.name }}</option>
                        </template>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="assigned_users">Assigned Users</label>
                      <multi-select
                        id="assigned_users"
                        v-model="form.users"
                        placeholder="Choose users"
                        :options="users"
                        label="name"
                        track-by="uuid"
                        :multiple="true"
                        :close-on-select="false"
                      />
                    </div>

                    <div class="form-group">
                      <label for="notes">Internal Notes</label>
                      <textarea
                        id="notes"
                        v-model="form.notes"
                        type="text"
                        class="form-control"
                        rows="3"
                      />
                    </div>
                  </form>
                </div><!--CardBody-->
              </div><!--Card-->

              <div class="card my-3">
                  <div class="card-body">
                    <p class="card-title">Photos</p>

                    <div class="form-group">
                      <div class="custom-file">
                        <input
                            id="photosUpload"
                            ref="photos"
                            type="file"
                            class="custom-file-input"
                            accept="image/*"
                            aria-describedby="photosUpload"
                            @change="storePhotos()"
                            multiple="true"
                            :disabled="uploadProgress !== null || processing"
                        >
                        <label
                            class="custom-file-label"
                            for="photosUpload"
                        >Upload Photos</label>
                      </div>
                    </div>

                    <template v-if="processing && uploadProgress">
                      <div class="progress">
                        <div
                            class="progress-bar"
                            role="progressbar"
                            :style="'width:'+uploadProgress+'%'"
                        >
                          {{ uploadProgress }}%
                        </div>
                      </div>
                    </template>

                    <template v-if="photos && photos.length > 0">

                      <div class="row">
                        <div class="col-sm-12 col-md-6">
                          <p>{{ photos.length }} Photos</p>
                        </div><!--Col-->

                        <div class="col-sm-12 col-md-6 text-end">
                          <div class="btn-group">
                            <button type="button" class="btn btn-secondary btn-sm m-0" @click="getPhotos()" data-tooltip="Refresh photos" :disabled="processing"><i class="fa fa-refresh"></i></button>
                            <button type="button" class="btn btn-danger btn-sm m-0" @click="resetPhotos()" :data-tooltip="'Delete '+photos.length + ' photos'" :disabled="processing"><i class="fa fa-trash"></i></button>
                          </div>
                        </div><!--Col-->

                      </div>

                        <template v-for="photo in photos">
                          <div
                            class="my-3"
                          >
                            <a :href="photo.url" target="_blank">
                              <img
                              :src="photo.url"
                              width="100px"
                              class="img-fluid"
                              :alt="photo.name || 'Photo'"
                            >
                            </a>

                           <button type="button" class="btn btn-danger btn-sm float-right" @click="deletePhoto(photo)" data-tooltip="Delete photo" :disabled="processing"><i class="fa fa-trash"></i> </button>

                          </div>
                        </template>

                    </template>

                    <p class="mt-3"><a :href="'/documents?client='+form.uuid" class="text-decoration-none mb-3">{{ documents.length }} Documents</a></p>

                    <template v-if="documents && documents.length">

                    <template v-for="document in documents">
                      <a :href="'/documents/'+document.uuid" class="list-group-item list-group-item-action">{{ document.type }} {{ document.reference }}</a>
                    </template>

                    </template>

                    <template v-else>
                      <div class="alert alert-warning">No documents found.</div>
                    </template>

                  </div>
                </div>

              <template v-if="form && form.uuid">

                <div class="card my-3">
                  <div class="card-body">
                    <p class="card-title">Assigned Tasks</p>

                    <form @submit.prevent="postTask">

                    <div class="form-group">
                      <label for="task_content">New Task</label>
                      <input
                        id="task_content"
                        v-model="taskForm.content"
                        type="text"
                        class="form-control"
                        :disabled="processing"
                      >
                    </div>

                    <div class="form-group">
                      <label for="task_due_at">Due Date</label>
                      <input
                        id="task_due_at"
                        v-model="taskForm.due_at"
                        type="date"
                        class="form-control"
                        :disabled="processing"
                      >
                    </div>

                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="processing"
                      :hidden="!taskForm.content"
                    >
                      Add Task
                    </button>

                    </form>

                    <template v-if="tasks && tasks.length > 0">
                      <ul class="list-group my-3">
                        <template v-for="task in tasks">
                          <li class="list-group-item">
                            <a :href="'/tasks/'+task.uuid" class="text-decoration-none" target="_blank">

                              {{ task.content }}

                              <template v-if="task.status">
                                <span class="badge badge-pill badge-secondary">{{ task.status }}</span>
                              </template>
                            
                              <template v-if="task.due_at">
                                <span class="badge badge-pill badge-secondary">Due {{ task.due_at | date }}</span>
                              </template>
                            </a>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <template v-if="!processing && tasks && tasks.length === 0">
                      <div class="alert alert-warning">No tasks found.</div>
                    </template>
                    
                  </div>
                </div>



              <template v-if="form && form.uuid">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12 col-md-9">
                        <p class="card-title">Assigned {{ setCustomLabel('organization') }}s</p>
                      </div><!--Col-->
                      <div class="col-sm-12 col-md-3 text-end">
                        <a href="/organizations" target="_blank"><i class="fa fa-external-link"></i> <span class="sr-only">Manage</span></a>
                      </div><!--Col-->
                    </div><!--Row-->

                    <template v-if="client_organizations && client_organizations.length > 0">
                      <ul class="list-group my-3">
                      <template v-for="client_organization in client_organizations">
                        <div class="list-group-item">
                          <a :href="'/organizations/'+client_organization.organization" class="text-decoration-none" target="_blank">{{ client_organization.name }}</a>
                          <button type="button" class="btn btn-danger btn-sm float-right" @click="detachOrganization(client_organization)" data-tooltip="Unassign organization"
                           :disabled="processing">
                           <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </template>
                      </ul>
                    </template>

                    <div class="form-group">
                      <label for="assign_organization">Assign {{ setCustomLabel('organization') }}</label>
                      <select
                        id="assign_organization"
                        v-model="assign_organization"
                        class="form-control"
                        :disabled="processing">
                        <option value=""></option>
                        <template v-if="organizations && organizations.length > 0">
                          <template v-for="organization in organizations">
                            <option
                              :value="organization.uuid"
                              :disabled="client_organizations.find(co => co.organization === organization.uuid)"
                            >
                              {{ organization.name }}
                            </option>
                          </template>
                        </template>
                      </select>
                    </div>

                    <template v-if="assign_organization">
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="assignOrganization"
                        :disabled="processing"
                      >
                        Assign
                      </button>
                    </template>

                  </div>
                </div>
              </template>


                <audit-logs class="mt-3" :form="form" />
              </template>
            </div><!--Col-->
            <div class="col-md-12 col-lg-6">
              <template v-if="form && form.uuid">
                <client-contacts
                  :client_uuid="form.uuid"
                  class="mb-3"
                />

                <messages-form
                  :client="form"
                  class="mb-3"
                />

                <messages-scheduled
                  :client_uuid="form.uuid"
                  class="mb-3"
                />
                <messages-feed :client_uuid="form.uuid" />
                
              </template>
            </div>
          </div><!--Row-->
      </div><!--Col-->
    </div><!--Row-->


    <div
      id="mergeClientModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Merge Client
            </h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>

          <form @submit.prevent="postMergeClient">
            <div class="modal-body">

              <p>
              Merge this client's related data with another client.
              </p>

              <div class="form-group">
                <label for="from_client">From Client UUID</label>
                <input
                  id="from_client"
                  v-model="merge_form.from_client"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="to_client">To Client UUID</label>
                <input
                  id="to_client"
                  v-model="merge_form.to_client"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <button type="button" class="btn btn-outline-primary" @click="searchMergeClients()" :disabled="processing">Search for Duplicates</button>

              <template v-if="search_duplicates && search_duplicates.length > 0">

                <template v-for="search_duplicate in search_duplicates">
                  <div class="list-group my-3">
                  <template v-if="search_duplicate.uuid != form.uuid">
                    <li class="list-group-item">{{ search_duplicate.name }} {{ search_duplicate.client_reference }}

                      <div class="btn-group float-right">
                      <button type="button"  @click="useMergeClient(search_duplicate)" class="btn btn-outline-primary btn-sm mr-2" :disabled="processing">Use for Merge</button>
                      <a :href="'/clients/'+search_duplicate.uuid" target="_blank" class="btn btn-outline-secondary btn-sm">View Client</a>
                      </div>
                    </li>
                  </template>
                  </div>
                </template>

              </template>

              <template v-if="merge_form.from_client && merge_form.to_client">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="flipMerge"
                >
                  <i class="fa fa-repeat" /> Flip Merge
                </button>

                <div class="alert alert-warning mt-3">
                  All data associated to client: {{ merge_form.from_client }} will be merged to <a
                    :href="'/clients/'+merge_form.to_client"
                    target="_blank"
                  >{{ merge_form.to_client }}</a>
                </div>
              </template>

              <template v-if="merge_results && merge_results.length > 0">
                <p class="lead m-0">
                  Merge Results
                </p>

                <ul class="list-group">
                  <template v-for="merge_result in merge_results">
                    <li
                      class="list-group-item"
                    >
                      <code>{{ merge_result.count }}</code> {{ merge_result.table }} merged
                    </li>
                  </template>
                </ul>
              </template>
            </div>

            <div class="modal-footer">

              <a href="https://help.ecourtdate.com/how-to-merge-clients" target="_blank"><i class="fa fa-question-circle"></i> Learn more</a>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Confirm Merge
              </button>
            </div>
          </form>
        </div>
      </div>
    </div><!--Modal-->



    <div
      id="transferClientModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Transfer Client
            </h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>

          <form @submit.prevent="postTransferClient">
            <div class="modal-body">
              <template v-if="transfer_response">
                <div class="alert alert-success">
                  {{ transfer_response }}
                </div>
              </template>

              <template v-if="!transfer_agencies || transfer_agencies.length === 0">
                <div class="alert alert-warning">Set transfer agencies in <a href="/settings?search=transfers" target="_blank">Settings</a></div>
              </template>

              <template v-else>

              <div class="form-group">
                <label for="transfer_to_agency">To Agency</label>
                <select
                  id="transfer_to_agency"
                  v-model="transfer_form.to_agency"
                  class="form-control"
                >
                  <option value=""></option>
                  <template v-for="agency in transfer_agencies">
                    <option :value="agency.uuid">{{ agency.name }}</option>
                  </template>
                </select>
                <button type="button" class="btn btn-outline-primary btn-sm mt-3" @click="getAgencies()" :disabled="processing"><i class="fa fa-refresh"></i> <span class="sr-only">Refresh Agencies</span></button>

              </div>


              <div class="form-check">
                <input
                  id="transfer_archive"
                  v-model="transfer_form.archive"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="transfer_archive"
                  >Archive After Transfer is Complete</label>
                </div>

              </template>
            </div>

            <div class="modal-footer">

              <button
                type="submit"
                class="btn btn-outline-success"
                :disabled="transferring || !transfer_agencies || transfer_agencies.length === 0"
              >
                Transfer Client
              </button>
            </div>
          </form>
        </div>
      </div>
    </div><!--Modal-->
  </div>
</template>

<script>
import _ from 'lodash';
import tabs from '../mixins/tabs';
import moment from 'moment';
import queries from '../mixins/queries';

export default {

  mixins: [ tabs, queries ],
  
  data () {
    return {
      form: {},
      processing: false,
      errors: {},
      auto_messages: [],
      files: [],
      languages: [],
      base_url: '/clients',
      users: [],
      title: null,
      genders: [],
      races: [],
      marital_statuses: [],
      merge_form: {
        from_client: null,
        to_client: null
      },
      search_duplicates: [],
      transfer_form: {
        to_agency: null,
        archive: true,
        client: null
      },
      assign_organization: null,
      transfer_response: null,
      transferring: false,
      merge_results: [],
      countries: [],
      client_groups: [],
      show_new_group: false,
      queries: {},
      documents: [],
      organizations: [],
      client_organizations: [],
      photos: [],
      uploadProgress: null,
      statuses: [],
      tasks: [],
      taskForm: {
        client: null,
        content: null
      },
      transfer_agencies: []
    }
  },
  computed: {

    age: function () {
      if (this.form.dob) {
        var dob = moment(this.form.dob, 'YYYY-MM-DD', true).isValid();
        if (dob) {
          return moment().diff(this.form.dob, 'years') + ' years old';
        }
      }
      return '';
    },

    clientGroups: function() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return;
      }

      var groups = [];

      if(this.$root.current_user.current_agency.settings && this.$root.current_user.current_agency.settings.default_groups) {
        groups = this.$root.current_user.current_agency.settings.default_groups;

        groups = groups.split(",");

        groups = groups.map(g => g.trim());
      }

      var client_groups = [];

      if(this.client_groups && this.client_groups.length > 0) {
        client_groups = this.client_groups.map(r => r.group);

        client_groups.forEach(g => {
          if(!groups.includes(g)) {
            groups.push(g);
          }
        })
      }

      if(!groups) {
        return [];
      }

      return groups.map(g => g.trim());
    }

  },

  mounted() {

    var self = this;
    
    this.$eventBus.$on('clients', function (data) {
      self.updateRecord(data);
    });
    
  },

  created () {
    this.title = this.setCustomLabel('client');

    this.resetQueries();
    this.resetTaskForm();
    this.setURLParams();
    this.getLanguages();
    this.getRecord();

  },

  methods: {

    resetQueries() {
      this.queries = {
        ref: null
      }
    },

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    isHidden(navigation) {
      
        if(!this.$root.current_user || !this.$root.current_user.uuid) {
            return true;
        }

        if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
            return this.$root.current_user.hidden_navigations.includes(navigation);
        }

        return false;
    },

    updateRecord(data) {
      if(!this.form.uuid || !data.uuid || !data.updated_at) {
        return;
      }

      if(data.uuid != this.form.uuid) {
        return;
      }

      this.form = {...this.form, ...data};

      this.setTitle();
    },

    getRecord () {
      this.processing = true;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.queries))
      .then((response) => {
        if (response && response.data) {
          
          this.form = response.data;

          this.merge_form.from_client = this.form.uuid;

          this.resetQueries();

          this.setTitle();

          this.processing = false;

          this.getOptions();

          this.getDocuments();

          this.getPhotos();

          this.getStatuses();

          this.getTasks();

          this.getOrganizations();

        }
      })
    },

    resetTaskForm() {
      this.taskForm = {
        client: null,
        content: null,
        status: 'open',
        flow: null,
        due_at: this.$moment().tz(this.timezone).add(30, 'days').format('YYYY-MM-DD')
      }
    },

    postTask() {
      this.processing = true;
      this.taskForm.client = this.form.uuid;
      this.$http.post('/tasks', this.taskForm)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.resetTaskForm();
          if(response.data.uuid) {
            this.$eventBus.$emit('show-alert', {
              text: 'Task created'
            });
            if(!this.tasks) {
              this.tasks = [];
            }

            this.tasks.unshift(response.data);

            
          }
        }
      })
    },

    getTasks() {

      if(!this.form.uuid) {
        return;
      }

      this.tasks = [];
      this.$http.get('/tasks?client='+this.form.uuid)
      .then(response => {
        if(response && response.data) {
          this.tasks = response.data;
        }
      })
    },

    getStatuses() {
      this.$http.get('/statuses?limit=100&type=client&slim=true&sort=name&sortDirection=asc&fields=name')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;

          if(this.form.status && !this.statuses.find(s => s.name === this.form.status)) {
            this.statuses.push({name: this.form.status});
          }
        }
      })
    },

    setTitle() {

        var title = this.form.name || 'Client';

        if(this.form.client_reference) {
          title = title + ' ' + this.form.client_reference;
        }

        document.title = title + ' | Edit Client';
    },

    getOptions() {
      this.getClientOptions();
      this.getCountries();
      this.getAutoMessages();
      this.getFiles();
      this.getUsers();
      this.getGroups();
    },

    postForm () {

      this.processing = true;
      let form = {...this.form};
      if (this.form.users && this.form.users.length > 0) {
        form.users = this.form.users.map((u) => u.uuid);
      }
      this.$http.patch('/'+this.$route.meta.base_url+'/' + this.form.uuid, form)
      .then(response => {
        if (response && response.data) {
          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            this.$eventBus.$emit('show-alert', {
              text: this.title + ' updated'
            });
          }
          
        }
      })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/' + this.form.uuid)
      .then(response => {
        if(response && response.status === 204) {
          this.$eventBus.$emit('show-alert', {
            text: this.title + ' trashed',
            type: 'danger'
          });
          this.form.deleted_at = new Date().toISOString();
          this.processing = false;
        }
      })  
    },

    getAutoMessages () {
      this.$http.get('/auto_messages?limit=1000&sort=name&sortDirection=asc&slim=true&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.auto_messages = response.data;
        }
      })
    },

    sendAutoMessage(auto_message) {
      this.processing = true;
      this.$http.post('auto_messages/'+auto_message+'/send?client='+this.form.uuid)
      .then((response) => {
        if(response && response.data) {
            this.$eventBus.$emit('show-alert', {
              text: 'Sending message ' + response.data.content
            });
            this.processing = false;
        }
      })
    },

    getFiles() {
        this.$http.get('/files?slim=true&limit=10&sort=name&sortDirection=asc&fields=name,uuid')
        .then(response => {
            if(response && response.data) {
                this.files = response.data;
            }
        })
    },

    getUsers () {
      this.$http.get('/users?limit=1000&slim=y&fields=name,email,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if (response && response.data) {
          this.users = response.data;         
        }
      })
    },

    getAgencies() {

      if(!this.settings || !this.settings.allow_transfers_to) {
        this.transfer_agencies = [];
        return;
      }

      let agencies = this.settings.allow_transfers_to.split(',');

      if(!agencies || agencies.length === 0) {
        this.transfer_agencies = [];
        return;
      }
      
      agencies.forEach(agency => {
        this.$http.get('/agencies/'+agency)
        .then(response => {
          if(response && response.data && response.data.name) {
            this.transfer_agencies.push(response.data);
          }
        })
      })
    },


    postMergeClient() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/merge', this.merge_form)
      .then(response => {
        if(response && response.data) {
          this.merge_results = response.data;
          this.$eventBus.$emit('show-alert', {
            text: 'Merge complete'
          });
          this.processing = false;
        }
      })
    },

    flipMerge() {
      var from = this.merge_form.from_client || null;
      var to = this.merge_form.to_client || null;

      this.merge_form.to_client = from;
      this.merge_form.from_client = to;

      this.$eventBus.$emit('show-alert', {
        text: 'Merge flipped'
      });
    },

    searchMergeClients() {
      this.processing = true;
      this.$http.get('/search?search='+this.form.client_reference+'&not_client='+this.form.uuid)
      .then(response => {
        if(response && response.data) {
          this.search_duplicates = response.data;
          this.processing = false;
        }
      })
    },

    useMergeClient(client) {
      this.merge_form.to_client = client.uuid;
      this.merge_results = [];
      this.search_duplicates = [];
    },

    postTransferClient() {
      this.transferring = true;
      this.transfer_response = null;
      this.transfer_form.client = this.form.uuid;
      this.$http.post(this.base_url+'/transfer', this.transfer_form)
      .then(response => {
        if(response && response.data && response.data.message) {
          this.$eventBus.$emit('show-alert', {
            text: response.data.message
          });
          this.transferring = false;
          this.transfer_response = response.data.message;
          this.transfer_form.client = null;
        }
      })
    },

    getClientOptions() {
        this.$axios.get(this.$root.assets_url+'js/options.json')
        .then(response => {
            if(response && response.data) {
              this.races = response.data.races;
              this.genders = response.data.genders;
              this.marital_statuses = response.data.marital_statuses;
            }
        })
    },

    getGroups() {
      this.$http.get('/client_groups')
      .then(response => {
        if(response && response.data) {
          this.client_groups = response.data;
        }
      })
    },

    showNewGroup() {
      if(this.show_new_group === true) {
        this.show_new_group = false;
        return;
      }

      this.show_new_group = true;
    },


    setFieldTitle(field) {

        var fields = localStorage.getItem('fields');

        if(!fields || !fields.length) {
          return this.$options.filters.rmDashes(field);
        }


        var title = _.find(JSON.parse(fields), { 'key': 'clients.'+field });

        if(!title || !title.name) {
          return this.$options.filters.rmDashes(field);
        }

        return title.name;
    },

    getDocuments() {
      if(!this.form.uuid) {
        return;
      }

      this.$http.get('/documents?client='+this.form.uuid)
      .then(response => {
        if(response && response.data) {
          this.documents = response.data;
        }
      })
    },

    resetPhotos() {
      if(!this.form.uuid) {
        return;
      }

      this.processing = true;

      this.$http.delete('/clients/'+this.form.uuid+'/photos')
          .then(response => {
            if(response && response.status) {
              this.$eventBus.$emit('show-alert', {
                text: 'Photos reset',
                type: 'danger'
              });
              this.photos = [];

              this.processing = false;
            }
          })
    },

    getPhotos() {
      if(!this.form.uuid) {
        return;
      }

      this.processing = true;

      this.$http.get('/clients/'+this.form.uuid+'/photos')
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.photos = response.data;
            }
          })
    },

    storePhotos() {

      const files = this.$refs.photos.files;

      if(files && files.length > 0) {

        this.processing = true;

        for (let i = 0; i < files.length; i++) {

          let file = files.item(i);

          this.uploadPhoto(file, {
            progress: progress => {
              this.uploadProgress = Math.round(progress * 100);

              if(this.uploadProgress === 100) {
                this.$eventBus.$emit('show-alert', {
                  text: 'Photo uploaded'
                });
                this.uploadProgress = null;
              }
            }
          }).then(response => {
            this.processing = false;

            if(response.uuid) {
              this.getPhotos();
            }
          })

        }

      }
    },


    async uploadPhoto(file, options = {}) {
      const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
        'file_path': this.form.uuid+'/'+file.name,
        'bucket': 'ecdphotos',
        'name': file.name,
        'size': file.size,
        'type': file.type,
        'content_length': file.size,
        'extension': file.name.split('.').pop(),
        'content_type': options.contentType || file.type,
        'expires': options.expires || '',
        'visibility': options.visibility || ''
      }, {
        baseURL: options.baseURL || null,
        headers: options.headers || {},
        ...options.options
      });
      if (response && response.data) {
        let headers = response.data.headers;

        if ('Host' in headers) {
          delete headers.Host;
        }

        if (typeof options.progress === 'undefined') {
          options.progress = () => {};
        }

        const cancelToken = options.cancelToken || ''

        await this.$axios.put(response.data.url, file, {
          cancelToken: cancelToken,
          headers: headers,
          onUploadProgress: (progressEvent) => {
            options.progress(progressEvent.loaded / progressEvent.total);
          }
        }).catch(function (error) {
          if(error.response) {
            console.log(error.response.data);
          }
        });

        response.data.extension = file.name.split('.').pop();

        return response.data;
      }
      return null;
    },

    deletePhoto(photo) {
      this.processing = true;
      this.$http.delete('/clients/'+this.form.uuid+'/photos?name='+photo.name)
          .then(response => {
            if(response && response.data) {
              this.photos = this.photos.filter(p => p.name != photo.name);
              this.processing = false;
            }
          })
    },

    getPDF() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf', {responseType: 'blob'})
          .then(response => {
            if(response && response.data) {
              this.processing = true;

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', this.$route.meta.base_url+'-'+this.form.uuid+'.pdf');
              document.body.appendChild(link);
              link.click();

              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);

              this.processing = false;
            }
          })
        },


      getOrganizations() {
        this.organizations = [];
        this.$http.get('/organizations?limit=100')
        .then(response => {
          if(response && response.data && response.data.length > 0) {
            this.organizations = response.data;

            this.getClientOrganizations();
          }
        })
      },

      getClientOrganizations() {
        this.client_organizations = [];
        this.$http.get('/clients/'+this.form.uuid+'/organizations')
        .then(response => {
          if(response && response.data && response.data.length > 0) {
            this.client_organizations = response.data;

            this.client_organizations.forEach(co => {
              var org = this.organizations.find(o => o.uuid === co.organization);

              if(org && org.name) {
                co.name = org.name;
              }
            })
          }
        })
      },

      detachOrganization(client_organization) {
        this.processing = true;
        this.$http.delete('/organizations/'+client_organization.organization+'/clients?client='+this.form.uuid)
        .then(response => {
          if(response && response.status === 204) {
            this.processing = false;

            this.client_organizations = this.client_organizations.filter(co => co.organization !== client_organization.organization);
          }
        })
      },

      assignOrganization() {
        if(!this.assign_organization) {
          return;
        }

        this.processing = true;
        this.$http.post('/organizations/'+this.assign_organization+'/clients', {client: this.form.uuid})
        .then(response => {
          if(response && response.status) {
            this.processing = false;
            this.assign_organization = null;
            this.getClientOrganizations();
          }
        })
      }

  }
}
</script>

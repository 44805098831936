<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1>{{ form.name }}</h1>
        <p>
          <router-link :to="{name: 'settings.index'}">Settings</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
        </p>

      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
      </div><!--Col-->

      <div class="col-sm-12 col-md-6 text-end">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-end">
              </div><!--Col-->
            </div><!--Row-->

            <form
                @submit.prevent="postForm"
            >

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                >
              </div>


              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                    v-if="form"
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="form-control"
                ></textarea>
              </div>

              <div class="form-group">
                <label for="risk">Risk</label>
                <input
                    id="risk"
                    v-model="form.risk"
                    type="number"
                    class="form-control"
                >
                <small class="form-text text-muted">Lower equals less risk</small>
              </div>

                <div class="form-group">
                    <label for="conditions">Conditions</label>
                    <div class="form-check">
                        <template v-for="(condition, index) in conditions">

                            <div class="form-check">
                                <input
                                :id="condition"
                                :value="condition"
                                v-model="form.conditions"
                                type="checkbox"
                                class="form-check-input"
                                >
                                <label
                                class="form-check-label"
                                :for="condition"
                                >{{ condition }}</label>
                            </div>

                        </template>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <label
                    class="sr-only"
                    for="add_condition"
                    >Add Condition</label>
                    <input
                    id="add_condition"
                    v-model="add_condition"
                    type="text"
                    class="form-control"
                    placeholder="Add condition"
                    >
                    <div class="input-group-append">
                    <button
                        class="btn btn-success btn-sm"
                        type="button"
                        @click="addCondition"
                        :disabled="processing"
                    >
                        Add
                    </button>
                    </div>
                </div>


              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->


      <div class="col-sm-12 col-md-3">

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      form: {},
      add_condition: '',
      conditions: [
        'Complete Intake Form',
        'Drug Testing Required',
        'Assign Supervision Officer',
        'Monthly Criminal History Check',
        'Agree to Supervision Pay Plan',
        'Weekly Virtual Check-In',
        'Monthly Virtual Check-In',
        'Monthly In-Person Check-In',
        'Random Virtual Check-In',
        'Random Home Visit',
      ]
    }
  },


  created() {
    this.resetForm();
    this.getRecord();
  },

  methods: {

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form = {...this.form, ...response.data};
                this.setTitle();
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' updated'
                });
              }

            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        description: null,
        risk: 0,
        conditions: [],
      }
    },

    addCondition() {
      if(this.add_condition && !this.conditions.includes(this.add_condition)) {
        this.conditions.push(this.add_condition);
        this.add_condition = '';
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;

                this.setConditions();

                this.setTitle();
              }

              this.processing = false;
            }
          })
    },

    setConditions() {
        if(!this.form.conditions || this.form.conditions.length === 0) {
            this.form.conditions = [];
        }

        if(this.form.conditions && this.form.conditions.length > 0) {
            this.conditions = _.union(this.conditions, this.form.conditions);
            
        }
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title;
    },


  },
}
</script>
import Vue from 'vue';

import { datadogRum } from '@datadog/browser-rum';
Object.defineProperty(Vue.prototype, '$datadog', { value: datadogRum });

import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import moment from 'moment';
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

import MarkdownIt from 'markdown-it';
Object.defineProperty(Vue.prototype, '$markdown', { value: MarkdownIt });

import * as XLSX from 'xlsx';
Object.defineProperty(Vue.prototype, '$xlsx', { value: XLSX });

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
Vue.component('editor', Editor);

import axios from 'axios';
Object.defineProperty(Vue.prototype, '$axios', { value: axios });

import Ably from 'ably';
Object.defineProperty(Vue.prototype, '$ably', {value: Ably });

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
Object.defineProperty(Vue.prototype, '$html2canvas', { value: html2canvas });
Object.defineProperty(Vue.prototype, '$jsPDF', { value: jsPDF });

import DOMPurify from 'dompurify';
Object.defineProperty(Vue.prototype, '$DOMPurify', { value: DOMPurify });

import { faker } from '@faker-js/faker';
Object.defineProperty(Vue.prototype, '$faker', { value: faker });

import bootstrap from 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/fontawesome/css/fontawesome.min.css';
import './assets/fontawesome/css/light.min.css';
import './assets/fontawesome/css/solid.min.css';

import './assets/devices.css';

import './styles.scss';

import Multiselect from 'vue-multiselect';
Vue.component('multi-select', Multiselect); 

import "leaflet/dist/leaflet.css";
import L from "leaflet";

import markerClusterGroup from "leaflet.markercluster";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: import('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: import('leaflet/dist/images/marker-icon.png'),
   shadowUrl: import('leaflet/dist/images/marker-shadow.png'),
});

import App from './views/App.vue';

const components = require.context('./components', true, /\.vue$/i);
components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default));

import router from './router.js';
import http from './http.js';
import filters from './filters.js';
import auth from './mixins/auth';


var store = {
  current_user: {
    current_agency: {}
  },
  current_region: localStorage.getItem('current_region'),
  agency: localStorage.getItem('agency'),
  current_clear_version: '1.0',
  coordinates: [37.090240, -95.712891],
  map_layer: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
  mapbox_url: 'https://api.mapbox.com/styles/v1/ecourtdate/',
  mapbox_token: 'pk.eyJ1IjoiZWNvdXJ0ZGF0ZSIsImEiOiJjbDJoMTM5MmUwMXVqM2ltaHkwZW4zcXQ1In0.LMUuvwP7jONVzWPzk-G1MA',
  portal_assets: 'https://ecdportals.s3-us-gov-east-1.amazonaws.com/',
  bulk_action_types: [
    { value: 'Send a Message', label: 'Send a Message', description: 'Send a one-off message to a group of clients and contacts.' },
    { value: 'Assign Users', label: 'Assign Users', description: 'Assign and unassign current users to clients.' },
    { value: 'Trash Clients', label: 'Trash Clients', description: 'Bulk trash existing clients.' },
    { value: 'Archive Clients', label: 'Archive Clients', description: 'Bulk archive existing clients.' },
    { value: 'Restore Clients', label: 'Restore Clients', description: 'Bulk restore trashed or archived clients.' },
    { value: 'Transfer Clients', label: 'Transfer Clients', description: 'Transfer clients to another agency.' },
    { value: 'Update Clients', label: 'Update Clients', description: 'Update client information.' },
    { value: 'Create Events', label: 'Create Events', description: 'Schedule new events for clients.'},
    { value: 'Create Payments', label: 'Create Payments', description: 'Create new payments for clients.'},
  ],
  bulk_action_statuses: ['complete', 'scheduled', 'dispatching', 'processing', 'canceled'],
  status_types: ['client', 'case', 'contact', 'event', 'payment', 'warrant'],
  address_types: ['home', 'work', 'school', 'mail', 'hospital', 'jail', 'parents', 'other'],
  assets_url: 'https://assets.ecourtdate.com/app/',
  rtl_langs: ['ar', 'he', 'fa', 'pa', 'ku'],
  data_sources: ['messages', 'events', 'clients', 'contacts', 'client_cases', 'payments', 'opts'],
  checkin_statuses: {
    'waiting': 'Waiting',
    'ready': 'Ready',
    'in_progress': 'In Progress',
    'checked_out': 'Checked Out',
  },
  editorOptions: {
    minHeight: '600px',
    language: 'en-US',
    useCommandShortcut: false,
    usageStatistics: false,
    hideModeSwitch: false,
    previewStyle: 'vertical',
    toolbarItems: [
      ['heading', 'bold', 'italic', 'strike'],
      ['hr', 'quote'],
      ['ul', 'ol', 'task', 'indent', 'outdent'],
      ['table', 'image', 'link', 'codeblock']
    ]
  }
}

Vue.config.productionTip = false;

new Vue({
  router,
  http,
  filters,
  L,
  markerClusterGroup,
  bootstrap,
  mixins: [ auth ],
  data: store,
  render: h => h(App)
}).$mount('#app');

router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('access_token');
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth);
  if (!requiresAuth) next()
  if(!token && requiresAuth) {
    next({ name: 'login' });
  } else {
    next()
  }

});
Vue.prototype.$toast = function(options) {
  if (typeof options === 'string') {
    options = { text: options };
  }
  this.$root.$emit('show-alert', options);
};

<template>
    <div>

<div class="row">

<div class="col-sm-12 col-md-4">

    <h4>{{ form.name }} {{ $route.meta.title }}s</h4>
    <p>
        <router-link :to="{name: 'portals.index'}">Portals</router-link> /
        <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / {{ $route.meta.title }}s Viewer
    </p>

  <p class="card-title">
    {{ records.length }} of {{ total.toLocaleString() }} Total {{ $route.meta.title }}s
  </p>

</div><!--Col-->

<div class="col-sm-12 col-md-4">
    <router-link :to="{'name': 'forms.edit', 'params': { 'id': $route.params.id } }" tag="button" class="btn btn-outline-primary" :disabled="processing">
            <i class="fa fa-edit"></i> Edit Form
    </router-link>
</div>

<div class="col-sm-12 col-md-4 text-end">

  <div class="btn-group d-print-none">
    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
  </div>
</div><!--Col-->

</div><!--Row-->


    <table class="table table-hover table-striped" style="width:100%">
      <thead>
        <tr>
        <th style="width:5%">Edit</th>
          <th v-for="key in tableColumns" :hidden="isHiddenColumn(key)">{{ key }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in records">
            <td>
                <template v-if="current_row !== index">
                <router-link :to="{'name': 'submissions.edit', 'params': { 'id': row.uuid } }" tag="button" class="btn btn-outline-primary" :disabled="processing">
                    <i class="fa fa-edit"></i> Edit
                </router-link>
                </template>

                <template v-if="current_row === index">
                   <loader :isLoading="processing"></loader>
                </template>
            </td>
            <td v-for="(value, key) in row" contenteditable @input="updateCell(index, key, $event)" :hidden="isHiddenColumn(key)">
              <template v-if="isImageField(value)">
                    <img :src="value" class="img-fluid mt-3" />
              </template>
              <template v-else>
                {{  value }}
              </template>
            </td>
        </tr>
      </tbody>
    </table>
  
    </div>
  </template>
  <script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

mixins: [ queries ],
  
  data() {
      return {
        processing: false,
        downloading: false,
        form: {},
        records: [],
        downloads: [],
        total: 0,
        current_row: null
      }
  },
  
  computed: {
  
    tableColumns: function() {
  
      if(!this.records || !this.records.length) {
        return [];
      }
  
      return Object.keys(this.records[0]);
    }
  
  },

  mounted() {

    var self = this;

    this.$eventBus.$on(this.$route.meta.base_url, function(data) {

        if(!data.uuid || !data.form) {
          return;
        }

        if(data.form !== self.form.uuid) {
          return;
        }

        if(!self.records || !self.records.length) {
          self.records = [];
        }

        var index = self.records.findIndex(function(record) {
          return record.uuid === data.uuid;
        });

        if(index > -1) {
          self.records[index] = self.mapRecord(data);
        } else {
          self.records.unshift(self.mapRecord(data));
          self.total++;
        }

    });


},
  
  created() {
      this.getForm();
      this.getRecords();
  },
  
  methods: {

    getForm() {
            
            if(!this.$route.params.id) {
                return;
            }
    
            this.$http.get('/forms/'+this.$route.params.id)
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;

                    this.setTitle();
                }
            })
    },

    setTitle() {
      if(!this.form.name) {
        return;
      }

      document.title = this.form.name + ' ' + this.$route.meta.title + 's';
    },

    mapRecord(record) {
        var data = record.data;
        data.uuid = record.uuid;
        data.created_at = record.created_at;
        data.updated_at = record.updated_at;
        data.archived_at = record.archived_at;
        data.deleted_at = record.deleted_at;
        return data;
    },
  
      getRecords() {
        

          this.processing = true;
  
          this.records = [];
  
          this.getTotal();
  
          this.$http.get('/'+this.$route.meta.base_url+'?form='+this.$route.params.id)
          .then(response => {
              if(response && response.data) {
  
                this.records = response.data.map(this.mapRecord);

                this.processing = false;
              }
          })
      },
  
      getTotal() {
          
          this.total = 0;
  
          this.$http.get('/'+this.$route.meta.base_url+'/total?form='+this.$route.params.id)
          .then(response => {
              if(response && response.status === 200) {
                  this.total = response.data;
              }
          })
      },
  
      updateCell(rowIndex, key, event) {
        this.records[rowIndex][key] = event.target.textContent;

        this.updateSubmission(rowIndex);
      },

      updateSubmission: _.debounce(function (index) {
        this.postSubmission(index)
        }, 600),

      postSubmission(index) {

        var form = {
            data: this.records[index]
        }

        this.current_row = index;

        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.records[index].uuid, form)
        .then(response => {
            if(response && response.data) {
                this.processing = false;
                this.records[index].updated_at = response.data.updated_at;
                this.current_row = null;
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' updated'
                });
            }
        })
      },
  
      isHiddenColumn(key) {
  
        var hiddenColumns = ['uuid', 'agency', 'region', 'portal', 'location', 'archived_at', 'deleted_at'];
  
        return hiddenColumns.indexOf(key) > -1;
      },

  isImageField(value) {

    if(!value) {
      return false;
    }

    if(typeof value !== 'string') {
      return false;
    }

    if(value.includes('data:image')) {
      return true;
    }

    return false;
  }
  
  }
  
  }
  </script>
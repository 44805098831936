<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h4>{{ fullAddress }}</h4>
        <p>
          <router-link :to="{name: 'clients.index'}">Clients</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}es</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->


      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="!form.archived_at"
              class="dropdown-item"
              @click="archiveRecord(form)"
            >Archive</a>
            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord(form)"
            >Trash</a>
            <a
              v-if="form.archived_at || form.deleted_at"
              class="dropdown-item"
              @click="restoreRecord(form)"
            >Restore</a>
          </div>

          <template v-if="!form.archived_at && !form.deleted_at">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm"
            >
              Save
            </button>
          </template>

        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">

            <p class="card-title">Edit {{ $route.meta.title }}</p>


            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="address_1">Address 1</label>
                <input
                  id="address_1"
                  v-model="form.address_1"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="address_2">Address 2</label>
                <input
                  id="address_2"
                  v-model="form.address_2"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                  id="city"
                  v-model="form.city"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="state">State</label>

                <template v-if="form.country === 'US'">
                  <select
                    id="state"
                    v-model="form.state"
                    class="form-control"
                    :disabled="processing"
                  >
                    <template v-if="states && states.length > 0">
                      <template v-for="state in states">
                        <option
                          :value="state.key"
                        >
                          {{ state.label }}
                        </option>
                      </template>
                    </template>
                  </select>
                </template>

                <template v-else>
                  <input
                    v-model="form.state"
                    type="text"
                    class="form-control"
                  >
                  <p class="form-text text-muted">State, Province, or Region</p>
                </template>
              </div>

              <div class="form-group">
                <label for="postal_code">
                  <template v-if="form.country === 'US'">
                    Zip
                  </template>
                  <template v-else>
                    Postal Code
                  </template>
                </label>
                <input
                  id="postal_code"
                  v-model="form.postal_code"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="country">Country</label>
                <select
                  id="country"
                  v-model="form.country"
                  class="form-control"
                >
                  <template v-if="countries && countries.length > 0">
                    <template v-for="(country, index) in countries">
                      <option
                        :value="country.code"
                      >
                        {{ country.code }} - {{ country.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="address_type">Type</label>
                <select
                  id="address_type"
                  v-model="form.type"
                  class="form-control"
                  @change="setMarker()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="address_type in $root.address_types">
                    <option
                      :value="address_type"
                    >
                      {{ address_type }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="address_notes">Internal Notes</label>
                <textarea
                  id="address_notes"
                  v-model="form.notes"
                  class="form-control"
                  rows="3"
                />
              </div>

              <div class="form-group">
                <label for="latitude">Latitude</label>
                <input
                  id="latitude"
                  type="number"
                  v-model="form.latitude"
                  class="form-control"
                  step=".0000001"
                  @change="setMarker()"
                >
              </div>

              <div class="form-group">
                <label for="longitude">Longitude</label>
                <input
                  id="longitude"
                  type="number"
                  v-model="form.longitude"
                  class="form-control"
                  step=".0000001"
                  @change="setMarker()"
                >
              </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                  :hidden="form.deleted_at || form.archived_at"
                >
                  Save
                </button>

            </form>

          </div><!--Body-->
        </div><!--Card-->


        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="!processing && client.uuid">

          <div class="card">
            <div class="card-body">

          <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }} {{ client.client_reference }}
              </router-link>
              </p>

            </div>

            <div class="col-sm-12 col-md-6 text-end">
              <div class="btn-group">
              <button type="button" class="btn btn-danger btn-sm" @click="detachClient(client)" :disabled="processing">Detach</button>
              </div>
            </div><!--Col-->

          </div><!--Row-->

          <ul class="list-group">

            <template v-if="client.language">
              <li class="list-group-item">
                Language

                {{ setLanguage(client.language) }}

              </li>
            </template>

            <template v-if="client.type">
              <li class="list-group-item">
                Type {{ client.type }}
              </li>
            </template>

            <template v-if="client.group">
              <li class="list-group-item">
                Group {{ client.group }}
              </li>
            </template>

            <template v-if="client.status">
              <li class="list-group-item">
                Status {{ client.status }}
              </li>
            </template>

          </ul>

          </div><!--Body-->
        </div><!--Card-->

      </template>

        <template v-if="!form.client">

          <div class="alert alert-warning">
            <p class="mb-0">No client attached</p>
          </div>

        <form @submit.prevent="searchClients()">
          <div class="input-group mb-3">
            <input
                v-model="search"
                type="text"
                class="form-control text-lowercase"
                aria-label="Client"
                aria-describedby="client"
            >
            <div class="input-group-append">
              <button
                  id="client"
                  class="btn btn-primary"
                  type="submit"
                  :disabled="processing"
              >
                Search Clients
              </button>
            </div>
          </div>

          <template v-if="clients && clients.length > 0">
            <ul class="list-group mb-3">
              <template v-for="client in clients">
                <li
                    class="list-group-item"
                >
                  <a :href="'/clients/'+client.uuid" target="_blank">{{ client.name }}
                    <template v-if="client.client_reference">
                      ({{ client.client_reference }})
                    </template>
                  </a>

                  <button type="button" class="btn btn-success float-right" @click="attachClient(client)" :disabled="processing">Attach</button>
                </li>
              </template>
            </ul>
          </template>
        </form>
        </template>

        <div class="card mt-3">

          <div class="card-body">

            <div class="row">
                
                <div class="col-sm-12 col-md-6">
                  <p class="card-title"><i class="fa fa-barcode"></i> Generate Mail Template</p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-end">
                  <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="autoMessageButton"
              >
                Auto Messages
              </button>
              <div class="dropdown-menu" aria-labelledby="autoMessageButton" style="max-height:400px; overflow-y:auto">

                <div class="p-2">
                  <p class="card-title">{{ auto_messages.length }} Auto Messages</p>
                  <label class="sr-only" for="search_auto">Search</label>
                  <input id="search_auto" class="form-control" type="text" placeholder="Search Auto Messages" v-model="search_auto">
                </div>

                <template v-for="auto_option in auto_messages">
                  <template v-if="auto_option.templates && auto_option.templates['en']">
                    <a
                      class="dropdown-item"
                      @click="changeAutoMessage(auto_option.uuid)"
                      :hidden="!showAutoMessage(auto_option)"
                    >{{ auto_option.name }}</a>
                  </template>
                </template>
              </div>

              </div><!--Col-->

            </div><!--Row-->


            <form @submit.prevent="generatePDF">

            <div class="form-group">
                <fieldset>
                  <legend>Mail Template</legend>
                  <template v-for="mail_template in mail_templates">
                    <label
                        class="mr-2 text-capitalize"
                        :for="'pdf_'+mail_template"
                    >
                      <input
                          :id="'pdf_'+mail_template"
                          v-model="pdfForm.type"
                          type="radio"
                          name="type"
                          :value="mail_template"
                      > {{ mail_template }}
                    </label>
                  </template>
                </fieldset>
            </div>

            <div class="form-group">
              <label for="pdf_name">Name</label>
              <input
                  id="pdf_name"
                  v-model="pdfForm.name"
                  type="text"
                  class="form-control"
                  required
              >
            </div>

            <div class="form-group">
              <label for="pdf_subject">Subject</label>
              <input
                  id="pdf_subject"
                  v-model="pdfForm.subject"
                  type="text"
                  class="form-control"
                  required
              >
            </div>

            <div class="form-group">
              <label for="pdf_content">Message</label>
              <textarea
                  id="pdf_content"
                  v-model="pdfForm.content"
                  class="form-control"
                  rows="3"
                  required
              />
            </div>

            <button type="submit" class="btn btn-success" :disabled="processing">Generate PDF</button>

            </form>

        </div><!--Body-->

        </div><!--Card-->


      </div><!--Col-->
      
      <div class="col-sm-12 col-md-4">
        <div
          id="map"
          class="mb-3 map-lg"
        />

        <div class="card mt-3">

        <div class="card-body">

          <p class="card-title">Address Verification</p>

          <img src="https://assets.ecourtdate.com/images/logos/usps.webp" alt="USPS logo" width="32">

          <template v-if="verification.address && verification.address.formattedAddress">
            <p>{{ verification.address.formattedAddress }}</p>

            <button type="button" class="btn btn-outline-primary btn-sm mb-3" @click="copyToClipboard(verification.address.formattedAddress)" :disabled="processing"><i class="fa fa-copy"></i></button>
          </template>

          <ul class="list-unstyled">

          <template v-if="verification.verdict">
            
            <li>Address Complete {{ verification.verdict.addressComplete }}</li>
            <li>Validation Granularity {{ verification.verdict.validationGranularity }}</li>

          </template>

          <template v-if="verification.uspsData">
            
            <li>USPS Deliverable {{ verification.uspsData.dpvConfirmation }}</li>

            <li>Vacant {{ verification.uspsData.dpvVacant }}</li>

            <li>FIPS {{ verification.uspsData.fipsCountyCode }}</li>

            <li>CASS {{ verification.uspsData.cassProcessed }}</li>
            
          </template>

          <template v-if="verification.metadata">
            
            <template v-if="verification.metadata.business">
              <span class="badge badge-pill badge-success">Business</span>
            </template>

            <template v-if="verification.metadata.residential">
              <span class="badge badge-pill badge-success">Residential</span>
            </template>

            <template v-if="verification.metadata.poBox">
              <span class="badge badge-pill badge-success">PO BOX</span>
            </template>
          
          </template>

          </ul>

        </div><!--Body-->

        </div><!--Card-->
        
      </div><!--Col-->
    </div><!--Row-->
  </div><!--Container-->
</template>
<script>
import queries from '../mixins/queries';
export default {
    
    mixins: [ queries ],

    data () {
        return {
            processing: false,
            generating: false,
            form: {},
            mailForm: {
              type: 'letter',
              content: '',
              subject: '',
              name: '',
            },
            pdfForm: {
              type: 'letter',
              content: '',
              subject: '',
              name: '',
            },
            map: null,
            marker: {},
            loading: false,
            search: '',
            clients: [],
            client: {},
            countries: [],
            states: [],
            verification: {},
            geocodes: [],
            mail_templates: ['letter', 'postcard'],
            auto_messages: [],
            search_auto: '',
        }
    },

    computed: {
      fullAddress: {
        get() {

          var fields = [
            'address_1',
            'address_2',
            'city',
            'state',
            'postal_code',
            'country'
          ];

          var address = '';

          fields.forEach(field => {
            if(this.form[field]) {
              address += this.form[field] + ' ';
            }
          })

          return address;
        },
        set(value) {
          this.form.full_address = value;
        }
      }
    },

    mounted () {

        if(this.map) {
          this.map.remove();
        }
        
        this.initMap();
        this.getRecord();
        this.getCountries();
        this.getStates();
 
    },

      beforeDestroy() {
        if (this.map) {
          this.map.remove();
        }
      },

    methods: {

        getStates() {
            this.$axios.get(this.$root.assets_url+'js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },

        getCountries() {
            this.$axios.get(this.$root.assets_url+'js/country_codes.json')
            .then(response => {
                if(response && response.data) {
                    this.countries = response.data;
                }
            })
        },

        setStatusClass(status) {
            if(status === 'deliverable') {
                return 'badge-success';
            }

            if(status.includes("missing")) {
                return 'badge-warning';
            }

            return 'badge-danger';
        },

        getRecord () {
            this.processing = true
            this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
            .then(response => {
                if (response && response.data) {
                    this.form = response.data

                    if(this.form.full_address) {
                        this.getGeocodes(this.form.full_address);
                    }

                    this.setMarker();

                    this.processing = false;

                    this.getAutoMessages();

                    this.getClient();

                    this.getVerification();
                }
            })
        },

        getVerification() {
          this.verification = {};
          this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/verify')
          .then(response => {
            if(response && response.data) {
              this.verification = response.data;
            }
          })
        },

        getGeocodes(address) {

            if(this.form.latitude && this.form.longitude) {
                return
            }

            this.$http.get('/geocode?address='+address)
            .then(response => {
                if(response && response.data) {
                    this.geocodes = response.data

                    if(this.geocodes[0] && this.geocodes[0]['geometry']) {
                        if(this.geocodes[0]['geometry']['location']) {
                            this.form.latitude = this.geocodes[0]['geometry']['location']['lat'];
                            this.form.longitude = this.geocodes[0]['geometry']['location']['lng'];

                            this.setMarker();
                        }
                    }

                }
            })
        },

        setMarker() {

            if(!this.form.latitude || !this.form.longitude) {
              return;
            }

            if(this.map.hasLayer(this.marker)){
              this.map.removeLayer(this.marker);
            }

            this.map.flyTo([this.form.latitude, this.form.longitude], 14);

            var icon_aliases = {
              "work": "building",
              "mail": "mailbox",
              "jail": "handcuffs",
              "parents": "people-roof",
              "other": "map-marker"
            }

            var icons = [];

            this.$root.address_types.forEach(type => {

              if(icon_aliases[type]) {
                icons[type] = icon_aliases[type];
                return;
              }

              icons[type] = type;
            })

            const icon = icons[this.form.type] || 'map-marker';

            const markerIcon = window.L.divIcon({
              html: '<i class="fa text-primary fa-2x fa-'+icon+'"></i>',
              iconSize: [20, 20],
              className: 'marker-icon'
            });

            this.marker = window.L.marker([this.form.latitude, this.form.longitude], {icon: markerIcon}).addTo(this.map);

            var self = this;

            this.marker.on('click', function(e) {
              self.map.flyTo(e.latlng, 18);
            });
        },

        initMap() {

            if(this.map) {
              this.map.remove();
              this.map = null;
            }

            this.map = window.L.map("map").setView(this.$root.coordinates, 4);

            window.L.tileLayer(this.$root.map_layer).addTo(this.map);

        },

        postForm() {

            if(this.form.archived_at || this.form.deleted_at) {
                this.$eventBus.$emit('show-alert', {
                  text: 'Restore to update'
                });
                return
            }

            this.processing = true

            this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
                if(response && response.data && response.status === 200) {
                    this.processing = false;

                    if(response.data.updated_at) {
                        this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' updated'
                        });
                        this.form.updated_at = response.data.updated_at;
                        this.setMarker();
                    }
                    
                }
            })
        },

        searchClients() {
          this.processing = true;

          if(!this.search) {
            this.search = '';
          }

          this.$http.get('/clients?search=' + this.search || '')
              .then(response => {
                if (response && response.data) {
                  this.processing = false;
                  this.clients = response.data;
                }
              })
        },

        attachClient(client) {
          this.form.client = client.uuid;
          this.search = null;
          this.clients = [];
          this.postForm();
          this.getClient();
          this.initMap();
        },

        detachClient(client) {
          this.form.client = null;
          this.mailForm.name = '';
          this.pdfForm.name = '';
          this.client = {};
          this.search = null;
          this.clients = [];
          this.postForm();
          this.initMap();
        },

        getClient() {

          if(!this.form.client) {
            return;
          }

          this.$http.get('/clients/' + this.form.client)
              .then(response => {
                if (response && response.data) {
                  this.client = response.data;

                  if(this.client.name) {
                    this.pdfForm.name = this.client.name;
                  }
                }
              })
          },

          generatePDF() {
          this.processing = true;
          this.$http.post('/mails/pdf', this.pdfForm, {responseType: 'blob'})
          .then(response => {
            if(response && response.data) {
              const blobUrl = window.URL.createObjectURL(response.data);

              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = blobUrl;

              a.download = 'mail.pdf';

              document.body.appendChild(a);
              a.click();

              document.body.removeChild(a);
              window.URL.revokeObjectURL(blobUrl);

              this.processing = false;

              this.pdfForm['content'] = '';
              this.pdfForm['subject'] = '';

            }
          })
        },

        getAutoMessages () {
        this.$http.get('/auto_messages?limit=1000&fields=name,uuid,templates,file_url&slim=false&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.auto_messages = response.data;
            }
        })
        },

        changeAutoMessage (uuid) {
        this.auto_message = _.find(this.auto_messages, {'uuid': uuid});

        let language = this.client.language || 'en';
        
        this.pdfForm.content = this.auto_message.templates[language] || this.auto_message.templates['en'] || '';
        this.pdfForm.subject = this.auto_message.subject || '';

        },

        showAutoMessage(auto_message) {
        if(!auto_message) {
          return;
        }

        if(!this.search_auto) {
          return true;
        }

        if(auto_message.name.toLowerCase().includes(this.search_auto.toLowerCase())) {
          return true;
        }

        return false;
      },

    }
}
</script>

<template>
    <div>
      <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
      />
          
      <div class="row">
        <div class="col-sm-12 col-md-4">
            <h1>{{ form.reference }} {{ form.type }}</h1>
            <p>
              <router-link :to="{name: 'clients.index'}">Clients</router-link> /
              <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
            </p>
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4">
  
          <template v-if="file && file.url">
  
          <a
              :href="file.url"
              target="_blank"
              class="btn btn-secondary"
              :disabled="processing"
              :hidden="!file.url || form.deleted_at || form.archived_at"
          >
            <i class="fa fa-link"></i> Open File
          </a>
  
          </template>
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4 text-end">
          <div class="btn-group">
  
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-primary dropdown-toggle radius-right-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Tools
                </button>
                <div class="dropdown-menu">
  
                  <a
                    v-if="!form.archived_at && !form.deleted_at"
                    class="dropdown-item"
                    @click="archiveRecord(form)"
                  >Archive</a>
                  <a
                    v-if="form.archived_at || form.deleted_at"
                    class="dropdown-item"
                    @click="restoreRecord(form)"
                  >Restore</a>
                  <a
                    v-if="!form.deleted_at"
                    class="dropdown-item"
                    @click="trashRecord(form)"
                  >Trash</a>
                </div>
              </div>
              
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="updateRecord"
            >
              Save
            </button>
  
          </div>
        </div><!--Col-->
      </div><!--Row-->
  
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title">
                    Edit {{ $route.meta.title }}
                  </p>
                </div><!--Col-->
                <div class="col-6 text-end">
                </div><!--Col-->
              </div><!--Row-->
  
              <form @submit.prevent="updateRecord()">
  
                <template v-if="processing && uploadProgress">
                  <div class="progress">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        :style="'width:'+uploadProgress+'%'"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </template>
  
                <div class="form-group">
                  <div class="custom-file">
                    <input
                        id="fileUpload"
                        ref="file"
                        type="file"
                        class="custom-file-input"
                        aria-describedby="fileUpload"
                        @change="storeFile()"
                    >
                    <label
                        class="custom-file-label"
                        for="fileUpload"
                    >{{ fileName || 'Choose File' }}</label>
                  </div>
                </div>

                <div class="form-group mt-3">
                    <label for="name">Name</label>
                    <input
                        id="name"
                        v-model="form.name"
                        class="form-control"
                        required
                    >
                </div>
  
                <div class="form-group">
                  <label for="reference">Reference</label>
                  <input
                    id="reference"
                    v-model="form.reference"
                    class="form-control"
                    required
                  >
                </div>
  
                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                      id="type"
                      v-model="form.type"
                      class="form-control"
                      :disabled="processing"
                      required>
                    <template v-for="id_type in types">
                      <option
                          :value="id_type"
                      >{{ id_type }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="expires_on">Expires On</label>
                  <input id="expires_on" type="date" class="form-control" v-model="form.expires_on">
                </div>

                <div class="form-group">
                    <label for="status">Status</label>
                    <select
                        id="status"
                        v-model="form.status"
                        class="form-control"
                        :disabled="processing"
                        >
                        <option value="">Select Status</option>
                        <option value="valid">Valid</option>
                        <option value="invalid">Invalid</option>
                    </select>
                </div>

                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea id="description" class="form-control" v-model="form.description" rows="5"></textarea>
                </div>
  
                <div class="form-group">
                  <label for="notes">Notes</label>
                  <textarea id="notes" class="form-control" v-model="form.notes" rows="5"></textarea>
                </div>

                <template v-if="form.data">
                
                    <template v-for="(value, key) in form.data">
                      <div class="form-group">
                        <label :for="'data_'+key">{{ key }}</label>
                        <input
                        :id="'data_'+key"
                        v-model="form.data[key]"
                        class="form-control"
                        >
                        </div>
                    </template>
                
                </template>
  
                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Save
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
  
          <audit-logs :form="form" />
        </div><!--Col-->
        <div class="col-sm-12 col-md-4">
  
          <template v-if="file && file.url">
  
            <template v-if="file.type && file.type.includes('image/')">
              <img ref="image" :src="file.url" class="img-fluid mb-3" alt="ID Preview">
            </template>
            <template v-if="file.type && file.type.includes('application/pdf')">
              <iframe :src="file.url" title="File Preview" width="100%" height="800px" frameborder="0" border="0px" class="mb-3"></iframe>
            </template>
  
  
            <button type="button" class="btn btn-danger" @click="deleteFile()" :disabled="processing">Delete File</button>
  
          </template>
  
  
            <div class="card my-3" :hidden="!file.url">
              <div class="card-body">
                <p class="card-title">Optical Character Recognition</p>
  
                <p class="card-text m-0">Scan the file to extract text</p>
  
                <button type="button" class="btn btn-success" @click="getOCR()" :disabled="processing">Scan File</button>
  
                <canvas ref="canvas" style="border:1px darkblue dashed; width:100%" class="my-3"></canvas>
  
                <template v-if="ocr && ocr.inference && ocr.inference.pages">
  
                  <button type="button" class="btn btn-primary" @click="mapOCR()" :disabled="processing">Map Fields</button>
  
                  
                <template v-for="page in ocr.inference.pages">
                  <ul class="list-group my-3">
                    <template v-for="(value, key) in page.prediction">
                      <li class="list-group-item">
                        <strong class="text-uppercase">{{ key | rmDashes }}</strong>
                        <br>
                        <template v-if="value.value">
                          {{ value.value }}
                        </template>
                        </li>
                    </template>
                  </ul>
                </template>
  
                </template>
  
  
              </div><!--Body-->
            </div><!--Card-->
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4">
  
          <div class="card">
            <div class="card-body">
  
              <form @submit.prevent="searchClients()">
  
                <div class="form-group">
                  <label for="search_client">Search Clients</label>
                  <input id="search_client" type="search" class="form-control" v-model="search_client">
                </div>
  
                <button type="submit" class="btn btn-primary" :disabled="searching">Search</button>
  
              </form>
  
  
              <template v-if="clients && clients.length > 0">
  
                <ul class="list-group my-3">
  
                  <template v-for="client in clients">
                    <li class="list-group-item" :disabled="processing">
  
                      <div class="row">
  
                        <div class="col-sm-12 col-md-9">
                          {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }} {{ client.client_reference }}
                          <br>
                          <template v-if="client.group">
                            <small>{{ client.group }}</small>
                          </template>
                          <template v-if="client.language">
                            <small>{{ client.language }}</small>
                          </template>
                        </div><!--Col-->
  
                        <div class="col-sm-12 col-md-3">
                          <button type="button" class="btn btn-sm btn-success btn-block" @click="attachClient(client)" :disabled="processing">Attach</button>
                        </div><!--Col-->
  
                      </div><!--Row-->
  
                    </li>
                  </template>
  
                </ul>
  
              </template>
            </div>
          </div>
  
  
          <div class="card my-3">
            <div class="card-body">
  
              <template v-if="!client.uuid">
                <div class="alert alert-warning m-0">
                  No client found
                </div>
              </template>
  
              <template v-if="!processing && client.uuid">
                <div class="row mb-3">
                  <div class="col-sm-12 col-md-9">
                    <p class="card-title">
                      Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.name }} {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }} {{ client.client_reference }}
                    </router-link>
                    </p>
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-3">
                    <button
                        type="button"
                        class="btn btn-danger btn-sm btn-block"
                        @click="detachClient()"
                    >
                      Detach
                    </button>
                  </div><!--Col-->
  
                </div><!--Row-->
  
                <ul class="list-group">
  
                  <template v-if="client.language">
                    <li class="list-group-item">
                      Language
  
                      {{ setLanguage(client.language) }}
  
                    </li>
                  </template>
  
                  <template v-if="client.type">
                    <li class="list-group-item">
                      Type {{ client.type }}
                    </li>
                  </template>
  
                  <template v-if="client.group">
                    <li class="list-group-item">
                      Group {{ client.group }}
                    </li>
                  </template>
  
                  <template v-if="form.status">
                    <li class="list-group-item">
                      Status {{ client.status }}
                    </li>
                  </template>
  
                </ul>
              </template>
            </div><!--Body-->
          </div><!--Card-->
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  import _ from "lodash";
  
  export default {
      
      mixins: [ queries ],
  
      data () {
          return {
              processing: false,
              searching: false,
              search_client: null,
              uploadProgress: null,
              form: {},
              client: {},
              clients: [],
              options: {
                'languages': [],
                'states': [],
                'countries': []
              },
              fileName: '',
              file: {},
              ocr: {},
              types: ['Driver License', 'Proof of Address', 'State ID', 'Passport', 'Passport Card', 'Social Security Card', 'Birth Certificate', 'Marriage Certificate', 'Divorce Certificate', 'Death Certificate', 'Adoption Certificate', 'Naturalization Certificate', 'Military Service', 'Other']
  
          }
      },
  
      created () {
  
          this.resetForm();
          this.getRecord();
  
          var self = this;
  
        for (let optionsKey in this.options) {
          self.getOptions(optionsKey);
        }
      },
  
      methods: {
  
        resetForm() {
          this.form = {
            reference: null,
            client: null,
            type: null,
            notes: null,
            expires_on: null,
            status: null,
            data: {}
          }
  
          if(!this.$refs.file) {
            return;
          }
  
          this.$refs.file.name = null;
          this.$refs.file.value = null;
          this.uploadProgress = null;
          this.fileName = '';
        },
  
          getRecord () {
              this.processing = true;
              this.$http.get(this.buildQueries('/'+this.$route.meta.base_url + '/' + this.$route.params.id, this.queries))
              .then(response => {
                  if (response && response.data) {
                      this.form = response.data;
                      this.processing = false;

                      if(!this.form.data) {
                        this.form.data = {};
                      }
  
                      if(response.data.value) {
                          document.title = response.data.value + ' | eCourtDate.com';
                      }
  
                      this.getFile();
  
                      this.getClient();
                  }
              })
          },
  
        getFile() {
  
          if(!this.form.file) {
            return;
          }
  
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/download')
              .then(response => {
                if(response && response.data) {
  
                  if(response.data.url) {
                    this.file = response.data;
                    this.downloadFile();
                  }
                }
              })
        },
  
        getOCR() {
          if(!this.file.url) {
            return;
          }
  
          this.processing = true;
  
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/ocr?type='+this.form.type)
              .then(response => {
                if(response && response.data) {
                  this.processing = false;
                  this.ocr = response.data;
  
                  this.drawPolygons();
                }
              })
        },
  
        downloadFile() {
          if(!this.file.url) {
            return;
          }
  
          this.$axios.get(this.file.url, {
            responseType: 'blob'
          }).then(response => {
            if(response && response.data) {
                this.file.type = response.data.type;
                this.file.size = response.data.size;
  
            }
          })
        },

  
          updateRecord() {
              this.processing = true;
              this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
              .then(response => {
                  if(response && response.data) {
  
                      if(response.data.updated_at) {
                        this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' updated'
                        });
                        this.form.updated_at = response.data.updated_at;
                        this.getClient();
                        this.getFile();
                      }
                      
                      this.processing = false;
                  }
              })
          },
  
        getClient() {
  
          if(!this.form.client) {
            return;
          }
  
            this.$http.get('/clients/'+this.form.client+'?slim=true')
                .then(response => {
                    if(response && response.data) {
                        this.client = response.data;
                    }
                })
        },
  
        searchClients() {
  
          this.searching = true;
  
          this.$http.get('/clients?slim=true&limit=5&fields=uuid,first_name,middle_name,last_name,type,client_reference,group,language&search='+this.search_client)
              .then(response => {
                if(response && response.data) {
                  this.clients = response.data;
                  this.searching = false;
                }
              })
        },
  
        attachClient(client) {
          this.client = {};
          this.form.client = client.uuid;
          this.search_client = null;
          this.updateRecord();
          this.clients = [];
        },
  
        detachClient() {
          this.client = {};
          this.form.client = null;
          this.updateRecord();
        },
    
        getOptions(key) {
          this.$axios.get(this.$root.assets_url+'js/'+key+'.json')
              .then(response => {
                if(response && response.data) {
                  this.options[key] = response.data;
                }
              })
        },
  
        setLabel () {
          if (this?.$refs?.file?.files?.length > 0) {
            this.fileName = this.$refs.file.files[0].name;
          } else {
            this.fileName = 'Choose File';
          }
        },
  
        storeFile () {
  
          if(!this.$refs.file || !this.$refs.file.files[0]) {
            return;
          }
  
  
            this.processing = true;
            this.storeUpload(this.$refs.file.files[0], {
              progress: progress => {
                this.uploadProgress = Math.round(progress * 100);
              }
            }).then(file_response => {
              if(file_response && file_response.key) {
  
                this.form.file = file_response.key;
  
                this.updateRecord();
  
              }
  
            })
        },
  
        async storeUpload(file, options = {}) {
          const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
            'file_path': this.form.uuid,
            'bucket': 'ecdids',
            'name': file.name,
            'size': file.size,
            'type': file.type,
            'content_length': file.size,
            'extension': file.name.split('.').pop(),
            'content_type': options.contentType || file.type,
            'expires': options.expires || '',
            'visibility': options.visibility || ''
          }, {
            baseURL: options.baseURL || null,
            headers: options.headers || {},
            ...options.options
          });
          if (response && response.data) {
            let headers = response.data.headers;
  
            if ('Host' in headers) {
              delete headers.Host;
            }
  
            if (typeof options.progress === 'undefined') {
              options.progress = () => {};
            }
  
            const cancelToken = options.cancelToken || ''
  
            await this.$axios.put(response.data.url, file, {
              cancelToken: cancelToken,
              headers: headers,
              onUploadProgress: (progressEvent) => {
                options.progress(progressEvent.loaded / progressEvent.total);
              }
            }).catch(function (error) {
              if(error.response) {
                console.log(error.response.data);
              }
            });
  
            response.data.extension = file.name.split('.').pop();
  
            return response.data;
          }
          return null;
        },
  
        deleteFile() {
          if(!this.file.url) {
            return;
          }
  
          this.form.file = null;
  
          this.file = {};
  
          this.updateRecord();
        },
  
        mapOCR() {
          if(!this.ocr || !this.ocr.inference || !this.ocr.inference.pages) {
            return;
          }
  
          var formFields = {
            "driver_license_id": "reference",
            "expiry_date": "expires_on",
          }
  
          for (const page of this.ocr.inference.pages) {
            for (const field in page.prediction) {
              if (page.prediction.hasOwnProperty(field)) {
                const data = page.prediction[field];
  
                if(!data.value) {
                  continue;
                }

                this.form.data[field] = data.value;
  
                if(formFields[field]) {
                  this.form[formFields[field]] = data.value;
                }
  
              }
            }
          }
        },
  
        drawPolygons() {
        const canvas = this.$refs.canvas;
        const image = this.$refs.image;
        const ctx = canvas.getContext("2d");
    
        canvas.width = image.width;
        canvas.height = image.height;
  
        ctx.drawImage(image, 0, 0, image.width, image.height);
    
        // Loop through OCR data and draw polygons
        for (const page of this.ocr.inference.pages) {
          for (const field in page.prediction) {
            if (page.prediction.hasOwnProperty(field)) {
              const data = page.prediction[field];
  
              if(!data.polygon) {
                continue;
              }
  
              const polygon = data.polygon.map(point => [
                point[0] * image.width,
                point[1] * image.height
              ]);
  
              // Draw the polygon
              ctx.beginPath();
  
              if(!polygon || !polygon[0] || !polygon[0][0] || !polygon[0][1]) {
                continue;
              }
  
              ctx.moveTo(polygon[0][0], polygon[0][1]);
              for (let i = 1; i < polygon.length; i++) {
                ctx.lineTo(polygon[i][0], polygon[i][1]);
              }
              ctx.closePath();
              ctx.lineWidth = 2;
              ctx.strokeStyle = "red";
              ctx.stroke();
  
              // Draw the text
  
              if(!data.value) {
                continue;
              }
  
              const centroid = this.getCentroid(polygon);
              ctx.fillStyle = "red";
              ctx.font = "16px Arial";
              ctx.fillText(field, centroid[0], centroid[1]);
            }
          }
        }
      },
  
      getCentroid(polygon) {
        const x = polygon.map(point => point[0]);
        const y = polygon.map(point => point[1]);
        const centroidX = x.reduce((a, b) => a + b, 0) / x.length;
        const centroidY = y.reduce((a, b) => a + b, 0) / y.length;
        return [centroidX, centroidY];
      }
  
      }
  }
  </script>
  
<template>
  <div>

    <div class="card" v-if="form.uuid">
      <div class="card-body">

          <div class="row mb-3">

            <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': form.uuid} }">
                    {{ form.name }} {{ form.first_name }} {{ form.middle_name }} {{ form.last_name }} {{ form.client_reference }}
                  </router-link>
                </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6">

              <button type="button" class="btn btn-danger btn-sm float-right" @click="detachRecord()" :disabled="processing">Detach</button>

            </div><!--Col-->

          </div><!--Row-->


          <ul class="list-group">

            <template v-if="form.language">
              <li class="list-group-item">
                Language

                {{ setLanguage(form.language) }}
              </li>
            </template>

            <template v-if="form.type">
              <li class="list-group-item">
                Type {{ form.type }}
              </li>
            </template>

            <template v-if="form.group">
              <li class="list-group-item">
                Group {{ form.group }}
              </li>
            </template>

            <template v-if="form.status">
              <li class="list-group-item">
                Status {{ form.status }}
              </li>
            </template>

          </ul>

      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import _ from "lodash";

export default {

props: ['client'],

data() {
    return {
        processing: false,
        form: {},
        base_url: '/clients',
        languages: []
    }
},

created() {
    this.getRecord();
    this.getLanguages();
},

methods: {

        getRecord() {
            
            if(!this.client) {
                return;
            }

            this.processing = true;
            this.form = {};

            this.$http.get(this.base_url+'/'+this.client.client+'?slim=true&fields=uuid,first_name,last_name,client_reference,type,group,status,language')
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;
                    this.processing = false;
                }
            })
        },

        detachRecord() {

          this.processing = true;

          this.$http.delete('/events/'+this.client.event+'/detach?client='+this.form.uuid)
          .then(response => {
            if(response && response.data) {

              if(response.data.message) {
                this.$eventBus.$emit('detach_client', this.form.uuid);
                this.$eventBus.$emit('show-alert', {
                  text: response.data.message
                });
              }

              this.processing = false;
              
            }
          })

        },

  getLanguages () {
    this.$axios.get(this.$root.assets_url+'js/languages.json')
        .then(response => {
          if(response && response.data) {
            this.languages = response.data;
          }
        })
  },

  setLanguage(language) {

    if(!language) {
      return;
    }

    var result = _.find(this.languages, {'key': language});

    if(!result) {
      return language;
    }

    return result.label;

  }

}

}
</script>
<template>
  <div>

    <page-tabs page="portals" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
              Search {{ $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search Data</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <div class="form-group">
                <label for="search_form">Form</label>
                <select id="search_form" class="form-control" v-model="queries.form" :disabled="processing">
                  <option value=""></option>
                  <template v-for="form_template in form_templates">
                    <option :value="form_template.uuid">{{ form_template.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                  <label for="search_language">Language</label>
                  <select
                    id="search_language"
                    v-model="queries.language"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="">Any</option>
                    <option
                      v-for="language in languages"
                      :value="language.key"
                    >
                      {{ language.label }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="search_portal">Portal</label>
                  <select id="search_portal" class="form-control" v-model="queries.portal" :disabled="processing">
                    <option value=""></option>
                    <template v-for="portal in portals">
                      <option :value="portal.uuid">{{ portal.name }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="sort">Sort</label>
                  <select
                    id="sort"
                    v-model="queries.sort"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="created_at">Created At</option>
                    <option value="updated_at">Updated At</option>
                    <option value="name">Name</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="sortDirection">Sort Direction</label>
                  <select
                    id="sortDirection"
                    v-model="queries.sortDirection"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </div>

                <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.trashed"
                    >
                    <label
                      class="form-check-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.archived"
                    >
                    <label
                      class="form-check-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->


      <div class="col-sm-12 col-md-9">

            <div class="row mb-3">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">
                <template v-if="queries.form">
                  <a :href="'/forms/'+queries.form+'/submissions'" class="btn btn-outline-primary btn-sm" :disabled="processing">
                    <i class="fa fa-list"></i> View All Submissions
                  </a>
                </template>
                <button type="button" class="btn btn-secondary btn-sm" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div><!--Col-->

            </div><!--Row-->

            <div class="row">

                <template v-for="record in records">
                  <div class="col-md-4 col-sm-6 mb-3">
                    <div class="card">
                      <div class="card-body" style="max-height: 460px; overflow-y: auto;">

                        <template v-if="record.archived_at">
                          <p><span class="badge badge-warning text-uppercase">Archived</span></p>
                        </template>

                        <template v-if="record.trashed_at">
                          <p><span class="badge badge-danger text-uppercase">Trashed</span></p>
                        </template>

                        <h5 class="card-title">
                          <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                            <i class="fa fa-edit"></i> {{ record.name || record.uuid }}
                          </router-link>
                        </h5>
                       
                        <button type="button" class="btn btn-secondary btn-sm my-3" @click="downloadRecordPDF(record)" :disabled="processing"><i class="fa fa-file-pdf"></i> PDF </button>

                        <template v-if="record.form">
                          <p class="m-0">
                              <strong>Form</strong> <a :href="'/forms/'+record.form" target="_blank">{{ getFormTemplate(record) }}</a>
                          </p>
                        </template>

                        <template v-if="record.portal">
                          <p class="m-0">
                              <strong>Portal:</strong> <a :href="'/portals/'+record.portal" target="_blank">{{ setPortalName(record) }}</a>
                          </p>
                        </template>

                        <template v-if="record.language">                      
                          <p class="m-0">
                              <strong>Language:</strong> {{ setLanguage(record.language) }}
                          </p>
                        </template>

                        <p class="m-0">
                          <strong>Created:</strong> {{ record.created_at | datetime }}
                        </p>

                        <template v-if="record.updated_at">
                          <p class="m-0">
                            <strong>Updated:</strong> {{ record.updated_at | datetime }}
                          </p>
                        </template>

                        <template v-if="record.archived_at">
                          <p class="m-0">
                            <strong>Archived:</strong> {{ record.archived_at | datetime }}
                          </p>
                        </template>

                        <template v-if="record.deleted_at">
                          <p class="m-0">
                            <strong>Trashed:</strong> {{ record.deleted_at | datetime }}
                          </p>
                        </template>

                        <h5 class="mt-3">SUBMISSION DATA:</h5>

                        <ul class="list-group">
                          <template v-for="(field, field_index) in record.data">
                            <li class="list-group-item"><span class="badge badge-pill badge-secondary">{{ field_index }}</span> {{ field }}</li>
                          </template>
                        </ul>

                      </div>
                    </div>
                  </div>
                </template>

              </div><!--Row-->
                

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="loadMore"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        show_load: false,
        records: [],
        queries: {},
        total: 0,
        form_templates: [],
        portals: []
    }
},

created() {
    this.resetRecords();
    this.resetQueries();
    this.setURLParams();
    this.getRecords();
    this.getForms();
    this.getPortals();
},

methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    resetQueries() {
      this.queries = {
        skip: 0,
        limit: 10,
        client: null,
        form: null,
        portal: null,
        language: null,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;      
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    setStatusClass(status) {

      if(status == 'private') {
        return 'badge-danger';
      }

      if(status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    getForms() {
      this.$http.get('/forms?slim=true&limit=100&sort=name&sortDirection=desc&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.form_templates = response.data;
        }
      })
    },

    getPortals() {
      this.portals = [];
      this.$http.get('/portals?slim=true&limit=100&sort=name&sortDirection=desc&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;
        }
      })
    },

    setPortalName(record) {
      if(!record.portal) {
        return;
      }

      var portal = this.portals.find(p => p.uuid == record.portal);

      if(portal) {
        return portal.name;
      }
    },

    getFormTemplate(record) {
      if(!record.form) {
        return;
      }

      var form = this.form_templates.find(f => f.uuid == record.form);

      if(form && form.name) {
        return form.name;
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

      this.processing = true;

      this.updateURL();

      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
          if(response && response.data) {

              this.records = response.data

              if (response.data.length < this.queries.limit) {
                  this.show_load = false
              } else {
                  this.show_load = true
              }

              this.processing = false
          }
      })
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    loadMore() {
      this.processing = true;
      this.show_load = false;

      this.queries.skip = this.records.length;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {

              if(response.data.length < this.queries.limit) {
                this.show_load = false;
              } else {
                this.show_load = true;
              }

              if(response.data.length > 0) {
                response.data.forEach(r => {
                    this.records.push(r);
                    this.total++;
                })                
              }

              this.processing = false;
          } else {
            this.show_load = false;
          }
        });
    },

    downloadRecordPDF(record) {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+record.uuid+'/pdf', {responseType: 'blob'})
      .then(response => {
        if(response && response.data) {
          this.processing = true;

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.$route.meta.base_url+'-'+record.uuid+'.pdf');
          document.body.appendChild(link);
          link.click();

          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.processing = false;
        }
      })
    },


}

}
</script>
<template>
  <div>
   
      <div class="card mb-3">
        <div class="card-body">

          <div class="row mb-3">

            <div class="col-sm-12 col-md-8">

          <p class="card-title">
            Send a {{ title }}

            <template v-if="!form.uuid && tos && tos.length > 0">
              to {{ tos.length }} Contacts
            </template>

          </p>

          </div><!--Col-->

          <div class="col-sm-12 col-md-4 text-end">
            <button type="button" class="btn btn-sm btn-outline-secondary btn-sm m-0" data-bs-toggle="tooltip" :data-tooltip="show_options === true ? 'Hide Options' : 'Show Options'" :class="{'active': show_options === true}" @click="toggleOptions() && this.blur()"><i class="fa fa-grid"></i> <span class="sr-only">Options</span></button>
          </div><!--Col-->

        </div><!--Row-->


          <template v-if="form.test == 1">
            <div class="alert alert-warning">
              Simulate Mode is enabled for this message. 
              <button
                class="btn btn-light"
                :disabled="processing"
                @click="sendLive"
              >
                Send Live
              </button>
            </div>
          </template>

          <template v-if="!form.uuid && !client">

            <template v-if="tos && tos.length">
              <div class="row mb-3">
                <div class="col-12">
                <template v-for="to in tos">
                  <span class="badge badge-pill badge-primary link-hover mr-1" @click="removeTo(to.to)">{{ to.to }}  <i class="fa fa-times"></i> </span>
                </template>
                </div><!--Col-->
              </div><!--Row-->
            </template>

          <form @submit.prevent="addTo">

              <label for="add_to">To (Client, Phone, or Email)</label><br>
              <div class="input-group mb-3">
                <input
                  v-model="add_to"
                  ref="add_to"
                  id="add_to"
                  type="text"
                  class="form-control text-lowercase"
                  :class="setAddToClass()"
                  aria-label="To"
                  aria-describedby="add-to"
                  @input="searchClients()"
                  required
                >
                <div class="input-group-append">
                  <button
                    id="add-to"
                    class="btn btn-success"
                    type="submit"
                    :disabled="isAddToDisabled()"
                  >
                   Add
                  </button>
                </div>
              </div>

          </form>

            <template v-if="search_results && search_results.length > 0">

              <div class="list-group mb-3">

              <template v-for="search_result in search_results">

                <button class="list-group-item list-group-item-action" @click="addClient(search_result.uuid)">{{ search_result.result }}</button>

              </template>

              </div>

            </template>

          </template>

        <form @submit.prevent="postForm">

          <div class="row" :hidden="!show_options">
            <div class="col-12 col-md-6">
              <div class="form-group vbDatePicker">
                <label for="send_date">Send Date</label>
                <input
                  id="send_date"
                  v-model="form.date"
                  placeholder="mm/dd/yyyy"
                  type="date"
                  class="form-control"
                >
              </div>
            </div><!--Col-->
            <div class="col-12 col-md-6">
              <div class="form-group vbTimePicker">
                <label for="send_time">Send Time</label>
                <input
                  id="send_time"
                  v-model="form.time"
                  placeholder="hh:mm AM"
                  type="time"
                  class="form-control"
                >
              </div>
            </div><!--Col-->
          </div><!--Row-->

          <div class="row mb-3" :hidden="!show_options">

            <div class="col-sm-12 col-md-4">

              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="autoMessageButton"
              >
                Auto Messages
              </button>
              <div class="dropdown-menu" aria-labelledby="autoMessageButton" style="max-height:400px; overflow-y:auto">

                <div class="p-2">
                  <p class="card-title">{{ auto_messages.length }} Auto Messages</p>
                  <label class="sr-only" for="search_auto">Search</label>
                  <input id="search_auto" class="form-control" type="text" placeholder="Search Auto Messages" v-model="search_auto">
                </div>

                <template v-for="auto_option in auto_messages">
                  <template v-if="auto_option.templates && auto_option.templates['en']">
                    <a
                      class="dropdown-item"
                      @click="changeAutoMessage(auto_option.uuid)"
                      :hidden="!showAutoMessage(auto_option)"
                    >{{ auto_option.name }}</a>
                  </template>
                </template>
              </div>

            </div><!--Col-->

            <div class="col-sm-12 col-md-4">

              <merge-tags
                @add-tag-to-message="addTagToMessage"
              />

            </div><!--Col-->

            <div class="col-sm-12 col-md-4">

                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="filesButton"
                      :disabled="processing"
                    >
                      Attach Files
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filesButton" style="max-height:400px; overflow-y:auto">

                      <div class="p-2">
                        <p class="card-title">{{ files.length }} Files</p>
                        <label class="sr-only" for="search_files">Search</label>
                        <input id="search_files" class="form-control" type="text" placeholder="Search Files" v-model="search_files">
                      </div>

                      <template v-for="file in files">
                          <a
                            class="dropdown-item"
                            @click="attachFile(file.merge_tag)"
                            :hidden="!showFile(file)"
                          >{{ file.name }} </a>
                      </template>

                      <template v-if="files && !files.length && !processing">
                        <a class="dropdown-item" href="/files">Upload Files</a>
                      </template>

                    </div>

            </div><!--Col-->

          </div><!--Row-->

          <div class="form-group">
            <label for="subject">Subject</label>
            <input 
              :dir="setLangDir()"
              id="subject" 
              type="text" 
              class="form-control" 
              v-model="form.subject"
            >
          </div>

          <div class="form-group">
            <label for="message">Message <template v-if="form.content && form.content.length >= 1"><span class="badge badge-pill" :class="setCharacterClass(form.content)">{{ form.content.length }}</span></template></label>
            <textarea
              :dir="setLangDir()"
              id="message"
              ref="formContent"
              v-model="form.content"
              class="form-control"
              :class="setValidateClass()"
              rows="5"
              required
            />
            <template v-if="form.language && form.language != 'en'">
              <span class="badge badge-pill badge-secondary mt-1">
                {{ setLanguage(form.language) }}
              </span>
            </template>
          </div>

          <div class="form-group">
            <label for="attachments">Attachments</label>
            <input
              id="attachments"
              ref="files"
              type="file"
              class="form-control"
              multiple="true"
              @change="updateAttachments()"
              :disabled="processing"
            >
          </div>

          <template v-if="uploadProgress > 1 && uploadProgress < 100">
            <div class="progress">
              <progress
                class="progress-bar"
                :style="'width:'+uploadProgress+'%'"
              >
                {{ uploadProgress }}%
              </progress>
            </div>
          </template>

          <template v-if="attachments && attachments.length > 0">

            <ul class="list-group">

            <template v-for="attachment in attachments">

              <li class="list-group-item">{{ attachment.name }}</li>

            </template>

            </ul>

          </template>

    

          <div class="row mt-3">
            <div class="col-sm-12 col-md-6">
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing || isDisabled"
              >
                Send Message
              </button>
            </div><!--Col-->
            <div class="col-sm-12 col-md-6 text-end">
              <div class="btn-group">

                <template v-if="client && client.language && client.language != 'en'">
                  <button type="button" class="btn btn-outline-success btn-sm" @click="autoTranslate(client.language)" :disabled="processing"><i class="fa fa-language"></i> Auto Translate</button>
                </template>

                <template v-else>

                <div class="btn-group" :hidden="!form.content">
                  <button type="button" class="btn btn-outline-success btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" :disabled="processing">
                  <i class="fa fa-language"></i> Auto Translate
                  </button>
                  <div class="dropdown-menu" style="max-height: 400px; overflow-y:auto">
                    <template v-for="language in languages">
                      <template v-if="form.language != language.key">
                      <button type="button" class="dropdown-item" @click="autoTranslate(language.key)" :hidden="client && client.language && client.language == language.key">{{ language.label }}</button>
                      </template>
                    </template>
                  </div>
                </div>

                </template>

              </div>
            </div><!--Col-->
          </div><!--Row-->

        </form>

        </div><!--Body-->
      </div><!--Card-->


  </div>
</template>

<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  props: ['client', 'event', 'payment', 'content', 'subject'],

  mixins: [queries],

  data () {
    return {
      base_url: '/messages',
      title: 'Message',
      form: {},
      processing: false,
      searching: null,
      show_options: false,
      search_files: '',
      search_auto: '',
      clients: [],
      uploadProgress: null,
      auto_messages: [],
      auto_message: {},
      files: [],
      tos: [],
      add_to: null,
      invalid_to: false,
      search_results: [],
      attachments: []

    }
  },

  computed: {

    isDisabled: function() {

      if(this.attachments && this.attachments.length > 0) {
        return false;
      }

      if(!this.form.content || !this.form.content.length) {
        return true;
      }

      if(this.client && this.client.uuid) {
        return false;
      }

      if(this.tos && this.tos.length > 0) {
        return false;
      }

      return true;
    },

  },

  created() {
    this.resetForm();
    this.resetAttachments();

    if(localStorage.getItem('show_options') && localStorage.getItem('show_options') == 'true') {
      this.show_options = true;
    }

    if(this.content) {
      this.form.content = this.content;
    }

    if(this.subject) {
      this.form.subject = this.subject;
    }

    this.getMergeTags();
    this.getAutoMessages();
    this.getLanguages();
    this.getFiles();
  },

  methods: {

    setLangDir() {
      if(!this.form.language) {
        return 'ltr';
      }

      if(this.$root.rtl_langs.includes(this.form.language)) {
        return 'rtl';
      }

      return 'ltr';
    },

    sendLive() {
      this.form.test = 0;
      this.$eventBus.$emit('show-alert', {
        text: 'Message changed to Live Mode'
      });
    },

    attachFile (merge_tag) {
      if(this.form.content.includes(merge_tag)) {
        return;
      }

      this.form.content += ' {'+merge_tag+'}';
    },

    showFile(file) {
      if(!file) {
        return;
      }

      if(!this.search_files) {
        return true;
      }

      if(file.name.toLowerCase().includes(this.search_files.toLowerCase())) {
        return true;
      }

      return false;
    },

    showAutoMessage(auto_message) {
      if(!auto_message) {
        return;
      }

      if(!this.search_auto) {
        return true;
      }

      if(auto_message.name.toLowerCase().includes(this.search_auto.toLowerCase())) {
        return true;
      }

      return false;
    },

    changeAutoMessage (uuid) {
        this.auto_message = _.find(this.auto_messages, {'uuid': uuid});

        let language = this.client && this.client.language ? this.client.language : 'en';

        if(this.auto_message.templates[language] && this.auto_message.templates[language].length > 0) {
          this.form.content = this.auto_message.templates[language];
        } else {
          this.form.content = this.auto_message.templates['en'] || '';
        }

        this.form.subject = this.auto_message.subject || '';
        this.form.test = this.auto_message.simulate || 0;

        if(this.auto_message.file_url) {
          this.getFile(this.auto_message.file_url);
        }
    },

    getFile(url) {
      this.processing = true;
      this.$http.get('/files?limit=1&slim=true&fields=uuid&url='+url)
      .then(response => {
        if(response && response.status) {

          if(response.data && response.data[0] && response.data[0]['uuid']) {
            this.form.file = response.data[0]['uuid'];
            this.form.file_url = url;
          }

          this.processing = false;
        }
      })
    },

    getAutoMessages () {
        this.$http.get('/auto_messages?limit=20&fields=name,uuid,templates,subject,file_url&slim=false&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.auto_messages = response.data;
            }
        })
    },

    getFiles() {
      this.$http.get('/files?limit=20&fields=name,uuid,merge_tag&slim=true&sort=created_at&sortDirection=desc')
      .then(response => {
        if(response && response.data) {
          this.files = response.data;
        }
      })
    },

    addTagToMessage (value) {

      let selectionIndex = this.$refs.formContent.selectionStart;
      let newValue = '';
      if (this.form.content) {
        newValue = this.form.content.slice(0, selectionIndex) + ' ' + value + ' ' + this.form.content.slice(selectionIndex, this.form.content.length);
      } else {
        newValue = value;
      }
      this.form.content = newValue;

    },

    postForm () {

      this.processing = true;

      if(this.client) {
        this.form.client = this.client.uuid;
      }

      if(this.event) {
        this.form.event = this.event;
      }

      if(this.payment && this.payment.uuid) {
        this.form.payment = this.payment.uuid;

        if(this.payment.case_number) {
          this.form.case_number = this.payment.case_number;
        }
      }

      if(this.$refs.files.files && this.$refs.files.files.length > 0) {
        this.form.mms = this.$refs.files.files.length;
      }

      if(this.form.uuid) {
        this.updateForm();
        return;
      }

      if(this.client) {
        this.createForm();
        return;
      }

      if(this.tos && this.tos.length > 0) {

        var self = this;

        var form = this.form;

        this.tos.forEach(to => {

          form.to = to.to;

          form.client = null;

          if(to.client) {
            form.client = to.client;
          }

          self.createFormTo(form);

        });

        this.resetAddTo();
        this.resetForm();
        this.tos = [];
      }
    },

    removeTo(to) {
      
      var exists = _.find(this.tos, {'to': to});

      if(!exists) {
        return;
      }

      var results = [];

      this.tos.forEach(r => {

        if(r.to == to) {
          return;
        }

        results.push(r);

      });

      this.tos = results;
    },

    isAddToDisabled() {
      if(this.processing) {
        return true;
      }

      if(!this.add_to || this.add_to.length < 3) {
        return true;
      }

      if(this.isEmail(this.add_to) || this.isPhone(this.add_to)) {
        return false;
      }

      return true;
    },

    isEmail(value) {
      const reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

      return reg_email.test(value);
    },

    isPhone(value) {
      const reg_phone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

      return reg_phone.test(value);
    },

    addTo() {

      this.invalid_to = false;

      if(!this.isEmail(this.add_to) && !this.isPhone(this.add_to)) {
        this.invalid_to = true;
        return;
      }

      var exists = _.find(this.tos, {'to': this.add_to});

      if(exists) {
        this.invalid_to = true;
        return;
      }

      this.tos.unshift({'to': this.add_to});

      this.resetAddTo();
    },

    setAddToClass() {
      if(this.invalid_to) {
        return 'is-invalid';
      }
    },

    resetAddTo() {
      this.add_to = null;
      this.invalid_to = false;
      this.searching = null;
      this.search_results = [];
    },

    addClient(uuid) {
      this.getContacts(uuid);

      this.resetAddTo();
    },

    getContacts(uuid)
    {
      this.processing = true;
      this.$http.get('/contacts?fields=value&slim=true&valid=1&notify=1&optout=false&client='+uuid)
      .then(response => {
        if(response && response.status === 200) {

          this.processing = false;

          if(!response.data || response.data.length == 0) {
            this.$eventBus.$emit('show-alert', {
              text: 'No contacts found for this client',
              type: 'danger'
            });
            return;
          }

          if(response.data && response.data.length > 0) {

              var self = this;

              response.data.forEach(r => {

                var exists = _.find(self.tos, {'to': r.value});

                if(exists) {
                  return;
                }

                self.tos.unshift({'to': r.value, 'client': uuid});
              });


          }
        }
      })
    },


    toggleOptions() {
      this.show_options = !this.show_options;

      localStorage.setItem('show_options', this.show_options);
    },

    searchClients: _.debounce(function (query) {
      this.getSearch(query);
    }, 500),

    getSearch() {

      if(!this.add_to || this.add_to.length < 3) {
        return;
      }

      if(this.add_to == this.searching) {
        return;
      }

      this.searching = this.add_to;

      this.$http.get('/search?limit=3&search='+this.searching)
      .then(response => {
        if(response && response.status === 200) {

          if(response.data && response.data.length > 0) {
            this.search_results = response.data;            
          }

          this.processing = false;

          this.searching = null;
        }
      })
    },

    createFormTo(form) {

      if(!form.date) {
        form.time = null;
      }

      var url = this.base_url;

      if(!this.client) {
        url = this.base_url+'/oneoffs';
      }

      this.$http.post(url, form)
      .then(response => {
        if (response && response.data) {

          this.resetForm();

          if(response.data.uuid) {
            this.responseMessage(response.data, this.attachments);
            this.processing = false;
            return;
          }

          if(!Array.isArray(response.data)) {
            return;
          }

          var self = this;

          response.data.forEach(r => {
            self.responseMessage(r, self.attachments);
          })


          this.resetAttachments();

          this.processing = false;

        }
      })      
    },

    createForm () {

      if(!this.form.date) {
        this.form.time = null;
      }

      var url = this.base_url;

      if(!this.client) {
        url = this.base_url+'/oneoffs';
      }

      this.$http.post(url, this.form)
      .then(response => {
        if (response && response.data) {

          this.resetForm();

          if(response.data.uuid) {
            this.responseMessage(response.data, this.attachments);
            this.processing = false;
            return;
          }

          if(!Array.isArray(response.data)) {
            return;
          }

          var self = this;

          response.data.forEach(r => {
            self.responseMessage(r, self.attachments);
          })

          this.resetAttachments();

          this.processing = false;

        }
      })
    },

    responseMessage(message, files) {

      if(!message.uuid) {
        return;
      }

      var status = message['last_status'] ? message['last_status'] : 'scheduled';

      this.$eventBus.$emit('show-alert', {
        text: this.title + ' '+status
      });

      if(files && files.length > 0) {
        this.storeFiles(message.uuid, files);
      }

    },

    updateForm () {
      this.$http.patch(this.base_url + '/' + this.form.uuid, this.form)
      .then(response => {
        if (response && response.data) {
          this.resetForm();
          this.$eventBus.$emit('show-alert', {
            text: this.title + ' updated'
          });
          this.processing = false;
        }
      })
    },

    resetForm() {
      
      this.form = {
        clients: [],
        client: null,
        subject: null,
        content: "",
        signature: 0,
        test: 0,
        scheduled_at: null,
        date: null,
        time: null,
        file_url: null,
        file: null,
        language: 'en'
      };
    },

    resetAttachments() {
      this.attachments = [];

      if(!this.$refs.files) {
        return;
      }

      this.$refs.files.value = null;
      this.$refs.files.files = null;
    },

    autoTranslate(language) {
      this.postTranslate('content', language);
      this.postTranslate('subject', language);
    },

    postTranslate(field, language) {

      if(!this.form[field]) {
        return;
      }

      var translator = {
        source_language: this.form.language,
        target_language: language,
        content: this.form[field]
      }

      this.$http.post('/translator', translator)
      .then(response => {
        if(response && response.data) {

          if(response.data.target_language) {
            this.form.language = response.data.target_language;
          }

          if(response.data.content) {
            this.form[field] = response.data.content;
          }

        }
      })
    },

    
    storeFiles(uuid, files) {

        if(!files || !files.length) {
          return;
        }

        for (let i = 0; i < files.length; i++) {

            this.processing = true;
            
            let file = files.item(i);

            file.file_path = uuid+'/'+file.name;

            this.uploadFiles(file, {
                progress: progress => {
                    this.uploadProgress = Math.round(progress * 100);
                }
            }).then(response => {
                this.processing = false;
                this.storeFileUsage(uuid, file);
            })
        }
    },

    async uploadFiles(file, options = {}) {
        const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
            'bucket': 'ecdmms',
            'file_path': file.file_path,
            'content_type': options.contentType || file.type,
            'content_length': file.size,
            'expires': options.expires || '',
            'visibility': options.visibility || ''
        }, {
            baseURL: options.baseURL || null,
            headers: options.headers || {},
            ...options.options
        });
        if (response && response.data) {
            let headers = response.data.headers;
            if ('Host' in headers) {
                delete headers.Host;
            }
            if (typeof options.progress === 'undefined') {
                options.progress = () => {};
            }
            const cancelToken = options.cancelToken || ''
            await this.$axios.put(response.data.url, file, {
                cancelToken: cancelToken,
                headers: headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            })
            response.data.extension = file.name.split('.').pop();
            return response.data;
        }
        return null
    },

    storeFileUsage(uuid, file) {
      this.$http.post('/agencies/files', {
        size: file.size,
        type: 'message',
        record: uuid
      })
    },

    updateAttachments() {

      const files = this.$refs.files.files;

      if(!files || !files.length) {
        return;
      }

      this.attachments = files;
    },

    setCharacterClass(value) {

      if(!value) {
        return 'badge-secondary';
      }

      if(value.length > 480) {
        return 'badge-danger';
      }

      if(value.length > 320) {
        return 'badge-warning';
      }

      if(value.length > 10 && value.length < 320) {
        return 'badge-success';
      }

      return 'badge-secondary';
    },

    setValidateClass() {

      if(!this.merge_tags || !this.merge_tags.length) {
        return;
      }

      if(!this.form || !this.form.content) {
        return;
      }

      var tags = this.extractMergeTags(this.form.content);

      if(!tags) {
        return;
      }

      var values = this.merge_tags.map(item => item['value']);

      values = values.map(item => item.replace(/\[|\]/g, ''));

      function hasInvalidTags(tags, values) {
        return tags.some(item => !values.includes(item));
      }

      if(hasInvalidTags(tags, values)) {
        return 'is-invalid';
      }

      },

      extractMergeTags(value) {
      const regex = /\[([^\]]+)\]/g;
      const mergeTags = [];
      let match;

      while ((match = regex.exec(value)) !== null) {
        mergeTags.push(match[1].replace(/%.*?%/g, '').trim());
      }

      return mergeTags;
      },


  }
}
</script>

<template>
  <div>

    <template v-if="form.test == 1">
      <div class="alert alert-primary">
        {{ $route.meta.title }} in test mode.
      </div>
    </template>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-sm-12 col-md-8">
          <h1 id="title">{{ form.name }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">
            
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-wrench"></i> Tools
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  @click="duplicateRecord"
                >Duplicate</a>
                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
                >Restore</a>
                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>
              </div>
            </div>

          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success text-capitalize"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->


    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="type">{{ $route.meta.title }} Type</label>
                <select
                  id="type"
                  v-model="form.type"
                  class="form-control text-capitalize"
                  :disabled="processing"
                >
                  <option
                    v-for="action_type in $root.bulk_action_types"
                    :value="action_type.value"
                  >
                    {{ action_type.label }}
                  </option>
                </select>
              </div>

              <template v-if="form.type == 'Send a Message'">

                <template v-if="!processing && !languages.length">
                      <div class="alert alert-danger">Choose enabled languages in
                        <a href="/settings" target="_blank">General Settings</a>
                      </div>
                </template>


                <div class="form-group">
                  <label for="message_delay">Message Delay <small>in minutes</small></label>
                  <input
                    id="message_delay"
                    v-model="action.message_delay"
                    class="form-control"
                    type="number"
                  >
                </div>


                <div class="form-check">
                  <input
                      id="test"
                      v-model="form.test"
                      type="checkbox"
                      class="form-check-input"
                      @change="postForm()"
                      :disabled="processing"
                  >
                  <label
                      class="form-check-label"
                      for="test"
                  >Simulate Mode</label>
                  <p class="form-text text-muted small">Generate simulated messages to test this bulk action</p>
                </div>

                <div class="d-flex justify-content-between align-items-start">

                  <div class="row mb-3">
                    <div class="col-md-4 col-sm-12">

                      <merge-tags
                        :tags="'scheduled'"
                        @add-tag-to-message="addTagToMessage"
                      />

                    </div><!--Col-->

                    <div class="col-md-4 col-sm-12">

                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="autoMessagesButton"
                      :disabled="processing"
                    >
                      Auto Messages
                    </button>
                    <div class="dropdown-menu" aria-labelledby="autoMessagesButton">
                      <p class="dropdown-header">
                        Send Auto Message
                      </p>

                      <template v-for="auto_option in auto_messages">
                        <template v-if="auto_option.templates && auto_option.templates['en']">
                          <a
                            class="dropdown-item"
                            @click="changeAutoMessage(auto_option.uuid)"
                          >{{ auto_option.name }} </a>
                        </template>
                      </template>
                    </div>

                </div><!--Col-->

                    <div class="col-md-4 col-sm-12 text-end">

                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="filesButton"
                      :disabled="processing"
                    >
                      Attach Files
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filesButton" style="height:400px;overflow-y:auto">

                      <form @submit.prevent="getFiles()">

                      <div class="form-group p-2">
                        <label for="search_files">Search Files</label>
                        <input
                          id="search_files"
                          v-model="search_files"
                          class="form-control"
                          placeholder="Search Files"
                          @change="getFiles()"
                          :disabled="processing"
                        >
                      </div>

                      </form>

                      <template v-for="file in files">
                          <a
                            class="dropdown-item"
                            @click="attachFile(file.merge_tag)"
                          >{{ file.name }} </a>
                      </template>

                      <div class="dropdown-divider"></div>

                        <a
                          class="dropdown-item"
                          href="/files"
                          target="_blank"
                        ><i class="fa fa-link"></i> Upload Files</a>

                    </div>

                </div><!--Col-->

              </div><!--Row-->

                </div><!--Row-->

                <div class="form-group">
                  <label for="subject" class="form-label">Message Subject</label>
                  <input id="subject" class="form-control" type="text" v-model="action.subject">
                </div>


                <template v-for="language in languages">

                  <div
                    class="form-group mt-3"
                    v-show="showLanguage(language.key)"
                  >
                    <label
                      :for="'bulkMessageContent_'+language.key"
                      class="text-capitalize"
                    >{{ language.label }} Message</label>
                    <textarea
                      :id="'bulkMessageContent_' + language.key"
                      :ref="'bulkMessageContent_' + language.key"
                      v-model="messages[language.key]"
                      class="form-control"
                      :class="setValidateClass(messages[language.key])"
                      :rows="language.key === 'en' ? 10 : 2"
                      @focus="focusedInput = language.key"
                    />
                    <template v-if="language.key === 'en'">
                      <span class="badge badge-pill badge-primary">DEFAULT LANGUAGE</span>
                      
                      <button type="button" class="btn btn-outline-primary btn-block my-3" :class="{'active': show_languages}" @click="toggleShowLanguages()" :disabled="processing"><i class="fa fa-language"></i> 
                        <template v-if="show_languages">
                          Hide
                        </template>
                        <template v-else>
                          Show
                        </template>
                          Languages
                        </button>
                    </template>

                    <template v-if="language.key != 'en'">
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm mt-2"
                        :data-tooltip="'Auto Translate from English to '+language.label"
                        @click="autoTranslate(language)"
                        :disabled="processing"
                      ><i class="fa fa-language"></i> Auto Translate</button>
                    </template>
                  </div>
                </template>


              <div class="form-group">
                <label for="from_phone">Agency Phone</label>
                <select
                  id="from_phone"
                  class="form-control"
                  v-model="action.from_phone"
                  :disabled="processing"
                >
                  <template v-for="(agency_phone, index) in agency_phones">
                      <option
                        :value="agency_phone.phone_number"
                      >
                        <template v-if="agency_phone.name && agency_phone.name != agency_phone.phone_number">{{ agency_phone.name }}</template> {{ agency_phone.phone_number }}
                      </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="from_email">Agency Email</label>
                <select
                  id="from_email"
                  class="form-control"
                  v-model="action.from_email"
                  :disabled="processing"
                >
                  <template v-for="agency_email in agency_emails">
                      <option
                        :value="agency_email.email"
                      >
                        <template v-if="agency_email.name && agency_email.name != agency_email.email">{{ agency_email.name }}</template> {{ agency_email.email }}
                      </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                  <label for="event">Default Event</label>
                  <select
                      id="event"
                      class="form-control"
                      v-model="action.event"
                      :disabled="processing"
                  >
                    <option value="">Choose Event</option>
                    <template v-for="event in events">
                      <option
                          :value="event.uuid"
                      >
                        {{ event.date | date }} {{ event.time | time }} {{ event.description }} {{ event.type }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="post_url">Attach an Info Post</label>
                  <select
                      id="post_url"
                      class="form-control"
                      v-model="action.post_url"
                      :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="post in posts">
                      <option
                          :value="post.url"
                      >
                        {{ post.title['en'] || 'Untitled' }}
                      </option>
                    </template>
                  </select>
                </div>

                <template v-if="form.action.post_url">
                  <p><a :href="'/posts/'+form.action.post_url" class="btn btn-outline-secondary btn-sm" target="_blank"><i class="fa fa-link"></i> Edit Post</a></p>
                </template>


                <div class="btn-group my-3">
                <button type="button" class="btn btn-outline-success" @click="generatePost()" :disabled="processing">Generate Post</button>
                <button type="button" class="btn btn-outline-primary" @click="getPosts()" data-bs-toggle="tooltip" data-tooltip="Refresh Posts" :disabled="processing"><i class="fa fa-sync"></i> <span class="sr-only">Refresh Posts</span></button>
                </div>

                <div class="form-group mt-3">
                  <label for="send_form">Attach a Form</label>
                  <select
                      id="send_form"
                      class="form-control"
                      v-model="action.send_form"
                      :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="send_form in send_forms">
                      <option
                          :value="send_form.uuid"
                      >
                        {{ send_form.name }}
                      </option>
                    </template>
                  </select>
                </div>

                <template v-if="form.action.send_form">
                  <p><a :href="'/forms/'+form.action.send_form" class="btn btn-outline-secondary btn-sm" target="_blank"><i class="fa fa-link"></i> Edit Form</a></p>
                </template>

              </template>


              <template v-if="form.type == 'Assign Users'">
                <div class="form-group">
                  <label for="user">Assign to User</label>
                  <select
                    v-model="action.user"
                    for="user"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-if="users && users.length > 0">
                      <template v-for="user in users">
                        <option
                          :value="user.uuid"
                        >
                          {{ user.name }} {{ user.email }}
                        </option>
                      </template>
                    </template>
                  </select>
                </div>
                <div class="form-check">
                  <input
                    id="unassign"
                    v-model="action.unassign"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="unassign"
                  > Unassign Current Users</label>
                </div>

                <div class="form-check">
                  <input
                    id="notify_assign_to"
                    v-model="action.notify_assign_to"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="notify_assign_to"
                  > Notify Assign To</label>
                </div>
              </template>

              <template v-if="form.type == 'Transfer Clients'">

                <div class="form-group" v-if="!action.to_agency">
                  <label for="search_agencies">Search Agencies</label>
                  <input
                    id="search_agencies"
                    v-model="search_agencies"
                    class="form-control"
                    @blur="getAgencies()"
                    :disabled="processing"
                  >
                </div>

                <div class="form-group">
                  <label for="to_agency">To Agency</label>
                  <select
                    id="to_agency"
                    v-model="action.to_agency"
                    class="form-control"
                    :disabled="processing">
                    <option value=""></option>
                    <template v-for="agency in agencies">
                      <option
                        :value="agency.uuid"
                      >
                        {{ agency.name }} {{ agency.region }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-check">
                  <input
                    id="transfer_archive"
                    v-model="action.archive"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="transfer_archive"
                  >Archive After Transfer is Complete</label>
                </div>
              </template>

              <template v-if="form.type == 'Update Clients'">
              
                  <div class="form-group">
                    <label for="update_client_group">Update Group</label>
                    <select
                      id="update_client_group"
                      v-model="action.update_client_group"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value=""></option>
                      <template v-for="client_group in clientGroups">
                        <option
                          :value="client_group"
                        >
                          {{ client_group }}
                        </option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group" :hidden="!clientStatuses || clientStatuses.length === 0">
                    <label for="update_client_status">Update Status</label>
                    <select
                      id="update_client_status"
                      v-model="action.update_client_status"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value=""></option>
                      <template v-for="status in clientStatuses">
                        <option
                          :value="status"
                        >
                          {{ status }}
                        </option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="update_client_type">Update Type</label>
                    <input id="update_client_type" type="text" class="form-control" v-model="action.update_client_type">
                  </div>

                  <div class="form-group">
                    <label for="update_client_language">Update Language</label>
                    <select
                      id="update_client_language"
                      v-model="action.update_client_language"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value=""></option>
                      <template v-for="(language, index) in languages">
                        <option
                          :value="language.key"
                        >
                          {{ language.label }}
                        </option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="update_client_organization">Update Organization</label>
                    <select id="update_client_organization" class="form-control" v-model="action.update_client_organization">
                      <option value=""></option>
                      <template v-for="organization in organizations">
                        <option :value="organization.uuid">{{ organization.name }}</option>
                      </template>
                    </select>
                  </div>
              
              </template>


              <template v-if="form.type == 'Create Events'">

                <div class="form-check">
                <input
                  id="create_events_random"
                  v-model="action.create_events_random"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="create_events_random"
                > Randomly Schedule</label>
                <p class="form-text text-muted small">Randomly schedule events within business days and hours.</p>
              </div>

                <div class="form-group" :hidden="action.create_events_random == true">
                  <label for="create_events_date">Event Start Date</label>
                  <input
                    id="create_events_date"
                    v-model="action.create_events_date"
                    class="form-control"
                    type="date"
                  >
                </div>

                <div class="form-group" :hidden="action.create_events_random == true">
                  <label for="create_events_time">Event Start Time</label>
                  <input
                    id="create_events_time"
                    v-model="action.create_events_time"
                    class="form-control"
                    type="time"
                  >
                </div>

                <div class="form-group" :hidden="action.create_events_random == true">
                  <label for="create_events_end_date">Event End Date</label>
                  <input
                    id="create_events_end_date"
                    v-model="action.create_events_end_date"
                    class="form-control"
                    type="date"
                  >
                </div>

                <div class="form-group" :hidden="action.create_events_random == true">
                  <label for="create_events_end_time">Event End Time</label>
                  <input
                    id="create_events_time"
                    v-model="action.create_events_end_time"
                    class="form-control"
                    type="time"
                  >
                </div>

                <div class="form-group">
                  <label for="create_events_case_number">Event Case Number</label>
                  <input
                    id="create_events_case_number"
                    v-model="action.create_events_case_number"
                    class="form-control"
                    type="text"
                  >
                </div>

                <div class="form-group">
                  <label for="create_events_status">Event Status</label>
                  <select
                    id="create_events_status"
                    v-model="action.create_events_status"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="status in eventStatuses">
                      <option
                        :value="status"
                      >
                        {{ status }}
                      </option>
                    </template>
                  </select>
                </div>

                
                <div class="form-group">
                  <label for="create_events_type">Event Type</label>
                  <select
                    id="create_events_type"
                    v-model="action.create_events_type"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="(event_type, index) in eventTypes">
                      <option
                        :value="event_type"
                      >
                        {{ event_type }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="create_events_location">Event Location</label>
                  <select
                    id="create_events_location"
                    v-model="action.create_events_location"
                    class="form-control"
                    :disabled="processing"
                  >
                  <option value=""></option>
                  <option value="random">Randomly choose a Location</option>
                  <template v-for="(location, index) in location_types">
                    <option
                      :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="create_events_flow">Event Flow</label>
                  <select
                    id="create_events_flow"
                    v-model="action.create_events_flow"
                    class="form-control"
                    :disabled="processing"
                  >
                  <option value=""></option>
                  <option value="random">Randomly choose a Flow</option>
                  <template v-for="flow in flow_types">
                    <option
                      :value="flow.uuid"
                    >
                      {{ flow.name }}
                    </option>
                  </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="create_events_judge">Event Judge</label>
                  <select
                    id="create_events_judge"
                    v-model="action.create_events_judge"
                    class="form-control"
                    :disabled="processing"
                  >
                  <option value=""></option>
                  <option value="random">Randomly choose a Judge</option>
                  <template v-for="judge in judges">
                    <option
                      :value="judge.uuid"
                    >
                      {{ judge.name }}
                    </option>
                  </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="create_events_description">Event Description</label>
                  <input
                    id="create_events_description"
                    v-model="action.create_events_description"
                    class="form-control"
                    type="text"
                  >
                </div>

                <div class="form-group">
                  <label for="create_events_location_notes">Event Location Notes</label>
                  <input
                    id="create_events_location_notes"
                    v-model="action.create_events_location_notes"
                    class="form-control"
                    type="text"
                  >
                </div>

              </template>

              <template v-if="form.type == 'Create Payments'">

                <div class="form-group">
                  <label for="payment_amount">Payment Amount</label>
                  <div class="input-group">
                    <span
                      class="input-group-text"
                      style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                    >$</span>
                    <input
                      id="payment_amount"
                      v-model="action.payment_amount"
                      type="number"
                      class="form-control"
                      min="0"
                      step="0.01"
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label for="payment_location">Payment Location</label>
                  <select
                    id="payment_location"
                    v-model="action.payment_location"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="location in location_types">
                      <option
                        :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="payment_description">Payment Description</label>
                  <input
                    id="payment_description"
                    v-model="action.payment_description"
                    class="form-control"
                    type="text"
                  >
                </div>

                <div class="form-group">
                  <label for="payment_due_date">Payment Due Date</label>
                  <input
                    id="payment_due_date"
                    v-model="action.payment_due_date"
                    class="form-control"
                    type="date"
                  >
                </div>

                <div class="form-group">
                  <label for="payment_issue_date">Payment Issue Date</label>
                  <input
                    id="payment_issue_date"
                    v-model="action.payment_issue_date"
                    class="form-control"
                    type="date"
                  >
                </div>

                <div class="form-group">
                  <label for="payment_status">Payment Status</label>
                  <select
                    id="payment_status"
                    v-model="action.payment_status"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="status in statuses">
                      <option
                        :value="status.name"
                      >
                        {{ status.name }}
                      </option>
                    </template>
                  </select>
                </div>

              </template>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
                hidden
              >
                Save
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
                    


        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">Search Clients</p>

            <loader :processing="searching" />

            <form @submit.prevent="postForm">
              <template v-if="form.ran != 1">

                <div class="row">

                  <div class="col-sm-12 col-md-6">

                    <button
                      type="submit"
                      class="btn btn-primary mb-3"
                      :disabled="searching || processing"
                    >
                      Search
                    </button>

                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6 text-end">

                    <template v-if="form.type == 'Send a Message'">

                      <button type="button" class="btn btn-success" data-bs-target="#addContactsModal" data-bs-toggle="modal" :disabled="searching || processing">Add Contacts</button>

                    </template>

                  </div><!--Col-->

                </div><!--Row-->

              </template>


              <template v-for="(search_field, search_field_index) in search_fields">
                <div class="form-group">
                  
                  <label :for="'search_field_'+search_field_index">{{ search_field.label }}</label>
                  <input
                    :id="'search_field_'+search_field_index"
                    v-model="form.search[search_field.key]"
                    :type="search_field.type"
                    class="form-control"
                    @keyup.enter="postForm()"
                    :disabled="searching || processing"
                  >
                  <template v-if="search_field.help">
                    <small class="form-text text-muted">{{ search_field.help }}</small>
                  </template>
                </div>


                <template v-if="search_field.key == 'limit'">

                    <div class="form-group">
                      <label for="random_limit">Random Limit</label>
                      <input
                        id="random_limit"
                        v-model="form.search.random_limit"
                        type="number"
                        class="form-control"
                        :disabled="searching || processing"
                      >
                    </div>

                </template>

              </template>

              <div class="form-group">
                <label for="event_flows">Flow</label><br>
                <multi-select 
                  id="event_flows"
                  v-model="form.search.flows" 
                  :options="flows"
                  :multiple="true"
                  :close-on-select="false"
                />
              </div>

              <div class="form-group">
                <label for="event_locations">Locations</label><br>
                <multi-select 
                  id="event_locations"
                  v-model="form.search.locations" 
                  :options="locations"
                  :multiple="true"
                  :close-on-select="false"
                />
              </div>

              <div class="form-group">
                <label for="event_statuses">Event Status</label><br>
                <multi-select 
                  id="event_statuses"
                  v-model="form.search.statuses" 
                  :options="eventStatuses"
                  :multiple="true"
                  :close-on-select="false"
                />
              </div>

              <div class="form-group">
                <label for="event_types">Event Type</label><br>
                <multi-select 
                  id="event_types"
                  v-model="form.search.event_types" 
                  :options="eventTypes"
                  :multiple="true"
                  :close-on-select="false"
                />
              </div>

              <div class="form-group">
                <label for="appearance_statuses">Event Appearance</label><br>
                <multi-select 
                  id="appearance_statuses"
                  v-model="form.search.appearance_statuses" 
                  :options="eventAppearances"
                  :multiple="true"
                  :close-on-select="false"
                />
              </div>
              

              <template v-if="clientStatuses && clientStatuses.length > 0">

              <div class="form-group">
                <label for="search_client_status">Client Status</label>
                <select
                  id="search_client_status"
                  v-model="form.search.client_status"
                  class="form-control"
                  @change="postForm()"
                  :disabled="processing || searching"
                  >
                  <option value="" />
                  <template v-for="status in clientStatuses">
                    <option
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </template>
                </select>
              </div>

              </template> 

              <div class="form-group">
                <label for="users">Assigned User</label><br>
                <select
                    id="users"
                    v-model="form.search.users"
                    class="form-control"
                    @change="postForm()"
                    :disabled="processing || searching"
                >
                  <option value="" />
                  <template v-for="user in users">
                    <option
                        :value="user.uuid"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="judge_name">Assigned Judge</label><br>
                <select
                    id="judge_name"
                    v-model="form.search.judge_names"
                    class="form-control"
                    @change="postForm()"
                    :disabled="processing || searching"
                >
                  <option value="" />
                  <template v-for="judge in judges">
                    <option
                        :value="judge.name"
                    >
                      {{ judge.name }}
                    </option>
                  </template>
                </select>
              </div>

            <template v-if="clientGroups && clientGroups.length > 0">

            <p class="m-0">{{ chosen_groups.length }} of {{ clientGroups.length}} Client Groups</p>

            <template v-for="group in clientGroups">
              <span
                class="badge badge-pill mr-1 mb-3 link-hover"
                :class="setGroupClass(group)"
                @click="toggleClientGroup(group)"
              >
                {{ group }}
              </span>
            </template>

            </template>

              <template v-if="organizations && organizations.length > 0">

                <p class="m-0">{{ chosen_organizations.length }} of {{ organizations.length}} Assigned Organizations</p>

                <template v-for="organization in organizations">
              <span
                  class="badge badge-pill mr-1 mb-3 link-hover"
                  :class="setOrganizationClass(organization)"
                  @click="toggleOrganization(organization)"
              >
                {{ organization.name }}
              </span>
                </template>

                <br>
              </template>

              <div class="form-group">
                <label for="created_by">Creator</label><br>
                <select
                  id="created_by"
                  v-model="form.search.created_by"
                  class="form-control"
                  @change="postForm()"
                  :disabled="processing || searching"
                >
                  <option value="" />
                  <template v-for="creator in creators">
                    <option
                      :value="creator.created_by"
                    >
                      {{ creator.created_by }}
                    </option>
                  </template>
                </select>
              </div>

              <template v-if="form.type == 'Restore Clients'">

                <div class="form-group">
                  <label for="archived_from">Archived From Date</label>
                  <input id="archived_from" type="date" class="form-control" v-model="form.search.archived_from" :disabled="processing">
                </div>

                <div class="form-group">
                  <label for="archived_to">Archived To Date</label>
                  <input id="archived_to" type="date" class="form-control" v-model="form.search.archived_to" :disabled="processing">
                </div>

              </template>

              <div class="form-check">
                <input
                  id="is_archived"
                  v-model="form.search.archived"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="searching"
                  @change="postForm()"
                >
                <label
                  class="form-check-label"
                  for="is_archived"
                >Archived</label>
              </div>

              <div class="form-check">
                <input
                  id="is_trashed"
                  v-model="form.search.trashed"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="searching"
                  @change="postForm()"
                >
                <label
                  class="form-check-label"
                  for="is_trashed"
                >Trashed</label>
              </div>
            </form>
          </div><!--Body-->
        </div><!--Card-->


        <audit-logs
          :form="form"
          class="mt-3"
        />
      </div><!--Col-->
      <div class="col-lg-9 col-md-12">
        <div
          class="card"
          style="overflow-x:auto"
        >
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-12 col-md-3">

                <h4 id="client_count" class="card-title">
                  {{ clientCount }}
                </h4>

                <template v-if="form.status">
                  <span
                    id="bulk_action_status"
                    class="badge badge-pill text-uppercase mb-3"
                    :class="setStatusClass(form.status)"
                  >{{ form.status }}</span>
                </template>

                <template v-if="form.scheduled_at">
                  <br><span class="text-muted">{{ form.scheduled_at | datetime }}</span>
                  <span class="text-muted"> {{ form.scheduled_at | dateTimezone | timeago }}</span>
                </template>


              </div><!--Col-->
              <div class="col-sm-12 col-md-9 text-end">

                <template v-if="form && form.uuid">

                  <div class="btn-group" v-if="!form.deleted_at && !form.archived_at">
                    <template v-if="form.status == 'scheduled'">
                      <button
                        type="button"
                        class="btn btn-danger"
                        :disabled="processing"
                        @click="cancelSchedule"
                      >
                        Cancel Schedule
                      </button>
                    </template>

                    <template v-if="form.ran != 1">
                                        
                      <template v-if="form.status != 'scheduled'">
                        <button
                          type="button"
                          class="btn btn-success"
                          :disabled="processing"
                          @click="updateRunBulkAction(0)"
                        >
                          <i class="fa fa-play"></i> 

                          <template v-if="form.test == 1">
                            Simulate Run
                          </template>
                          <template v-else>
                            Live Run
                          </template>

                        </button>

                        <button
                          type="button"
                          class="btn btn-outline-success"
                          data-bs-toggle="modal"
                          data-bs-target="#scheduleModal"
                          :disabled="processing"
                        >
                          <i class="fal fa-clock"></i> Schedule Run
                        </button>

                      </template>
                    </template>


                    <button type="button" class="btn btn-outline-success" @click="saveTemplate()" :disabled="processing">
                      <template v-if="form.template == 1">
                        <i class="fa fa-star"></i> Remove Template
                      </template>
                      <template v-else>
                        <i class="fal fa-star"></i> Save Template
                      </template>
                    </button>

                    <template v-if="form.type == 'Send a Message' && form.status == 'draft'">

                    <button type="button" class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#sendTestModal" :disabled="processing"><i class="fal fa-thumbs-up"></i> Send Test</button>

                    </template>

                  </div><!--BtnGroup-->
                </template>
              </div><!--Col-->
            </div><!--Row--> 

            <div class="row my-3">
              <div class="col-12">

                <div class="btn-group">

                <template v-if="results && results.length > 0">
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        :disabled="processing"
                        @click="downloadCSV(results, 'clients')"
                      >
                        <i class="fa fa-file-csv"></i> CLIENTS CSV
                      </button>
                </template>

                <template v-if="form.ran == 1 && form.uuid && form.status == 'complete' && form.type == 'Send a Message'">
                      <a
                        :href="'/messages?type=bulk_action_'+form.uuid"
                        class="btn btn-outline-primary"
                        target="_blank"
                      ><i class="fa fa-comments"></i> Messages</a>
                </template>

                </div><!--Group-->

              </div><!--Col-->
            </div><!--Row-->

            <div class="table-responsive">
              <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>INCLUDE</th>

                  <template v-if="form.type == 'Send a Message'">
                    <th>PREVIEW</th>
                  </template>

                  <template v-for="(header, key) in headers">
                    <th
                      class="text-uppercase"
                    >
                      <template v-if="key === 'uuid'">
                        Client
                      </template>

                      <template v-else>
                        <span
                          class="text-muted link-hover"
                          @click="sortColumn(key)"
                        >{{ key }}</span>
                      </template>

                      <template v-if="key === form.search.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="searching"
                          @click="updateSortDirection()"
                        >
                          <template v-if="form.search.sortDirection === 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="form.search.sortDirection === 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

              <tbody>
                <template v-if="results && results.length > 0">
                  <template v-for="result in results">
                    <tr>
                      <td>
                        <template v-if="isExcluded(result.uuid)">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            :disabled="searching"
                            @click="toggleExclude(result.uuid)"
                          >
                            <i class="fa fa-times" />
                          </button>
                        </template>
                        <template v-else>
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            :disabled="searching"
                            @click="toggleExclude(result.uuid)"
                          >
                            <i class="fa fa-check" />
                          </button>
                        </template>
                      </td>

                      <template v-if="form.type == 'Send a Message'">
                        <td>
                          <a
                            :href="setPreviewURL(result)"
                            target="_blank"
                            class="btn btn-secondary btn-sm"
                            data-bs-toggle="tooltip"
                            data-tooltip="Preview Message"
                          ><i class="fa fa-comment" /> <span class="sr-only">Preview Message</span></a>
                        </td>
                      </template>

                      <template v-for="(field, index) in result">
                        <td>
                          <template v-if="index == 'uuid'">
                            <a
                              :href="'/clients/'+field"
                              class="btn btn-outline-secondary btn-sm"
                              target="_blank"
                            ><i class="fa fa-user" /> <span class="sr-only">Client Link</span></a>
                          </template>

                          <template v-else-if="index == 'language'">
                           {{ setLanguage(result.language) }}
                          </template>

                          <template v-else-if="isDateField(index)">
                            {{ field | datetime }}
                          </template>

                          <template v-else-if="index == 'users'">
                            <template v-if="field && field.length > 0">
                              <template v-for="(client_user, client_key) in field">
                                <a
                                  :href="'/clients?users='+client_user.uuid"
                                  target="_blank"
                                ><span class="badge badge-pill badge-primary">{{ client_user.name }} {{ client_user.email }}</span></a>
                              </template>
                            </template>

                            <template v-if="!field || !field.length">
                              <span class="badge badge-pill badge-warning">No Assigned Users</span>
                            </template>
                          </template>

                          <template v-else-if="client_fields.includes(index)">
                            {{ field }}
                          </template>
                        </td>
                      </template>
                    </tr>
                  </template>
                </template><!--Results-->
              </tbody>
            </table>
            </div><!--Responsive-->
          </div><!--Body-->
        </div><!--Card-->
      </div>
    </div><!--Row-->

      <div
        id="scheduleModal"
        ref="scheduleModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="scheduleModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="scheduleModalTitle"
                class="modal-title m-0"
              >
                Schedule {{ $route.meta.title }}
              </h5>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <form @submit.prevent="scheduleBulkAction()">

            <div class="modal-body">
              <p class="card-title m-0">
                Schedule {{ $route.meta.title}} to run at a date and time in the future.
              </p>


              <div class="form-group">
                <label for="scheduled_date">Scheduled Date</label>
                <input
                  id="scheduled_date"
                  v-model="scheduled.date"
                  type="date"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="scheduled_time">Scheduled Time</label>
                <input
                  id="scheduled_time"
                  v-model="scheduled.time"
                  type="time"
                  class="form-control"
                  required
                >
              </div>

              <template v-if="form.type != 'Assign Users'">
                <div class="form-check">
                  <input
                    id="test"
                    v-model="scheduled.test"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="test"
                  > Test Run?</label>
                </div>
              </template>
            </div><!--Body-->

            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Schedule Run
              </button>
            </div><!--Footer-->

             </form>
          </div>
        </div><!--Modal-->
      </div>


    <div
        id="sendTestModal"
        ref="sendTestModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="sendTestModalTitle"
        aria-hidden="true"
    >
      <div
          class="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
                id="sendTestModalTitle"
                class="modal-title m-0"
            >
             Test {{ $route.meta.title }}
            </h5>

            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            />
          </div>

            <div class="modal-body">
              <p class="card-title m-0">
                Send a copy of the bulk action to yourself and other users.
              </p>

              <form @submit.prevent="postTest()">

              <div class="form-group">
                <label for="test_phone">Test Phone</label>
                <input id="test_phone" type="tel" class="form-control" v-model="testForm.phone">
              </div>

              <div class="form-group">
                <label for="test_email">Test Email</label>
                <input id="test_email" type="email" class="form-control" v-model="testForm.email">
              </div>

                <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                >
                  Send Test
                </button>

              </form>

            </div><!--Body-->

        </div>
      </div><!--Modal-->
    </div>


    <template v-if="form.type == 'Send a Message'">

      <div
        id="addContactsModal"
        ref="addContactsModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addContactsModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="scheduleModalTitle"
                class="modal-title m-0"
              >
                Add Contacts
              </h5>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div class="modal-body">

              <p>Add any phone number or email address to this {{ $route.meta.title }}.</p>

              <div class="form-group">
                <label for="contacts">Contacts</label>
                <textarea id="contacts" class="form-control" rows="3" size="10" placeholder="jane@court.gov, 444-222-5555" v-model="form.contacts"></textarea>
                <small class="text-muted">Comma-separated list of phones and emails.</small>
              </div>
              
            </div><!--Body-->

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                :disabled="processing"
                @click="postForm()"
              >
              Save
              </button>
            </div><!--Footer-->
          </div>
        </div><!--Modal-->
      </div>

    </template>


  </div>
</template>

<script>
import _ from 'lodash';
import confetti from "canvas-confetti";
import queries from '../mixins/queries';
import jsonParse from '../mixins/jsonParse';

export default {
    
    mixins: [ queries, jsonParse ],

    data () {
        return {
        processing: false,
        searching: false,
        form: {
            search: {}
        },
        action: {},
        scheduled: {
            date: '',
            time: '',
            test: 0
        },
        testForm: {
          phone: null,
          email: null
        },
        client_fields: ['uuid', 'first_name', 'middle_name', 'last_name', 'client_reference', 'group', 'type', 'status', 'language', 'created_by', 'created_at', 'updated_at', 'archived_at', 'deleted_at'],
        search_fields: [
        {label: 'Search', key: 'search', type: 'search', hidden: false},
        {label: 'Client Limit', key: 'limit', type: 'number', help: 'The max number of clients to search for', hidden: false},
        {label: 'Case Number', key: 'case_number', type: 'text', hidden: false},
        {label: 'Event From Date', key: 'events_from_date', type: 'date', hidden: false},
        {label: 'Event To Date', key: 'events_to_date', type: 'date', hidden: false},
        {label: 'Event From Time', key: 'events_from_time', type: 'time', hidden: false},
        {label: 'Event To Time', key: 'events_to_time', type: 'time', hidden: false},
        ],
        bulk_action: {},
        auto_messages: [],
        files: [],
        search_files: '',
        results: [],
        headers: [],
        users: [],
        user: {},
        filtered_url: '',
        auto_message: {},
        messages: {
            'en': ''
        },
        focusedInput: 'en',
        total: 0,
        flows: [],
        flow_types: [],
        locations: [],
        location_types: [],
        statuses: [],
        bulkActionForm: {},
        eventTypes: [],
        eventAppearances: [],
        judges: [],
        groups: [],
        chosen_groups: [],
        chosen_organizations: [],
        creators: [],
        sorts: [],
        errors: [],
        excludes: [],
        agency_phones: [],
        agency_emails: [],
        languages: [],
        show_languages: false,
        events: [],
        organizations: [],
        posts: [],
        portal: {},
        merge_tags: [],
        agencies: [],
        search_agencies: '',
        send_forms: []
        }
    },

    computed: {

        clientCount: function() {

            if(this.form.ran === 1) {
                return this.form.clients + ' Clients';
            }

            if(this.total > this.form.search.limit && this.form.search.limit) {
                return this.form.search.limit + ' of ' + this.total.toLocaleString() + ' Clients';
            }
            if(this.total >= 0) {
                return this.total.toLocaleString() + ' Clients';
            }
            
            return 'No Clients';
        },

        clientGroups: function() {

          if(!this.$root.current_user || !this.$root.current_user.current_agency) {
            return;
          }

          var groups = [];

          if(this.$root.current_user.current_agency.settings && this.$root.current_user.current_agency.settings.default_groups) {
            groups = this.$root.current_user.current_agency.settings.default_groups;

            groups = groups.split(",");

            groups = groups.map(g => g.trim());
          }

          var client_groups = [];

          if(this.groups && this.groups.length > 0) {
            client_groups = this.groups.map(r => r.group);

            client_groups.forEach(g => {
              if(!groups.includes(g)) {
                groups.push(g);
              }
            })
          }

          if(!groups) {
            return [];
          }

          return groups.map(g => g.trim());
        },

        clientStatuses: function() {
          if(!this.statuses || !this.statuses.length) {
            return [];
          }

          return this.statuses.filter(s => s.type == 'client').map(s => s.name);
        },

        eventStatuses: function() {
          if(!this.statuses || !this.statuses.length) {
            return [];
          }

          return this.statuses.filter(s => s.type == 'event').map(s => s.name);
        },

    },

    mounted() {

      var self = this;
      
      this.$eventBus.$on('errors', function (errors) {

        if(!self.errors || !self.errors.length) {
          self.errors = [];
        }

          if(errors.data && errors.data.message) {
            self.errors.unshift(errors.data.message);
          }
          
          self.processing = false;
      });

      this.$eventBus.$on(this.$route.meta.base_url, function (data) {
        self.updateRecord(data);
      });
      
    },

    created () {
        this.resetForm();
        this.getLanguages();
        this.getMergeTags();
        this.getRecord();
        this.getEvents();
        this.getOrganizations();
    },

    methods: {

        resetForm() {

            this.form = {
                ran: 0,
                test: 0,
                contacts: null,
                messages: {
                    send_date: null,
                    send_time: null,
                    'en': ''
                },
                search: {
                    sort: "last_name",
                    sortDirection: "desc",
                    case_number: "",
                    events_from_date: "",
                    events_to_date: "",
                    events_from_time: "",
                    events_to_time: "",
                    flows: "",
                    event_statuses: "",
                    appearance_statuses: "",
                    judge_names: "",
                    archived: false,
                    trashed: false,
                    status: null,
                    groups: null,
                    users: null,
                    slim: true,
                    total: true,
                    limit: ''
                },
            }

            this.action = {
                user: null,
                unassign: false,
                notify_assign_to: false,
                messages: [],
                subject: null,
                from_phone: null,
                from_email: null,
                event: null
            }
        },

        updateRecord(data) {
          if(!this.form.uuid || !data.uuid || !data.updated_at) {
            return;
          }

          if(data.uuid != this.form.uuid) {
            return;
          }

          this.form = {...this.form, ...data};

          this.setTitle();

          this.shouldCelebrate();
        },

        setStatusClass(status) {
          
            if(status == 'complete') {
                return 'badge-success';
            }

            if(status == 'draft') {
                return 'badge-primary';
            }

            if(['unavailable action type', 'canceled'].includes(status)) {
                return 'badge-danger';
            }

            if(status == 'scheduled') {
                return 'badge-warning';
            }

            return 'badge-primary';
        },

        isDateField(field) {
          var fields = ['created_at', 'archived_at', 'deleted_at', 'scheduled_at', 'updated_at'];
          return fields.includes(field);
        },

        isExcluded(uuid) {
            return this.excludes.includes(uuid);
        },

        toggleExclude(uuid) {
            if(this.excludes.includes(uuid)) {
                this.excludes.splice(this.excludes.findIndex((obj => obj === uuid)), 1);
            } else {
                this.excludes.push(uuid);
            }
        },

        setPreviewURL(result) {
          if(this.form.action && this.form.action.messages) {

            var language = result.language || 'en';

            var content = this.messages[language] || this.messages['en'];

            if(!content) {
            content = this.form.action.messages[language] || this.form.action.messages['en'];              
            }

            var queries = {
              content: content,
              client: result.uuid,
              event: this.form.action.event || null,
            }

            return this.buildQueries('/preview', queries);
          }
          return '/preview';
        },

        searchClients() {

          this.resetSearch();

          this.searching = true;

          if(this.form.type == 'Assign Users') {
            this.form.search.slim = false;
          } else {
            this.form.search.slim = true;
          }

          this.form.search.total = true;
          
          this.form.search.fields = this.client_fields;

          this.$http.get(this.buildQueries('/clients', this.form.search))
          .then(response => {
            if(response && response.data) {

            this.total = response.data.total || 0;
            this.results = response.data.data || [];

            if(this.results.length >= 1) {
                this.headers = this.results[0];              
            }

            this.searching = false;

            }
          })
        },

        addTagToMessage (value) {

            let message = '';

            message = this.messages[this.focusedInput] || '';
            let selectionIndex = this.$refs['bulkMessageContent_' + this.focusedInput][0].selectionStart;
            let newValue = message.slice(0, selectionIndex) + value + message.slice(selectionIndex, message.length);
            this.messages[this.focusedInput] = newValue;

        },

        getLanguages() {
          this.$http.get('/languages')
          .then(response => {
            if(response && response.data) {

              if(!response.data.length) {
                return;
              }

              this.languages = response.data;

              var self = this;

              this.languages.forEach(function(language) {

                if(!self.messages[language.key]) {
                  self.messages[language.key] = '';                  
                }
                
              });
            }
          })
        },

        cancelSchedule() {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/cancel')
            .then(response => {
                if(response && response.status === 200) {
                    this.$eventBus.$emit('show-alert', {
                      text: this.$route.meta.title + ' canceled'
                    });
                    this.form = {...this.form, ...response.data};
                    this.processing = false;
                }
            })
        },

        scheduleBulkAction () {

            this.errors = [];
        
            this.processing = true;

            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/schedule', this.scheduled)
            .then(response => {
                if(response && response.data) {
                    this.form = {...this.form, ...response.data};
                    this.$eventBus.$emit('show-alert', {
                      text: this.$route.meta.title + ' scheduled'
                    });
                    this.processing = false;
                }
            })
        },

        updateRunBulkAction (test) {

            this.form.test = test;

            this.processing = true;

            this.form.action = this.action;

            this.form.action.messages = this.messages;

            this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {
                    this.runBulkAction();
                }
                    
            })
        },

        runBulkAction() {

            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/run')
            .then(response => {
                if(response && response.data) {

                    if(response.data.status) {
                        this.form.ran = 1;
                        this.form.status = response.data.status;
                        this.$eventBus.$emit('show-alert', {
                          text: 'Running '+this.$route.meta.title
                        }); 
                    }

                    if(response.data.updated_at) {
                        this.form.updated_at = response.data.updated_at;
                    }

                    this.processing = false;
                }
                
            })

        },

        setFormFields() {
            this.form.action = this.action;

            this.form.action.messages = this.messages;

            this.form.exclude = this.excludes;

            this.setGroups();
            this.setOrganizations();
        },

        postForm () {

            this.processing = true;

            this.setFormFields();

            this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {

                    this.$eventBus.$emit('show-alert', {
                      text: this.$route.meta.title + ' saved'
                    });
                    this.form = response.data;

                    this.setFields();

                    this.searchClients();

                    this.processing = false;
                }
                
            })
        },

        getForms() {

          if(!this.form.type || this.form.type != 'Send a Message') {
            return;
          }

            this.$http.get('/forms?limit=1000&fields=name,uuid&status=public')
            .then(response => {
                if(response && response.data) {
                    this.send_forms = response.data;
                }
            })
        },

        getRecord () {
            this.errors = [];
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
            .then(response => {
                if (response && response.data) {
                    
                    this.form = response.data;

                    this.processing = false;

                    this.setFields();

                    this.setGroups();
                    this.setOrganizations();

                    this.searchClients();

                    this.getOptions();

                    this.getPosts();

                    this.getAgencies();

                    this.getForms();

                }
                
            })
        },

        getAgencies() {
          if(!this.form.type || this.form.type != 'Transfer Clients') {
            return;
          }

          var queries = {
            limit: 25,
            fields: 'name,uuid',
            sort: 'name',
            sortDirection: 'asc',
            search: this.search_agencies
          }

          this.processing = true;

          this.$http.get(this.buildQueries('/agencies', queries))
          .then(response => {
            if(response && response.data) {
              this.agencies = response.data;
              this.processing = false;
            }
          })
        },

        setFields () {

            if (this.form.action) {
                this.auto_message = this.auto_messages[0];
                this.action = this.form.action;

                if(this.form.action.messages) {
                    this.messages = this.parseJSON(this.form.action.messages);
                }
            }

            this.excludes = this.form.exclude || [];

            if(this.form.name) {
              this.setTitle();
            }

            if(this.form.search) {

              if(this.form.search.groups) {
                this.chosen_groups = this.form.search.groups.split(',');
              }

              if(this.form.search.organizations) {
                this.chosen_organizations = this.form.search.organizations.split(',');
              }
            }

        },

        setTitle() {
            document.title = this.form.name +' | eCourtDate.com';
        },

        downloadCSV(records, file_name) {
            var csvFile, data, link;
            data = this.convertCSV(records);
            csvFile = new Blob([data], {type: "text/csv"});
            let fileName = this.form.name+'-'+file_name+'.csv';
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(csvFile, fileName)
            } else {
                link = document.createElement("a");
                link.download = fileName;
                link.href = window.URL.createObjectURL(csvFile);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
            }
        },

        convertCSV(records) {

            var results = [];

            var self = this;

            let keys = Object.keys(records[0]);

            keys.unshift('include');

            results += keys.join(',');

            results += '\n';

            records.forEach(function(item) {
              var record = '';

              var included = 'Y';

              if(self.excludes.includes(item['uuid'])) {
                included = 'N';
              }

              record += included+',';

              Object.keys(item).forEach(function(key) {
                record += '"' + (item[key] ? item[key] : '') + '",';
              });
              results += record;
              results += '\n';
            });

            return results;
        },

        changeAutoMessage (uuid) {
            this.auto_message = _.find(this.auto_messages, {'uuid': uuid});
            this.messages = this.parseJSON(this.auto_message.templates);
            if(this.auto_message.subject) {
              this.action.subject = this.auto_message.subject;
            }
        },

        attachFile (merge_tag) {
          for (const property in this.messages) {

            if(this.messages[property] && this.messages[property].includes(merge_tag)) {
              continue;
            }

            this.messages[property] += ' {'+merge_tag+'}';
          }
        },

        duplicateRecord () {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url + '/duplicate/' + this.form.uuid + '')
            .then(response => {
                if (response && response.data && response.data.uuid) {
                    window.open('/'+this.$route.meta.base_url+'/'+response.data.uuid, '_blank');
                    this.$eventBus.$emit('show-alert', {  
                      text: this.$route.meta.title + ' duplicated'
                    });
                }
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' not found'
                });
                this.processing = false;
            })
        },

        getAutoMessages () {
            this.$http.get('/auto_messages?limit=1000&fields=name,uuid,subject,templates&slim=false&sort=name&sortDirection=asc')
            .then(response => {
                if(response && response.data) {
                    this.auto_messages = response.data;
                }
            })
        },

        getFiles() {
          
          let url = '/files?limit=1000&fields=name,uuid,merge_tag&slim=true&sort=name&sortDirection=asc';

          if(this.search_files) {
            url += '&search='+this.search_files;
          }

          this.$http.get(url)
          .then(response => {
            if(response && response.data) {
              this.files = response.data;
            }
          })
        },

    getOptions() {
        this.getAutoMessages();
        this.getFiles();
        this.getUsers();
        this.getStatuses();
        this.getFlows();
        this.getLocations();
        this.getPortal();
        this.getEventTypes();
        this.getEventAppearances();
        this.getJudges();
        this.getGroups();
        this.getCreators();
        this.getAgencyPhones();
        this.getAgencyEmails();
        this.getUser();
    },

    getUsers () {
        this.$http.get('/users?limit=1000&slim=true&fields=email,uuid,name')
        .then(response => {
            if (response && response.data) {
                this.users = response.data;
            }
        })
    },

      getUser() {
        this.$http.get('/users/current')
            .then(response => {
              if(response && response.data) {
                this.user = response.data;

                if(this.user.phone) {
                  this.testForm.phone = this.user.phone;
                }

                if(this.user.email) {
                  this.testForm.email = this.user.email;
                }

              }
            })
      },

    getStatuses () {
      this.$http.get('/statuses?limit=1000&fields=name,type')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;
        }
      });
    },
    
    getFlows () {
      this.$http.get('flows?limit=1000&slim=true&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          let flows = response.data;
          this.flow_types = response.data;
          this.flows = flows.map((flow) => flow.name);
        }
      })
    },
    getLocations () {
      this.$http.get('locations?limit=1000&slim=true&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          let locations = response.data;
          this.location_types = response.data;
          this.locations = locations.map((location) => location.name);
        }
      })
    },

    getEventTypes () {
      this.$http.get('/event_types')
      .then(response => {
        if(response && response.data) {
          let types = response.data;
          this.eventTypes = types.map((type) => type.type);
        }
      })
    },

    getEventAppearances () {
      this.$http.get('/event_appearances')
      .then(response => {
        if(response && response.data) {
          this.eventAppearances = response.data.map((e) => e.appeared);
        }
      })
    },

    getJudges () {
      this.$http.get('/judges?limit=1000&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.judges = response.data;
        }
      })
    },

    getGroups () {
      this.$http.get('/client_groups?limit=1000&sort=group&sortDirection=name')
      .then(response => {
        if(response && response.data) {
            this.groups = response.data;
        }
      })
    },

    getCreators () {
      this.$http.get('/client_createds')
      .then(response => {
        if(response && response.data) {
          this.creators = response.data;
        }
      })
    },

    setGroupClass(group) {

      if(this.chosen_groups && this.chosen_groups.includes(group)) {
        return 'badge-primary';
      }

      return 'badge-secondary';
    },

      setOrganizationClass(organization) {

        if(this.chosen_organizations && this.chosen_organizations.includes(organization.uuid)) {
          return 'badge-primary';
        }

        return 'badge-secondary';
      },

    toggleClientGroup(group) {

      if(this.chosen_groups && !this.chosen_groups.includes(group)) {
        this.chosen_groups.push(group);

        this.postForm();
        return;
      }

      this.chosen_groups.splice(this.chosen_groups.findIndex((obj => obj === group)), 1);

      this.postForm();
    },

      toggleOrganization(organization) {

        if(this.chosen_organizations && !this.chosen_organizations.includes(organization.uuid)) {
          this.chosen_organizations.push(organization.uuid);

          this.postForm();
          return;
        }

        this.chosen_organizations.splice(this.chosen_organizations.findIndex((obj => obj === organization.uuid)), 1);

        this.postForm();
      },

    resetSearch() {
        this.results = [];
        this.total = 0;

        this.setGroups();
        this.setOrganizations();
    },

    setGroups() {
        if(this.chosen_groups) {
          this.form.search.groups = this.chosen_groups.join(",");
        }

        if(!this.chosen_groups) {
          this.form.search.groups = null;
        }
    },

      setOrganizations() {
        if(this.chosen_organizations) {
          this.form.search.organizations = this.chosen_organizations.join(",");
        }

        if(!this.chosen_organizations) {
          this.form.search.organizations = null;
        }

      },

    updateSortDirection() {

      if(this.form.search.sortDirection == 'asc') {
        this.form.search.sortDirection = 'desc';
      } else {
        this.form.search.sortDirection = 'asc';
      }

      this.searchClients();
    },

    sortColumn(key) {

      this.form.search.sort = key;

      this.updateSortDirection();
    },

    getAgencyPhones() {

      if(this.form.type !== 'Send a Message') {
        return;
      }

      this.$http.get('/agency_phones?slim=true&limit=100&fields=name,phone_number&sort=phone_number&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.agency_phones = response.data;
        }
      })
    },

    getAgencyEmails() {

      if(this.form.type !== 'Send a Message') {
        return;
      }

      this.$http.get('/agency_emails?slim=true&limit=100&fields=name,email&sort=email&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.agency_emails = response.data;
        }
      })
    },

      getOrganizations() {
        this.$http.get('/organizations?slim=true&limit=100&fields=name,uuid&sort=name&sortDirection=asc')
            .then(response => {
              if(response && response.data) {
                this.organizations = response.data;
              }
            })
      },

    saveTemplate() {

      if(this.form.template == 1) {
        this.form.template = 0;
      } else {
        this.form.template = 1;        
      }

      this.postForm();
    },

      setLanguage(value) {

        if(!value) {
          return;
        }

        var result = _.find(this.languages, {'key': value});

        if(!result) {
          return;
        }

        return result.label;

      },

      generatePost() {
        this.processing = true;

        var form = {
          title: {
            en: ''
          },
          summary: {
            en: ''
          },
          description: {
            en: ''
          },
          language: 'en',
          status: 'public',
          social_share: true
        }

        var self = this;

        this.languages.forEach(function(language) {
          if(self.messages[language.key]) {
            form.title[language.key] = self.action.subject;
            form.summary[language.key] = self.messages[language.key];
            form.description[language.key] = self.messages[language.key];
          }
        })

        if(this.portal && this.portal.uuid) {
          form.portal = this.portal.uuid;
        }

        this.$http.post('/posts', form)
        .then(response => {
          if(response && response.data) {
            this.processing = false;
            
            if(!this.posts) {
              this.posts = [];
            }

            this.posts.unshift(response.data);

            this.form.action.post_url = response.data.url;

            this.$eventBus.$emit('show-alert', {
              text: 'Post created'
            });
          }
        })
      },

      getPortal() {
        this.$http.get('/portals?limit=1&sort=default&sortDirection=desc&slim=true&fields=uuid')
        .then(response => {
          if(response && response.data && response.data[0]) {
            this.portal = response.data[0];
          }
        })
      },

      getPosts() {

        if(!this.form.type || !this.form.type == 'Send a Message') {
          return;
        }

        this.posts = [];

        this.$http.get('/posts?limit=10&sort=created_at&sortDirection=desc&fields=uuid,title,created_at')
            .then(response => {
              if(response && response.data) {
                this.posts = response.data;
              }
            })
      },


      getEvents() {
        this.$http.get('/events?slim=true&limit=100&sort=created_at&sortDirection=desc&fields=uuid,date,time,description,type')
            .then(response => {
              if(response && response.data) {
                this.events = response.data;
              }
            })
      },

      shouldCelebrate() {

        if(this.form.status != 'complete') {
          return;
        }

        confetti({
          particleCount: 150
        });

        setTimeout(() => {
          confetti.reset();
        }, 10000);

      },

      autoTranslate(language) {

        this.processing = true;

        this.errors = [];

        var translator = {
          source_language: 'en',
          target_language: language.key,
          content: this.messages['en']
        }

        this.$http.post('/translator', translator)
            .then(response => {
              if(response && response.data) {

                if(response.data.content) {
                  this.messages[language.key] = response.data.content;
                  this.$eventBus.$emit('show-alert', {
                    text: 'Added translation to '+language.label
                  });
                }

                this.processing = false;
              }
            })
      },

      postTest() {

          if(!this.messages['en']) {
            this.$eventBus.$emit('show-alert', {
              text: 'Please enter a message'
            });
            return;
          }

          this.processing = true;

          this.errors = [];

          var form = {
            content: this.messages['en'],
            subject: this.action.subject,
          }

          if(this.results && this.results[0]) {
            form.client = this.results[0].uuid;
          }

          if(this.form.action && this.form.action.event) {
            form.event = this.form.action.event;
          }

          if(this.form.action && this.form.action.post) {
            form.post = this.form.action.post;
          }

          if(this.testForm.phone) {
            form.to = this.testForm.phone;
            this.$http.post('/messages/oneoffs', form)
            this.$eventBus.$emit('show-alert', {
              text: 'Test SMS scheduled'
            });
          }

          if(this.testForm.email) {
            form.to = this.testForm.email;
            this.$http.post('/messages/oneoffs', form)
            this.$eventBus.$emit('show-alert', {
              text: 'Test email scheduled'
            });
          }

          this.processing = false;
      },

      showLanguage(key) {

        if(key == 'en') {
          return true;
        }

        if(this.show_languages) {
          return true;
        }

        return false;
      },

      toggleShowLanguages() {
        if(this.show_languages) {
          this.show_languages = false;
          return;
        }

        this.show_languages = true;
      },

      setValidateClass(value) {

      if(!value) {
        return 'is-invalid';
      }

      if(!this.merge_tags) {
        return;
      }

      var tags = this.extractMergeTags(value);

      if(!tags) {
        return;
      }

      var values = this.merge_tags.map(item => item['value']);

      values = values.map(item => item.replace(/\[|\]/g, ''));

      function hasInvalidTags(tags, values) {
        return tags.some(item => !values.includes(item));
      }

      if(hasInvalidTags(tags, values)) {
        return 'is-invalid';
      }

      },

      extractMergeTags(value) {
      const mergeTagRegex = /\[([^\]]+)\]/g;
      const mergeTags = [];
      let match;

      while ((match = mergeTagRegex.exec(value)) !== null) {
        mergeTags.push(match[1].replace(/%.*?%/g, '').trim());
      }

      return mergeTags;
      },

    },
}
</script>
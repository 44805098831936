<template>
  <div>

    <page-tabs page="uploads" />
    
    <div class="row mb-3">
      <div class="col-sm-12 col-md-3">
        <div class="card mb-3">
          <div class="card-body">

                <p class="card-title">
                  Add {{ $route.meta.title }}
                </p>

                <template v-if="!processing && upload_templates && upload_templates.length === 0">
                  <div class="alert alert-warning">
                   <i class="fa fa-question-circle"></i> No upload templates found. To upload a file, create an upload template first.
                  </div>
                </template>


          <template v-if="upload_templates && upload_templates.length > 0">
            <form @submit.prevent="storeFile">
              <div class="form-group">
                <div class="custom-file">
                  <input
                    id="fileUpload"
                    ref="file"
                    type="file"
                    class="custom-file-input"
                    aria-describedby="fileUpload"
                    @change="setLabel"
                  >
                  <label
                    class="custom-file-label"
                    for="fileUpload"
                  >{{ fileName || 'Choose File' }}</label>
                </div>
              </div>

              <div class="form-group">
                <label for="uploadTemplate">Upload Template</label>
                <select
                  id="uploadTemplate"
                  v-model="template"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option
                    value=""
                    disabled
                  >
                    Choose Upload Template
                  </option>
                  <template v-for="template_option in upload_templates">
                    <option
                      :value="template_option.uuid"
                    >
                      {{ template_option.name }}
                    </option>
                  </template>
                </select>
              </div>

                <div class="form-check">
                  <input
                    id="skip_processing"
                    v-model="skip_processing"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="skip_processing"
                  > Preview Upload <small>(Skip Processing)</small></label>
                </div>

              <template v-if="!errors.length">
                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Add
                </button>
              </template>

              <template v-if="processing && uploadProgress > 0">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="'width:'+uploadProgress+'%'"
                  >
                    {{ uploadProgress }}%
                  </div>
                </div>
              </template>
            </form>
          </template>

          </div><!--Body-->
        </div><!--Card-->


        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Search
                </p>
              </div><!--Col-->

              <div class="col-6 text-end">
                <loader :is-loading="processing" />
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search_uploads">Search {{ $route.meta.title }}s</label>
                <input
                  id="search_uploads"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                >
              </div>

              <div class="form-check">
                <input
                  id="search_file_data"
                  v-model="queries.file_data"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="search_file_data"
                >Search File Data</label>
              </div>

              <template v-if="queries.file_data">
                <div class="form-group">
                  <label for="search_operator">Search Operator</label>
                  <select
                    id="search_operator"
                    v-model="queries.search_operator"
                    class="form-control"
                  >
                    <template v-for="search_operator in search_operators">
                      <option
                        :value="search_operator"
                      >
                        {{ search_operator }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="search_column">Search Column</label>
                  <select
                    id="search_column"
                    v-model="queries.search_column"
                    class="form-control"
                  >
                    <option value="">
                      Any Column
                    </option>
                    <template v-for="search_column in default_fields">
                      <option
                        :value="search_column.key"
                      >
                        {{ search_column.name }} <small>{{ search_column.key }}</small>
                      </option>
                    </template>
                  </select>
                </div>
              </template>

              <div class="form-group">
                <label for="search_created_from">Created From Date</label>
                <input
                  id="search_created_from"
                  v-model="queries.created_from"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_created_to">Created To Date</label>
                <input
                  id="search_created_to"
                  v-model="queries.created_to"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_status">Status</label>
                <select
                  id="search_status"
                  v-model="queries.status"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="upload_status in upload_statuses">
                    <option :value="upload_status">{{ upload_status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_template">Upload Template</label>
                <select
                  id="search_template"
                  v-model="queries.template"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="template_query in upload_templates">
                    <option
                      :value="template_query.uuid"
                    >
                      {{ template_query.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-check">
                <input
                  id="search_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="search_trashed"
                >Trashed</label>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div>
      <div class="col-sm-12 col-md-9">

        <div class="row mb-3">
          <div class="col-12">
            <p class="lead">{{ records.length }} of {{ total }} {{ $route.meta.title }}s</p>
          </div><!--Col-->
        </div><!--Row-->

        <template v-if="!processing && !records.length">
          <div class="alert alert-warning">
            No {{ $route.meta.title }}s found.
          </div>
        </template>

        <div class="row">
          <template v-for="record in records">
            <div
              class="col-lg-4 col-md-6 mb-3"
            >
              <div class="card h-100">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <template v-if="record.deleted_at">
                        <span class="badge badge-danger mr-2">TRASHED</span>
                      </template>

                      <template v-if="record.status">
                        <upload-status :upload="record" />
                      </template>
                    </div><!--Col-->

                    <div class="col-md-6 col-sm-12 text-end">
                      <div class="btn-group">
                        <button
                          type="button"
                          :disabled="processing"
                          class="btn btn-outline-primary btn-sm m-0"
                          @click="saveFile(record.file)"
                        >
                          Save File
                        </button>
                      </div>
                    </div><!--Col-->
                  </div><!--Row-->

                  <p class="card-title mt-3">
                    <a :href="setUploadURL(record)">
                      <i class="fa fa-edit"></i> Edit
                      <template v-if="record.name">
                        {{ record.name }}
                      </template>
                    </a>
                  </p>
                    

                  <p class="m-0">
                    <strong>File Rows</strong> {{ record.rows }}
                  </p>

                  <p class="m-0">
                    <strong>
                      
                      <template v-if="record.status == 'processing'">
                        Current
                      </template>
                      <template v-else>
                        Last
                      </template>
                        
                      Row</strong> {{ record.current_row }}

                      <small class="text-muted">{{ setUploadProgress(record) }}</small>
                  </p>
                    
                  <template v-if="record.template">
                    <upload-template-button :uuid="record.template" />
                  </template>

                  <template v-if="record.created_by">
                    <p class="m-0">
                      <strong>Creator</strong>  

                      <template v-if="isUUID(record.created_by)">
                        <a :href="'https://console.ecourtdate.com/sftps/'+record.created_by" target="_blank">{{ setSFTPCreator(record.created_by) }}</a>
                      </template>

                      <template v-else>
                        <a
                          :href="'/users/'+record.created_by"
                          target="_blank"
                        >{{ record.created_by }}</a>
                      </template>
                    </p>
                  </template>

                  <p class="m-0">
                    <strong>Created</strong> {{ record.created_at | datetime }}
                  </p>
                  <template v-if="record.updated_at && !record.deleted_at">
                    <p class="m-0">
                      <strong>Updated</strong> {{ record.updated_at | datetime }}
                    </p>
                  </template>
                  <template v-if="record.deleted_at">
                    <p class="m-0">
                      <strong>Trashed {{ record.deleted_at | datetime }}</strong>
                    </p>
                  </template>

                  <template v-if="record.updated_at">
                    <p class="m-0">Process Time {{ setProcessTime(record) }}</p>
                  </template>

                  <template v-if="record.rows">
                    <p class="m-0">Time per Row {{ setRowTime(record) }}</p>
                  </template>

                </div>
              </div>
            </div>
          </template>
        </div>

        <div
          v-if="records.length && total > records.length"
          class="row"
        >
          <div class="col-12 text-center">
            <button
              type="button"
              class="btn btn-outline-primary"
              :disabled="processing"
              @click="getRecords"
            >
              Load more
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

  mixins: [ queries ],

  data() {

    return {
      processing: false,
      skip_processing: false,
      search_operators: ['=', 'contains', '<', '<=', '>', '>='],
      records: [],
      queries: {},
      total: 0,
      upload_templates: [],
      default_fields: [],
      errors: {},
      template: null,
      uploadProgress: null,
      fileName: '',
      sftps: [],
      upload_statuses: ['complete', 'processing', 'incomplete', 'failed', 'skipped', 'queued', 'delayed']
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('uploads', function (data) {
      self.pushRecord(data);
    });
    
  },

  created() {

    this.resetQueries();
    this.setURLParams();
    this.getFields();
    this.getUploadTemplates();
    this.getSFTPs();
    this.getRecords();

  },

  methods: {

      setURLParams() {
          var searchParams = new URLSearchParams(window.location.search);

          var self = this;

          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });

      },

    setUploadURL(upload) {

      var queries = {};

      if(this.queries.search) {
        queries.search = this.queries.search;
        queries.search_operator = this.queries.search_operator;
        queries.search_column = this.queries.search_column;
      }

      return this.buildQueries('/'+this.$route.meta.base_url+'/'+upload.uuid, queries);
    },

    setUploadProgress(record) {
      if(!record.rows || !record.current_row) {
        return;
      }

      var result = (record.current_row / record.rows) * 100;

      if(result == 100) {
        return;
      }

      return result.toLocaleString()+'%';
    },

    setSFTPCreator(creator) {
      if(!this.sftps) {
        return;
      }

      var value = creator;

      this.sftps.forEach(function(sftp) {

        if(sftp.uuid == creator) {
          value = sftp.name;
        }
      })

      return value;
    },

    getRecords() {
      
      this.processing = true;

      this.queries.skip = this.records.length;

      this.updateURL();

      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {

          if(response.data.length > 0) {
            var self = this;
            response.data.forEach(function(data) {
              self.pushRecord(data);
            });
          }

          this.processing = false;
        }
      })
    },

    sortRecords() {

        if(!this.records) {
          return;
        }

        var sort = this.queries.sortDirection || 'desc';

        this.records = _.orderBy(this.records, ['created_at'], [sort]);
    },

    setProcessTime(record) {

      var diff = this.$moment.utc(record.updated_at).diff(this.$moment.utc(record.created_at), 'minutes');

      if(diff >= 60) {
        return (diff / 60).toLocaleString() + ' h';
      }

      return diff.toLocaleString() + ' m';
    },

    setRowTime(record) {

      if(!record.current_row) {
        return 0;
      }

      var diff = this.$moment.utc(record.updated_at).diff(this.$moment.utc(record.created_at), 'seconds');

      return (diff / record.current_row).toLocaleString() + ' s';
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    saveFile(file) {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/save/'+file)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          window.open(response.data, '_blank');
        } else {
          this.processing = false;
          this.$eventBus.$emit('show-alert', {
            text: 'File not found',
            type: 'danger'
          });
        }
      })
    },

    resetQueries() {

      this.queries = {
        search: null,
        file_data: false,
        search_operator: '=',
        search_column: '',
        created_from: null,
        created_to: null,
        template: null,
        status: null,
        trashed: false,
        limit: 10,
        skip: 0,
        sortDirection: 'desc',
        sort: 'created_at'
      }; 

    },

    getFields() {
      this.$axios.get(this.$root.assets_url+'js/fields.json')
      .then(response => {
        if(response && response.data) {
          this.default_fields = response.data;
        }
      })
    },

    pushRecord(data) {

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(!this.records) {
        this.records = [];
      }

      if(index == -1) {
        this.records.unshift(data);
        this.sortRecords();
        return;
      }

      var records = [];

      this.records.forEach(function(record) {

        if(record.uuid == data.uuid) {
          record = {...record, ...data};
        }

        records.push(record);


      })

      this.records = records;

      this.sortRecords();

    },

    searchRecords() {

      this.total = 0;
      this.records = [];

      if(!this.queries.file_data) {
        this.getRecords();
      }

      this.bulkSearch();
    },

    bulkSearch() {

      if(!this.queries.file_data) {
        return;
      }

      var queries = {
        slim: true,
        limit: 100,
        sort: this.queries.sort,
        sortDirection: 'asc',
        created_from: this.queries.created_from,
        created_to: this.queries.created_to,
        template: this.queries.template,
        status: this.queries.status
      };

      this.processing = true;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
      .then(response => {
        if(response && response.data && response.data.length > 0) {

          var self = this;

          response.data.forEach(function(upload) {
            self.searchUpload(upload);
          });

        } else {
          this.processing = false;
        }
      })
    },

    searchUpload(upload) {
      
      this.processing = true;

      var queries = {
        search: this.queries.search,
        search_operator: this.queries.search_operator || 'contains',
        search_column: this.queries.search_column || '',
        slim: true
      }

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/'+upload.uuid+'/rows', queries))
      .then(response => {
        if(response && response.data) {

          if(response.data.rows && response.data.rows.length > 0) {
            this.total++;
            this.records.unshift(upload);
          }

          this.processing = false;
          
        }
      })
    },

    getUploadTemplates() {
      this.upload_templates = [];
      this.$http.get('/upload_templates?slim=true&limit=1000&fields=name,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.upload_templates = response.data;

          if(!this.template && this.upload_templates && this.upload_templates.length === 1) {
            this.template = this.upload_templates[0].uuid;
          }
        }
      })
    },

    getSFTPs() {
      this.$http.get('/sftps?slim=true&limit=1000&fields=data,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.sftps = response.data;
        }
      })
    },

    setLabel () {
        if (this?.$refs?.file?.files?.length > 0) {
            this.fileName = this.$refs.file.files[0].name;
        } else {
          this.fileName = 'Choose File';
        }
    },

    storeFile () {
        if (this?.$refs?.file?.files?.length > 0) {
            this.processing = true;
            this.storeUpload(this.$refs.file.files[0], {
                progress: progress => {
                    this.uploadProgress = Math.round(progress * 100);
                }
            }).then(file_response => {
                if (file_response) {
                    this.$http.post('/'+this.$route.meta.base_url, {
                        file: file_response.uuid,
                        key: file_response.key,
                        bucket: file_response.bucket,
                        name: this.$refs.file.files[0].name,
                        type: this.$refs.file.files[0].type,
                        template: this.template || null,
                        skip_processing: this.skip_processing
                    }).then(response => {
                    if(response && response.data) {

                        this.$refs.file.name = null;
                        this.$refs.file.value = null;
                        this.uploadProgress = null;
                        this.setLabel();

                        this.processing = false;

                    }
                        
                    });
                }
                    
            })
        }
    },

    async storeUpload(file, options = {}) {
        const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
            'bucket': 'ecduploads',
            'content_type': options.contentType || file.type,
            'content_length': file.size,
            'expires': options.expires || '',
            'visibility': options.visibility || ''
        }, {
            baseURL: options.baseURL || null,
            headers: options.headers || {},
            ...options.options
        });
        if (response && response.data) {
            let headers = response.data.headers;

            if ('Host' in headers) {
                delete headers.Host;
            }

            if (typeof options.progress === 'undefined') {
                options.progress = () => {};
            }

            const cancelToken = options.cancelToken || ''

            await this.$axios.put(response.data.url, file, {
                cancelToken: cancelToken,
                headers: headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            }).catch(function (error) {
              if(error.response) {
                console.log(error.response.data);
              }
            });

            response.data.extension = file.name.split('.').pop();

            return response.data;
        }
        return null;
    },


  }
}
</script>
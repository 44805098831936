<template>
  <div>
    <div class="card">
      <div class="card-body">
        <p class="card-title">
          Add an Agent
        </p>
        <form
          ref="addAgent"
          @submit.prevent="addAgent()"
        >
          <div class="form-group">
            <label for="firm_name">Firm Name</label>
            <input
              id="firm_name"
              v-model="form.firm_name"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="agent_reference">Agent Reference</label>
            <input
              id="agent_reference"
              v-model="form.agent_reference"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="first_name">First Name</label>
            <input
              id="first_name"
              v-model="form.first_name"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="last_name">Last Name</label>
            <input
              id="last_name"
              v-model="form.last_name"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input 
              id="email"
              v-model="form.email" 
              type="email" 
              class="form-control"
              :class="{'is-invalid': errors.email }"
              required 
            >
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input 
              id="phone"
              v-model="form.phone"
              type="tel"
              class="form-control"
              :class="{ 'is-invalid': errors.phone }"
            >
          </div>
          <div class="form-check">
            <input
              id="notify"
              v-model="form.notify"
              type="checkbox"
              class="form-check-input"
            >
            <label
              class="form-check-label"
              for="notify"
            >Notify</label>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="processing"
          >
            Add Agent
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            processing: false,
            form: {},
            errors: {}
        }
    },
    methods: {
        addAgent () {
            this.processing = true;
            this.$http.post('agents', this.form)
            .then(response => {
                if (response && response.data) {
                    this.form = {};
                    this.$eventBus.$emit('show-alert', {
                      text: 'Agent added'
                    });
                }
                this.processing = false;
            })
        }
    }
}
</script>

<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

        
    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>{{ form.name || $route.meta.title }}</h1>
          <p>
            <!--Uploads Index-->
            <router-link :to="{name: 'uploads.index'}">Uploads</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="duplicateRecord()"
                >Duplicate</a>

                <a
                    v-if="form.deleted_at || form.archived_at"
                    class="dropdown-item"
                    @click="restoreRecord(form)"
                >Restore</a>

                <a
                    v-if="!form.deleted_at && !form.archived_at"
                    class="dropdown-item"
                    @click="archiveRecord(form)"
                >Archive</a>

                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="template_name">{{ $route.meta.title }} Name</label>
                <input
                  id="template_name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <h4>Processing Settings</h4>
              <p class="text-muted small">Use these settings to control how data is created and updated.</p>

              <div class="form-group">
                <label for="template_method">Processing Method</label>
                <select
                  id="template_method"
                  v-model="form.method"
                  class="form-control text-capitalize"
                  :disabled="processing"
                  required
                >
                <option value=""></option>
                  <option
                    v-for="method in methods"
                    :value="method.value"
                  >
                    {{ method.title }}
                  </option>
                </select>
                <template v-if="form.method">
                <p class="form-text text-small">
                  <template v-if="form.method == 'update_fields'">
                   Each upload should include only newly created or updated records since the last upload.
                  </template>
                  <template v-if="form.method == 'load_replace_all_uploads'">
                    Each upload should include all current records for all relevant data models.
                  </template>
                  <template v-if="form.method == 'load_replace_all_template'">
                    Each upload should include all current records for all relevant data models using this template.
                  </template>
                </p>
                </template>
              </div>

              <template v-if="form.method && form.method != 'update_fields'">

                <div class="form-check">
                  <input
                    id="load_replace_clients"
                    v-model="form.load_replace_clients"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="load_replace_clients"
                  >Load and Replace Clients</label>
                </div>

                <div class="form-check">
                  <input
                    id="load_replace_contacts"
                    v-model="form.load_replace_contacts"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="load_replace_contacts"
                  >Load and Replace Contacts</label>
                </div>

                <div class="form-check">
                  <input
                    id="load_replace_cases"
                    v-model="form.load_replace_cases"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="load_replace_cases"
                  >Load and Replace Cases</label>
                </div>

                </template>

              <div class="form-group">
                <label for="unique_client">Update Existing Clients By</label>
                <multi-select 
                  id="unique_client"
                  v-model="form.unique_client"
                  placeholder="Choose client fields to reference"
                  :multiple="true" 
                  :options="client_fields"
                  track-by="key"
                  label="name"
                  :close-on-select="false"
                  :class="setUniqueClientsClass()"
                />
              </div>

              <template v-if="!form.unique_client || !form.unique_client.length">
                <div class="alert alert-danger">
                  At least one field is required to create unique clients.
                </div>
              </template>

              <div class="form-group">
                <label for="unique_case">Update Existing Cases By</label>
                <multi-select 
                  id="unique_case"
                  v-model="form.unique_case"
                  placeholder="Choose case fields to reference"
                  :multiple="true" 
                  :options="case_fields"
                  track-by="name"
                  label="name"
                  :close-on-select="false"
                />
                <p class="form-text text-muted small">
                 We recommend Case Number and Case Type (if available).
                </p>
              </div>
              <div class="form-group">
                <label for="unique_event">Update Existing Events By</label>
                <multi-select 
                  id="unique_event"
                  v-model="form.unique_event"
                  placeholder="Choose event fields to reference"
                  :multiple="true" 
                  :options="event_fields"
                  track-by="name"
                  label="name"
                  :close-on-select="false"
                  :class="setUniqueEventsClass()"
                />
                <p class="form-text text-muted small">
                 We recommend at least Event Case Number, Date, and Time. If you have a unique identifier for events, use Event Reference.
                </p>
              </div>

              <div class="form-group">
                <label for="unique_payment">Update Existing Payments By</label>
                <multi-select 
                  id="unique_payment"
                  v-model="form.unique_payment"
                  placeholder="Choose payment fields to reference"
                  :multiple="true" 
                  :options="payment_fields"
                  track-by="name"
                  label="name"
                  :close-on-select="false"
                />
                <p class="form-text text-muted small">
                 By default, uses Payment Reference and Payment Amount.
                </p>
              </div>

              <h4>Parsing Formats</h4>

              <p class="text-muted small">Use these settings to parse fields based on your data formats.</p>

              <div class="row">

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="file_format">File Format</label>
                    <select
                      id="file_format"
                      v-model="form.file_format"
                      class="form-control text-uppercase"
                      :disabled="processing"
                    >
                      <option
                        v-for="file_format in file_formats"
                        :value="file_format"
                      >
                        {{ file_format }}
                      </option>
                    </select>
                    <p class="form-text text-muted small">
                      Defaults to CSV
                    </p>
                  </div>
                </div><!--Col-->


                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="date_format">Date Format</label>
                    <select
                      id="date_format"
                      v-model="form.date_format"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option
                        v-for="date_format in date_formats"
                        :value="date_format"
                      >
                        {{ date_format }}
                      </option>
                    </select>
                    <p class="form-text text-muted small">
                      Defaults to Auto
                    </p>
                  </div>
                </div><!--Col-->
                  

              </div><!--Row-->

              <div class="row">

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="time_format">Time Format</label>
                    <select
                      id="time_format"
                      v-model="form.time_format"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option
                        v-for="time_format in time_formats"
                        :value="time_format"
                      >
                        {{ time_format }}
                      </option>
                    </select>
                    <p class="form-text text-muted small">
                      Defaults to Auto
                    </p>
                  </div>
                </div><!--Col-->


                  <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="delimiter">Field Delimiter</label>
                    <select
                      id="delimiter"
                      v-model="form.delimiter"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option
                        v-for="delimiter in delimiters"
                        :value="delimiter.key"
                      >
                        {{ delimiter.title }}
                      </option>
                    </select>
                    <p class="form-text text-muted small">
                      Defaults to Comma
                    </p>
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <div class="row">

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="separator">Cell Separator</label>
                    <select
                      id="separator"
                      v-model="form.separator"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option
                        v-for="separator in delimiters"
                        :value="separator.key"
                      >
                        {{ separator.title }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->


              <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="processing_order">Processing Order</label>
                    <select
                      id="processing_order"
                      v-model="form.processing_order"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option
                        v-for="processing_order in processing_orders"
                        :value="processing_order.value"
                      >
                        {{ processing_order.title }}
                      </option>
                    </select>
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <h4>Auto-Default Settings</h4>

              <p class="text-muted small">Use these settings to auto-populate fields if a row field is missing or not available.</p>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_client_status">Default Client Status</label>
                    <input
                      id="default_client_status"
                      v-model="form.default_client_status"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_event_status">Default Event Status</label>
                    <input
                      id="default_event_status"
                      v-model="form.default_event_status"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_payment_status">Default Payment Status</label>
                    <input
                      id="default_payment_status"
                      v-model="form.default_payment_status"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_payment_amount">Default Payment Amount</label>
                    <input
                      id="default_payment_amount"
                      type="number"
                      v-model="form.default_payment_amount"
                      class="form-control"
                      step="0.01"
                    >
                  </div>
                </div><!--Col-->

              </div><!--Row-->

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_location">Default Location</label>
                    <select
                      id="default_location"
                      v-model="form.location"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <template v-if="locations && locations.length > 0">
                        <option
                          v-for="location in locations"
                          :value="location.uuid"
                        >
                          {{ location.name }}
                        </option>
                      </template>
                    </select>
                    <p class="form-text text-muted small" v-if="form.location">
                      Used if the location field is is not mapped or a location is not found for a row.
                    </p>
                    </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_portal">Default Portal</label>
                    <select
                      id="default_portal"
                      v-model="form.default_portal"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <template v-if="portals && portals.length > 0">
                        <option
                          v-for="portal in portals"
                          :value="portal.uuid"
                        >
                          {{ portal.name }}
                        </option>
                      </template>
                    </select>
                    <p class="form-text text-muted small" v-if="form.default_portal">
                      Used to override the assigned Portal for a row.
                    </p>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_flow">Default Flow</label>
                    <select
                      id="default_flow"
                      v-model="form.flow"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option value="random">Random</option>
                      <option
                        v-for="flow in flows"
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </select>
                    <template v-if="form.flow == 'random'">
                      <p class="small text-muted">Randomly assign a flow</p>
                    </template>
                    <template v-if="form.flow && form.flow != 'random'">
                      <p class="small text-muted">Used if the flow field is not mapped or a flow is not found for a row.</p>
                    </template>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="default_group">Default Group</label>
                    <select id="default_group" class="form-control"
                    v-model="form.default_group"
                    :disabled="processing">
                      <option value="" />
                      <option
                        v-for="group in groups"
                        :value="group"
                      >
                        {{ group }}
                      </option>
                    </select>
                    <p class="form-text text-muted small" v-if="form.default_group">
                      Used if the group field is not mapped or a group is not found for a row.
                    </p>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <h4>Post Processing Settings</h4>
              <p class="text-muted small">Use these settings to control what happens after processing is complete.</p>
              
                <div class="form-group">
                  <label for="notify_emails">Notify Emails</label>
                  <input
                    id="notify_emails"
                    v-model="form.notify"
                    type="text"
                    class="form-control"
                  >
                  <p class="form-text text-muted small">
                    Comma-separated list of emails to notify when processing is complete
                  </p>
                </div>

                <div class="form-check">
                    <input
                      id="notify_assigned_users"
                      v-model="form.notify_assigned_users"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="notify_assigned_users"
                    >Notify Assigned Users</label>
                    <p class="text-muted small">Send a notification to assigned users when processing is complete.</p>
                  </div>

                  <div class="form-check" hidden>
                    <input
                      id="require_assigned_users"
                      v-model="form.require_assigned_users"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="require_assigned_users"
                    >Require Assigned Users</label>
                  </div>


 
                  <div class="form-check">
                    <input
                      id="auto_create_statuses"
                      v-model="form.auto_create_statuses"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="auto_create_statuses"
                    >Auto Create Statuses</label>
                    <p class="text-muted small">Automatically create statuses if they don't exist.</p>
                  </div>

                  <div class="form-check">
                    <input
                        id="delay_sync"
                        v-model="form.delay_sync"
                        type="checkbox"
                        class="form-check-input"
                    >
                    <label
                        class="form-check-label"
                        for="delay_sync"
                    > Delay Syncing Messages</label>
                    <p class="text-muted small">Delay syncing messages until after the upload finishes processing.</p>
                  </div>

            
                  <div class="form-check" hidden>
                    <input
                      id="disable_notify_contacts"
                      v-model="form.disable_notify_contacts"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="disable_notify_contacts"
                    >Don't Notify New Contacts</label>
                  </div>
              

              <button type="submit" class="btn btn-success mt-3" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.fields">

          <div class="card my-3">
          <div class="card-body">


            <form @submit.prevent="postForm()">

              <h4>Exclude Rows</h4>
              <p class="card-text">Use these settings to skip processing rows that match the following criteria.</p>

              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="exclude_field">Exclude Field</label>
                    <select
                      id="exclude_field"
                      v-model="form.exclude_field"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <template v-for="(exclude_field, exclude_key) in form.fields">
                        <option
                          :value="exclude_key"
                        >
                          {{ exclude_field }} <small>{{ exclude_key }}</small>
                        </option>
                      </template>
                    </select>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="exclude_operator">Exclude Operator</label>
                    <select
                      id="exclude_operator"
                      v-model="form.exclude_operator"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <template v-for="operator in operators">
                        <option
                          :value="operator"
                        >
                          {{ operator }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="exclude_value">Exclude Value</label>
                    <input
                      id="exclude_value"
                      v-model="form.exclude_value"
                      class="form-control"
                    >
                    <p class="small text-muted">
                      Case insensitive
                    </p>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button type="submit" class="btn btn-success" :disabled="processing" hidden>Save</button>

            </form>

          </div>
        </div>

        </template>

        <div class="card my-3">
          <div class="card-body">

            <h4>Multi-Agency Settings</h4>

            <p class="text-muted small">Use these settings to process uploads for multiple agencies.</p>

            <div class="alert alert-info">The <code>Agency</code> field is required to process uploads for multiple agencies.</div>

            <form @submit.prevent="postForm()">


              <div class="form-group">
                <label for="fields_prefix">Agency Reference Prefix</label>
                <input
                  id="fields_prefix"
                  v-model="form.fields_prefix"
                  type="text"
                  class="form-control"
                >
              </div>


            <button type="submit" class="btn btn-success" :disabled="processing" hidden>Save</button>

            </form>

          </div><!--Body-->

        </div><!--Card-->

        <audit-logs class="mt-3" :form="form" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">
            <h4>Simulate a File</h4>

            <p class="card-text">Download a file with simulated data to test your settings.</p>

            <button type="button" class="btn btn-primary" @click="postSimulateFile" :disabled="processing">Simulate File</button>

          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h4>Upload a Sample File</h4>
                <p class="card-text">Upload a sample file to auto map fields.</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">
                <template v-if="sample_format">
                  <button type="button" class="btn btn-success my-3" @click="autoMapFile" :disabled="processing">Auto Map Sample File</button>
                </template>
              </div><!--Col-->
            </div><!--Row-->

            <template v-if="sample_format">
              <ul class="list-unstyled">
                <li>Format: <span class="text-uppercase">{{ sample_format }}</span></li>
                <li>Columns:

                  <template v-if="sample_headers && sample_headers.length">
                    <template v-for="sample_header in sample_headers">
                      <span class="badge badge-pill bg-secondary">{{ sample_header }}</span>
                    </template>
                  </template>

                </li>
              </ul>              
            </template>


              <div class="form-group">
                <div class="custom-file">
                  <label
                      class="custom-file-label"
                      for="sampleFileUpload"
                  >CSV, TXT, or XlSX</label>
                  <input
                      id="sampleFileUpload"
                      ref="sample_file"
                      type="file"
                      class="custom-file-input"
                      aria-describedby="sampleFileUpload"
                      accept=".csv,.txt,.xlsx"
                      @change="readFile"
                  >
                </div>
              </div>
              

              <ul class="list-group">
                <template v-for="(header, index) in sample_headers">
                  
                  <li class="list-group-item">
                    {{ header }}

                    <div class="form-group">

                      <label :for="'sample_field_'+index">Map to Field</label>
                      <select
                        :id="'sample_field_'+index"
                        class="form-control"
                        @change="mapToField(header, $event)"
                        :disabled="processing"
                      >
                        <option value=""></option>
                        <template v-for="default_field in default_fields">
                          <option
                            :value="default_field.key"
                            :disabled="form.fields[default_field.key]"
                          >
                            {{ default_field.name }}
                          </option>
                        </template>
                      </select>
                    
                    </div>
                  </li>

                </template>
                </ul>


          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h4>Map Fields</h4>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">
                <template v-if="form.fields">
                  <span class="badge badge-pill bg-secondary">{{ Object.keys(form.fields).length }} of {{ default_fields.length }} fields</span>
                </template>
              </div><!--Col-->
            </div><!--Row-->


            <form
              @submit.prevent="postForm"
            >

                <div class="form-check my-3">
                  <input
                    id="map_index"
                    v-model="form.map_index"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="map_index"
                  >Map with Column #</label>
                </div>

                <div class="alert alert-primary">
                  <template v-if="form.map_index">
                    Each field mapping should match the numerical position of the column in your file. First column is 0.
                  </template>

                  <template v-else>
                    Each field mapping should match the column header in your file. Case sensitive. Must be an exact match.
                  </template>
                </div>

              <div class="form-group">
                <label for="search_fields" class="sr-only">Search Fields</label>
                <input 
                  id="search_fields"
                  v-model="search_fields"
                  type="text"
                  class="form-control"
                  placeholder="Search Fields"
                >
              </div>

              <div class="btn-group mb-3">
                <button type="button" class="btn btn-outline-success" @click="mapDefaultFields()" :disabled="processing">Map Default Fields</button>
                <button type="button" class="btn btn-outline-danger" @click="resetFields()" :disabled="processing">Reset All Fields</button>
              </div>

                <template v-for="default_field in default_fields">
                  <template v-if="showField(default_field)">
                    <div
                      class="row"
                    >
                      <div class="col">
                        <div class="form-group">
                          <label :for="default_field.key">{{ default_field.name }}</label>
                          <span class="badge badge-primary mx-1">{{ default_field.model }}</span> 
                          <span
                            v-if="default_field.pii"
                            class="badge badge-danger"
                          >PII</span>
                          <template v-if="default_field.split">
                            <span class="badge badge-light">SPLIT</span>
                          </template>
                          <input
                            :id="default_field.key"
                            v-model="form.fields[default_field.key]"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>

                <button type="submit" class="btn btn-success" :disabled="processing" hidden>Save</button>

              </form>

            </div><!--Body-->
          </div><!--Card-->
      </div><!--Col-->

    </div><!--Row-->


  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [queries],

  data() {
    return {
      processing: false,
      uploading: false,
      errors: null,
      title: null,
      search_fields: '',
      form: {},
      queries: {},
      flows: [],
      locations: [],
      methods: [
        {title: 'update by fields', value: 'update_fields'},
        {title: 'load and replace all uploads', value: 'load_replace_all_uploads'},
        {title: 'load and replace all uploads using this template', value: 'load_replace_all_template'}
      ],
      operators: ['=', '!=', '>=', '<=', '>', '<'],
      date_formats: [],
      time_formats: [],
      file_formats: ['auto', 'csv', 'txt', 'xlsx', 'xls'],
      processing_orders: [
        {title: 'per row descending (default)', value: 'desc'},
        {title: 'per row ascending', value: 'asc'}
      ],
      delimiters: [
        {title: 'comma', key: ','},
        {title: 'pipe', key: '|'},
        {title: 'tab', key: '\t'}
      ],
      default_fields: [],
      records: [],
      sample_headers: [],
      sample_format: '',
      portals: [],
      total: 0,
      simulateForm: {
        headers_only: false,
        limit: 100
      },
      groups: [],
      automap: ''
    }
  },

  computed: {

    client_fields: function () {
      let results = _.filter(this.default_fields, ['model', 'Client']);

      results = _.filter(results, function (field) {
        return field.split !== 1;
      });

      //rm keys
      const hiddens = ['assigned_user'];

      results = results.filter(({key}) => !hiddens.includes(key));

      results = results.map(({key, name}) => ({key, name}));

      //replace name with mapped field
      results = results.map(({key, name}) => {
        if(this.form.fields[key]) {
          return {key, name: this.form.fields[key]};
        }

        return {key, name};
      });

      return results;
    },
    event_fields: function () {
      let results = _.filter(this.default_fields, ['model', 'Event']);

      results = _.filter(results, function (field) {
        return field.key !== 'event_date_time';
      });

      return results.map(({key, name}) => ({key, name}));  
    },
    case_fields: function () {
      let results = _.filter(this.default_fields, ['model', 'Case']);

      return results.map(({key, name}) => ({key, name}));
    },

    payment_fields: function () {
      let results = _.filter(this.default_fields, ['model', 'Payment']);

      return results.map(({key, name}) => ({key, name}));
    },
  },

  mounted() {

  var self = this;

  this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
  });

  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getDefaultFields();
    this.getOptions();
    this.getPortals();
    this.setGroups();
  },

  methods: {

    resetForm() {
      this.form = {
        method: 'update_fields',
        delimiter: ',',
        separator: ',',
        date_format: 'Y-m-d',
        time_format: 'H:i:s',
        require_assigned_users: false,
        notify_assigned_users: false,
        default_group: null,
        default_portal: null,
        fields_prefix: null,
        fields: {},
        map_index: 0,
        load_replace_clients: false,
        load_replace_contacts: false,
        load_replace_cases: false,
        delay_sync: false
      }
    },

    setGroups() {
      if(!this.settings || !this.settings.default_groups) {
        return;
      }

      let groups = this.settings.default_groups;

      if(!groups || !groups.length) {
        return;
      }

      this.groups = groups.split(',');

    },

    getPortals() {
      this.$http.get('/portals?slim=true&limit=150&fields=name,uuid')
          .then(response => {
            if (response && response.data) {
              this.portals = response.data;
            }
          })
    },

    getRecord() {

      this.processing = true;

      this.$http.get('/' + this.$route.meta.base_url + '/' + this.$route.params.id)
          .then(response => {
            if (response && response.data) {

              this.form = response.data;

              if (!response.data.fields) {
                this.form.fields = {};
              }

              this.setTitle();

              this.processing = false;
            }
          })
    },

    setTitle() {
      this.title = this.setCustomLabel(this.$route.meta.title);
      document.title = this.form.name + ' ' + this.title + ' | eCourtDate.com';
    },

    postForm() {

      this.processing = true;
      this.errors = null;

      this.$http.patch('/' + this.$route.meta.base_url + '/' + this.form.uuid, this.form)
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if (response.data.updated_at) {
                this.form = {...this.form, ...response.data};
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' updated'
                });
              }

            }
          })
    },

    getDefaultFields() {
      this.$axios.get(this.$root.assets_url+'js/fields.json')
          .then(response => {
            if (response && response.data) {
              this.default_fields = response.data;
            }
          })
    },

    showField(field) {

      if (field.upload !== 1) {
        return false;
      }

      if (field.key == 'event_date' || field.key == 'event_time') {
        if (this.form.fields['event_date_time'] && this.form.fields['event_date_time'].length) {
          return false;
        }
      }

      if(this.search_fields && this.search_fields.length) {
        if(field.name.toLowerCase().indexOf(this.search_fields.toLowerCase()) === -1) {
          return false;
        }
      }

      return true;
    },

    getOptions() {

      this.$http.get('/locations?slim=true&fields=name,uuid&sort=name&sortDirection=asc')
          .then(response => {
            if (response && response.data) {
              this.locations = response.data;
            }
          })

      this.$http.get('/flows?slim=true&fields=name,uuid&sort=name&sortDirection=asc')
          .then(response => {
            if (response && response.data) {
              this.flows = response.data;
            }
          })

      this.$axios.get(this.$root.assets_url+'js/options.json')
          .then(response => {
            if (response && response.data) {
              this.date_formats = response.data.date_formats.map(item => item.key);
              this.time_formats = response.data.time_formats.map(item => item.key);
            }
          })
    },

    resetFields() {
      this.form.fields = {};
    },

    mapDefaultFields() {

      this.form.fields = {};

      var count = 0;

      this.default_fields.forEach(field => {

        if (field.upload !== 1 || field.split === 1) {
          return;
        }

        var models = ['Form', 'Bond', 'Attorney'];

        if (models.indexOf(field.model) > -1) {
          return;
        }

        var excludes = ['agency', 'action'];

        if (excludes.indexOf(field.key) > -1) {
          return;
        }

        var value = field.name;

        if (this.form.map_index === true) {
          value = count;
        }

        this.form.fields[field.key] = value;

        count++;

      })
    },

  mapToField(header, event) {
      
      var field = event.target.value;
  
      if (!field) {
        return;
      }
  
      this.form.fields[field] = header;

      this.postForm();
  },

  autoMapFile() {

    var default_fields = this.default_fields.filter(field => field.upload === 1);

    default_fields = default_fields.filter(field => field.split !== 1);

    default_fields = default_fields.filter(field => field.model !== 'Form');

    default_fields = default_fields.filter(field => field.model !== 'Identity');

    var post = {
      prompt: 'Given the following sample fields: ' + this.sample_headers.join(', ') + '. Map them to the following system fields: ' + default_fields.map(field => field.name).join(', ') + '. Do not include any other content besides the field mapping in a JSON object format. Only choose a single field per option.',
      model: 'gpt-4o',
      format: 'json_object'
    }

    this.processing = true;

    this.$http.post('/generator', post)
    .then(response => {
      if(response && response.data && response.data.message) {
          this.automap = response.data.message;

          this.processing = false;

          if(!this.automap) {
            this.automap = 'No mapping found';
            return;
          }

          for (const [key, value] of Object.entries(this.automap)) {

            var field = default_fields.find(field => field.name === value);

            if(field && field.key) {
              if(key && key != 'null' && key != '') {
                this.form.fields[field.key] = key;
              }
            }

          }

          this.resetSample();

      }
    })
  },

  resetSample() {
    this.sample_headers = [];
    this.sample_format = '';
  },

  readFile(event) {

    this.resetSample();

    if (!event.target.files.length) {
      return;
    }

    const file = event.target.files[0];

    if(file.type === 'text/csv' || file.name.endsWith('.csv')) {
      this.sample_format = 'csv';
      this.form.file_format = 'csv';
      this.readCSV(event);
      return;
    }

    if(file.name.endsWith('.txt')) {
      this.sample_format = 'txt';
      this.form.file_format = 'txt';
      this.readCSV(event);
      return;
    }

    this.sample_format = 'xlsx';

    this.form.file_format = 'xlsx';

    this.readExcel(event);

  },

  readExcel(event) {

      // Ensure a file was selected
      if (!event.target.files.length) return;

      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = this.$xlsx.read(data, { type: 'binary' });

        // Use the first sheet
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON
        const sheetData = this.$xlsx.utils.sheet_to_json(sheet, { header: 1 });
        
        // Check if there's at least one row (for headers)
        if (sheetData.length) {
          // Use the first row as headers
          this.sample_headers = sheetData[0];
        }
      };

      // Read the file as binary string
      reader.readAsBinaryString(file);
    },

  readCSV(event) {

      this.sample_headers = [];

      // Ensure a file was selected
      if (!event.target.files.length) return;

      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        // Use the file content
        const content = e.target.result;
        
        // Split the content by new line to get the rows
        const rows = content.split('\n');
        
        // Check if there's at least one row (for headers)
        if (rows.length) {
          // Use the first row and split by comma for CSV
          this.sample_headers = rows[0].split(',');
          
          // Trim spaces from headers if necessary
          this.sample_headers = this.sample_headers.map(header => header.trim());
        }
      };

      // Read the file as text
      reader.readAsText(file);
    },

    setUniqueEventsClass() {
        
        if (!this.form || !this.form.uuid) {
          return;
        }
  
        if (!this.form.unique_event || !this.form.unique_event.length) {
          return 'border border-danger';
        }
  
        if (this.hasEventReference()) {
          return 'border border-success';
        }
  
        if (this.form.length >= 3) {
          return 'border border-success';
        }
  
        return 'border border-warning';
    },

    hasEventReference() {
  
      let hasEventReference = false;
  
      this.form.unique_event.forEach(field => {
  
        if (field.key == 'events.event_reference') {
          hasEventReference = true;
        }
  
      })
  
      return hasEventReference;
    },

  setUniqueClientsClass() {

    if (!this.form || !this.form.uuid) {
      return;
    }

    if (!this.form.unique_client || !this.form.unique_client.length) {
      return 'border border-danger';
    }

    if (this.hasClientReference()) {
      return 'border border-success';
    }

    if (this.form.length >= 5) {
      return 'border border-success';
    }

    return 'border border-warning';
  },

  hasClientReference() {

    let hasClientReference = false;

    this.form.unique_client.forEach(field => {

      if (field.key == 'clients.client_reference') {
        hasClientReference = true
      }

    })

    return hasClientReference;
  },

  duplicateRecord() {
    this.processing = true;
    this.$http.post('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/duplicate')
        .then(response => {
          if (response && response.data) {

            this.processing = false;

            if (response.data.uuid) {
              this.$eventBus.$emit('show-alert', {
                text: this.$route.meta.title + ' duplicated'
              });
              window.location.href = '/' + this.$route.meta.base_url + '/' + response.data.uuid;
            }

          }
        })
  },


  postSimulateFile() {

    this.processing = true;
    this.errors = null;

    this.$http.post('/' + this.$route.meta.base_url + '/' + this.form.uuid + '/simulate', this.simulateForm)
        .then(response => {
          if (response && response.status) {
            this.processing = false;

            if(response.data) {
              this.downloadCSV(response.data);              
            }
          }
        })
  },

  downloadCSV(sample_file) {

    var csvFile, data, link;

    data = this.convertCSV(sample_file);

    csvFile = new Blob([data], {type: "text/csv"});

    this.$eventBus.$emit('show-alert', {
      text: 'Downloading file ' + sample_file.file
    });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(csvFile, sample_file.file);
    } else {
      link = document.createElement("a");
      link.download = sample_file.file;
      link.href = window.URL.createObjectURL(csvFile);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
    }

  },

  convertCSV(sample_file) {

    var results = [];

    if (sample_file['headers'] && sample_file['headers'].length > 0) {
      results += sample_file['headers'].join(',')
      results += '\n';
    }

    if (!sample_file['rows'] || !sample_file['rows'].length) {
      return results;
    }

    sample_file['rows'].forEach(function (row) {
      var record = ''
      Object.keys(row).forEach(function (key) {
        record += '"' + (row[key] ? row[key] : '') + '",';
      });
      results += record
      results += '\n';
    });
    return results;
  },


  }
}
</script>
<template>
    <div>
  
      <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
      />
  
  
      <div class="row">
        <div class="col-sm-12 col-md-4">
            <h1>{{ form.name }}</h1>
            <p>
            <router-link :to="{name: 'reports.index'}">Reports</router-link> /
            <router-link :to="{name: 'dashboards.index'}">Dashboards</router-link> /
            Edit {{ $route.meta.title }}
            </p>
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4">
  
  
  
        </div>
  
        <div class="col-sm-12 col-md-4 text-end">
  
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-primary dropdown-toggle radius-right-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Tools
                </button>
                <div class="dropdown-menu">
  
                  <a
                    v-if="!form.deleted_at"
                    class="dropdown-item"
                    @click="trashRecord(form)"
                  >Trash</a>
  
  
                </div>
              
            <button
              v-if="!form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
            >
              Save
            </button>
  
            <router-link
              :to="{ name: 'dashboards.index' }"
              class="btn btn-danger"
            >
              Close
            </router-link>
  
          </div>
        </div><!--Col-->
      </div><!--Row-->
  
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Edit {{ $route.meta.title }}
              </p>
  
              <form @submit.prevent="postForm()">


<div class="form-group">
  <label for="name">Name</label>
  <input
    id="name"
    v-model="form.name"
    class="form-control"
    required
  >
</div>

<div class="form-group">
  <label for="description">Description</label>
  <textarea
    id="description"
    v-model="form.description"
    class="form-control"
    rows="3"
  />
</div>

<div class="form-group">
  <label for="data_source">Data Source</label>
  <select id="data_source" class="form-control" v-model="form.data_source" :disabled="processing">
    <option value=""></option>
    <template v-for="source_option in $root.data_sources">
      <option :value="source_option">{{ source_option }}</option>
    </template>
  </select>
</div>

<div class="form-check">
  <input
    id="show_legend"
    v-model="form.show_legend"
    type="checkbox"
    class="form-check-input"
  >
  <label
    class="form-check-label"
    for="show_legend"
    > Show Legend</label>
</div>

<div class="form-check">
  <input
    id="show_source_data"
    v-model="form.source_data"
    type="checkbox"
    class="form-check-input"
  >
  <label
    class="form-check-label"
    for="show_source_data"
    > Show Source Data</label>
</div>

<div class="form-group">
  <label for="type">Type</label>
  <select
    id="type"
    class="form-control"
    v-model="form.type"
    :disabled="processing"
  >
    <option value=""></option>
    <template v-for="data_type in data_types">
      <option
        :value="data_type"
      >
        {{ data_type }}
      </option>
    </template>
  </select>
</div>

<div class="form-group" v-if="form.type == 'sum'">
  <label for="sum_field">Sum Field</label>
  <input id="sum_field" type="text" class="form-control" v-model="form.sum_field">
</div>

<div class="form-group" v-if="form.type == 'rate'">
  <label for="data_compare">Data Compare

    <template v-if="form.data_compare">
      <a
      :href="'/datapoints/'+form.data_compare"
      target="_blank"
      data-tooltip="Edit Datapoint">
      <i class="fa fa-edit"></i> <span class="sr-only">Edit Datapoint</span>
      </a>
    </template>
  </label>
  <select 
  id="data_compare" 
  class="form-control" 
  v-model="form.data_compare"
  :disabled="processing">
    <option value=""></option>
    <template v-if="datapoints && datapoints.length > 0">
    <template v-for="datapoint in datapoints">
      <option :value="datapoint.uuid || datapoint.key">{{ datapoint.name || datapoint.key }}</option>
    </template>
    </template>
  </select>
</div>

<div class="form-group">
  <label for="date_filter">Date Filter</label>
  <select id="date_filter" class="form-control" v-model="form.date_filter" :disabled="processing">
    <option value=""></option>
      <template v-if="dataSource && date_filters[dataSource] && date_filters[dataSource].length">
        <template v-for="date_filter in date_filters[dataSource]">
          <option
            :value="date_filter"
          >
            {{ date_filter }}
          </option>
        </template>
      </template>
  </select>
</div>

<div class="form-group">
  <label for="group">Group By</label>
  <select id="group" class="form-control" v-model="form.group" :disabled="processing">
    <option value=""></option>
    <option value="any">Any</option>
      <template v-if="dataSource && group_filters[dataSource]">
        <template v-for="group_filter in group_filters[dataSource]">
          <option
            :value="group_filter"
          >
            {{ group_filter }}
          </option>
        </template>
      </template>
  </select>
</div>

<template v-if="data_groups && data_groups.length >= 0 && form.group">

<div class="form-group">
  <label for="data"><span v-if="form.data && form.data.length">{{ form.data.length }}</span> Data Groups <template v-if="form.group"><code>({{form.group}})</code></template></label><br>
  <multi-select 
    id="data"
    v-model="form.data" 
    :options="data_groups"
    :multiple="true"
    :close-on-select="false"
    :disabled="processing"
  />
</div>

</template>

<div class="btn-group" :hidden="!form.group">

<button type="button" class="btn btn-outline-secondary btn-sm mr-1 mb-3" @click="toggleGroup()" :disabled="processing">Add Custom</button>

<template v-if="form.data && form.data.length != data_groups.length">
  <button type="button" class="btn btn-outline-secondary btn-sm mb-3 mr-1" @click="addAllGroups()" :disabled="processing">Add All</button>
</template>

<template v-if="form.data && form.data.length > 0">
  <button type="button" class="btn btn-outline-secondary btn-sm mb-3" @click="removeAllGroups()" :disabled="processing">Remove All</button>
</template>

</div>


<template v-if="show_custom_group">
    <div class="input-group mb-3">
      <input type="text" class="form-control" aria-label="Add Custom Group" aria-describedby="button-custom-group" v-model="custom_group">
      <div class="input-group-append">
        <button class="btn btn-outline-success" type="button" id="button-custom-group" @click="addGroup()">Add Custom Group</button>
      </div>
    </div>
</template>

<div class="form-group">
  <label for="x_title">X axis Title</label>
  <input id="x_title" class="form-control" type="text" v-model="form.x_title">
</div>

<div class="form-group">
  <label for="y_title">Y axis Title</label>
  <input id="y_title" class="form-control" type="text" v-model="form.y_title">
</div>

<div class="form-group">
  <label for="sort">Sort</label>
  <input
    id="sort"
    v-model="form.sort"
    class="form-control"
  >
</div>

              </form>  
  
            </div><!--Body-->
          </div><!--Card-->
    
          <audit-logs
            :form="form"
            class="mt-3"
          />
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-8">

          <template v-if="dashboardLink">

            <a
            :href="dashboardLink"
            target="_blank"
            class="btn btn-outline-success btn-sm mb-3"
            ><i class="fa fa-link" /> Open Component Link</a>

            <div class="browser-container">
              <div class="browser-header">
                <div class="browser-address-bar">
                  <span class="text-muted small"><i class="fa fa-lock text-success"></i> 
                    {{ dashboardLink }}
                  </span>
                </div>
              </div>
              <div class="browser-content" style="overflow-x:auto">

                <div class="row">
                  <div class="col-sm-12 col-md-9">
                    <template v-if="form.name">
                      <h4>{{ form.name }}</h4>
                    </template>
                  </div><!--Col-->
                  <div class="col-sm-12 col-md-3 text-end">
                    <template v-if="dataSource">
                      <p class="text-muted m-0">Data Source: {{ dataSource }}</p>
                    </template>
                  </div><!--Col-->
                </div>

                <template v-if="form.description">
                    <p class="lead m-0">{{ form.description }}</p>
                </template>

            </div><!--Content-->

            </div><!--Browser Container-->

          </template>


          <p class="mt-3">
            <template v-if="form.filters">
              {{ form.filters.length }}
            </template>
            Filters
          </p>

          <button type="button" class="btn btn-outline-success btn-sm mb-3" @click="addFilter()" :disabled="processing">Add Filter</button>

          <template v-if="form.filters">
            <div v-for="(filter, filter_index) in form.filters">
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label :for="'filter_field_'+filter_index">Filter Field</label>
                  <input :id="'filter_field_'+filter_index" class="form-control" type="text" v-model="filter.field">
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label :for="'filter_operator_'+filter_index">Filter Operator</label>
                  <select :id="'filter_operator_'+filter_index" class="form-control" v-model="filter.operator">
                  <option value=""></option>
                  <template v-for="operator in operators">
                    <option :value="operator">{{ operator }}</option>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label :for="'filter_value_'+filter_index">Filter Value</label>
                  <input :id="'filter_value_'+filter_index" class="form-control" type="text" v-model="filter.value">
                </div>
              </div>
            </div><!--Row-->
            <button type="button" class="btn btn-outline-danger btn-sm mb-3" @click="removeFilter(filter_index)" :disabled="processing">Remove Filter #{{ filter_index }}</button>
            </div>
          </template>
  
        </div><!--Col-->
  
  
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
      mixins: [ queries ],
  
      data() {
          return {
          processing: false,
          form: {},
        date_filters: {
            'events': ['date', 'created_at'],
            'messages': ['scheduled_at', 'created_at'],
            'clients': ['created_at'],
            'cases': ['file_date', 'created_at'],
            'client_cases': ['file_date', 'created_at'],
            'contacts': ['created_at', 'optout_at', 'event_date'],
            'opts': ['scheduled_at', 'created_at'],
            'payments': ['scheduled_at', 'created_at']
        },
        group_filters: {
            'events': ['type', 'status', 'location', 'age', 'judge', 'weather', 'repeat', 'language', 'optin', 'virtual', 'case_class'],
            'messages': ['channel', 'direction', 'error_code', 'location', 'language', 'segments', 'credits', 'status', 'type', 'case_class', 'optin'],
            'clients': ['age', 'group', 'type'],
            'contacts': ['channel', 'valid', 'optin', 'language', 'location', 'case_class', 'type', 'optout_reason', 'delivered_messages', 'failed_messages', 'optout_at', 'event_date'],
            'opts': ['channel', 'type', 'location', 'case_class', 'user_agent'],
            'payments': ['amount', 'status', 'location', 'type'],
            'cases': ['location', 'charge_code', 'judge', 'type', 'status', 'language'],
            'client_cases': ['judge', 'type', 'status', 'language']
        },
        data_types: ['count', 'sum', 'rate'],
        operators: ['=', '!=', 'contains', 'not contains', '<', '<=', '>', '>='],
        data_groups: [],
        fields: {
            'events': ['start_date', 'start_time', 'judge', 'status', 'appeared', 'type', 'location', 'agency_name', 'language'],
            'messages': ['from', 'to', 'status', 'direction', 'channel', 'location', 'agency_name', 'language', 'type', 'error_code', 'segments', 'scheduled_at'],
            'clients': ['type', 'group', 'status'],
            'cases': ['type', 'status', 'judge', 'appeared', 'language'],
            'client_cases': ['status', 'type', 'judge', 'appeared', 'language', 'file_date'],
            'contacts': ['channel', 'type', 'valid', 'notify', 'optin', 'file_date'],
            'opts': ['channel', 'user_agent', 'location', 'agency_name', 'type'],
            'payments': ['status', 'amount', 'type', 'location', 'agency_name']
        },
        show_custom_group: false,
        custom_group: '',
        datapoints: [],
        dashboard: {}
          }
      },

      computed: {

            dataSource() {

              if(this.form && this.form.data_source) {
                return this.form.data_source;
              }

              if(this.dashboard && this.dashboard.data_source) {
                return this.dashboard.data_source;
              }

              return;
            },

            dashboardLink() {
                if(!this.dashboard) {
                    return;
                }

                return 'https://'+this.dashboard.subdomain+'.'+this.dashboard.domain+'/'+this.form.uuid+'?preview='+this.dashboard.url;
            }

            },
  
        mounted() {
          var self = this;
  
          this.$eventBus.$on('errors', function () {
              self.processing = false;
          });
        },
  
      created () {
          this.resetForm();
          this.getRecord();
          this.getDatapoints();
      },
  
      methods: {
    
          resetForm() {
              this.form = {
                  name: null,
                  filters: []
              }
  
          },
  
          getRecord() {
              this.processing = true;
              this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
              .then(response => {
                  if(response && response.data) {
                      this.processing = false;
  
                      if(response.data.uuid) {
  
                          this.form = response.data;

                          this.getDashboard();

                          if(!Array.isArray(this.form.filters)) {
                             this.form.filters = [];
                          }
  
                          this.setTitle();
  
                      }
                  }
              })
          },

          getDashboard() {

            this.dashboard = {};

            if(!this.form.dashboard) {
                return;
            }

            this.$http.get('/dashboards/'+this.form.dashboard)
            .then(response => {
                if(response && response.data) {
                    this.dashboard = response.data;
                }
            })
          },
  
          setTitle() {
            document.title = this.form.name + ' | ' + this.$route.meta.title;
          },
  
          postForm() {    
              this.processing = true;
              this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
              .then(response => {
                  if(response && response.data) {
  
                      if(response.data.updated_at) {
                          this.form.updated_at = response.data.updated_at;
                          this.$eventBus.$emit('show-alert', {
                            text: this.$route.meta.title + ' updated'
                          });
                      }
  
                      this.processing = false;
                  }
              })
          },

  
          getDatapoints() {
          this.datapoints = [];
          this.$http.get('/datapoints')
          .then(response => {
            if(response && response.data) {
              this.datapoints = response.data;
            }
          })
        },

        removeFilter(filter_index) {

          this.form.filters.splice(filter_index, 1);

          this.$eventBus.$emit('show-alert', {
            text: 'Filter removed'
          });
        },

        addFilter() {
          this.form.filters.push({
            field: 'status',
            operator: '=',
            value: ''
          });
        },

        addAllGroups() {

            this.changeForm();

            this.form.data = this.data_groups;

            var self = this;

            this.data_groups.forEach(function(group) {
            if(self.form.data.includes(group)) {
                return;
            }

            self.form.data.push(group);
            })
            },

            removeAllGroups() {

            this.changeForm();

            this.form.data = [];
            },

            toggleGroup() {

            this.changeForm();

            if(this.show_custom_group == true) {
            this.show_custom_group = false;
            return;
            }

            this.show_custom_group = true;
            },

            addGroup() {

            this.changeForm();

            if(!this.data_groups.includes(this.custom_group)) {
            this.data_groups.unshift(this.custom_group);
            }

            if(!this.form.data.includes(this.custom_group)) {
            this.form.data.unshift(this.custom_group);
            }

            this.custom_group = '';

            this.$eventBus.$emit('show-alert', {
              text: 'Custom Data Group added'
            });
            }
  
        }
  
  }
  </script>
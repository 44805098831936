<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">        
      <div class="col-12 text-end">
        <div class="btn-group">

          <template v-if="form && form.status == 'skipped'">
            <button type="button" class="btn btn-outline-success" @click="replayRecord()" :disabled="processing">Process Upload</button>
          </template>

          <template v-if="form && form.status == 'complete'">
            <button type="button" class="btn btn-success" @click="createBulkAction()" :disabled="processing">Create Bulk Action</button>
          </template>

          <button
            type="button"
            class="btn btn-secondary dropdown-toggle radius-right-0"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              @click="downloadFile"
            >Download File</a>
            <a
              class="dropdown-item"
              @click="replayRecord()"
            >Replay Upload</a>

            <a class="dropdown-item" @click="syncRecord()">Sync Upload</a>

            <template v-if="!form.deleted_at">
              <a
                class="dropdown-item"
                @click="trashRecord(form)"
              >Trash Upload</a>
            </template>
          </div>
          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->
    <div class="row mb-3">
      <div class="col-9 mx-auto text-center mt-2">
        <template v-if="form.name">
          <h4 class="m-0">
            {{ form.name }}
          </h4>
        </template>

        <template v-if="form.file">
          <p class="text-muted mr-1 m-0">
            File: <a
              class="link-hover"
              @click="downloadFile"
            >{{ form.file }}</a>
          </p>
        </template>

        <template v-if="form.template">
          <upload-template-button :uuid="form.template" />
        </template>

        <template v-if="form.current_row">

        <p class="text-muted m-0 mr-1">

          <template v-if="form.status == 'processing'">
            Current
          </template>
          <template v-else>
            Last
          </template>

          Row: {{ form.current_row }}

        </p>

        </template>

        <template v-if="form.updated_at">
          <p class="text-muted m-0 mr-1">
            Process Time: {{ setProcessTime() }}
          </p>
        </template>

        <template v-if="form.rows">
          <p class="text-muted m-0 mr-1">
            Time per Row: {{ setRowTime() }}
          </p>
        </template>

        <template v-if="form.created_at">
          <p class="text-muted m-0 mr-1">
            Created: {{ form.created_at | datetime }}
          </p>
        </template>

        <template v-if="form.updated_at">
          <p class="text-muted m-0 mr-1">
            Updated: {{ form.updated_at | datetime }}
          </p>
        </template>


        <template v-if="form.created_by">
          <p class="text-muted m-0 mr-1">
            Creator:

            <template v-if="isUUID(form.created_by) && form.sftp_creator">
                <a :href="'https://console.ecourtdate.com/sftps/'+form.created_by" target="_blank">{{ form.sftp_creator }}</a>
            </template>

            <template v-else>
              <a
                :href="'/users/'+form.created_by"
                target="_blank"
              >{{ form.created_by }}</a>
            </template>
          </p>
        </template>

        <template v-if="upload_progress && upload_progress > 0 && upload_progress !== 100">

              <div class="progress">
                <div class="progress-bar" :class="setProgressClass()" role="progressbar" :style="'width:'+upload_progress+'%;'" :aria-valuenow="upload_progress.toLocaleString()" aria-valuemin="0" aria-valuemax="100"><template v-if="upload_progress == 100">🙌</template> {{ upload_progress.toLocaleString() }}%</div>
              </div>

        </template>

        <template v-if="form.status">
          <upload-status :upload="form" />
        </template>

        <div class="row">
          
          <div class="col-9 mx-auto">

          <form @submit.prevent="getRecord">

            <div class="form-group mt-3">
              <label
                class="sr-only"
                for="search_upload"
              >Search Upload</label>
              <input
                id="search_upload"
                v-model="queries.search"
                type="search"
                class="form-control"
                placeholder="Search upload data..."
              >
            </div>

            <div class="form-group">
              <label
                class="sr-only"
                for="search_operator"
              >Search Operator</label>
              <select
                id="search_operator"
                v-model="queries.search_operator"
                class="form-control"
              >
                <template v-for="(search_operator, index) in search_operators">
                  <option
                    :value="search_operator"
                  >
                    {{ search_operator }}
                  </option>
                </template>
              </select>
            </div>

            <div class="form-group">
              <label
                class="sr-only"
                for="choose_column"
              >Choose Column</label>
              <select
                id="choose_column"
                v-model="queries.search_column"
                class="form-control"
              >
                <option value="">
                  Any column
                </option>
                <template v-for="search_column in headers">
                  <option
                    v-if="search_column != 'index'"
                    :value="search_column"
                  >
                    {{ search_column }}
                  </option>
                </template>
              </select>
            </div>

            <button
              type="submit"
              class="btn btn-primary"
              :disabled="processing"
            >
              Search
            </button>

          </form>

        </div><!--Col-->
      </div><!--Row-->

      </div><!--Col-->
    </div><!--Row-->

    <div class="row mb-3">
      <template v-for="(count, count_key) in counts">
        <div
          class="col-sm-6 col-lg-2 mb-1"
        >
          <div class="card">
            <div class="card-body">
              <h4 class="m-0">
                <span v-if="count">{{ count.toLocaleString() || 0 }}</span> <span v-else>0</span>
              </h4>

              <p class="card-title m-0 text-capitalize">
                <a
                  :href="'/'+count_key+'?upload='+form.uuid"
                  target="_blank"
                >{{ count_key }}</a>
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>

    <template v-if="form.warnings && form.warnings.length > 0">
      <template v-for="upload_warning in form.warnings">
        <div
          class="alert alert-danger"
        >
          {{ upload_warning }}
        </div>
      </template>
    </template>

    <div class="row">
      <div class="col">
        <div class="card">
          <div
            class="card-body"
            style="overflow-x:auto !important"
          >
            <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <template v-for="(header, key) in headers">
                    <th
                      v-if="header !== 'index'"
                    >
                      <span class="text-muted">{{ getField(header) }}</span>
                      <br><small>{{ header }}</small>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(row, index) in rows">
                  <tr :id="'row_'+index" :class="setRowClass(index)">
                    <th scope="row">
                      {{ row.index }}
                    </th>
                    <template v-for="(field, field_index) in row">
                      <td>
                        <template v-if="field_index != 'uuid' && field_index != 'index'">
                          <p class="mb-0">
                           
                            <template v-if="field_index == 'location'">
                              <a :href="'/locations?name='+field" target="_blank">{{ field }}</a>
                            </template>

                            <template v-else>
                              {{ field }}
                            </template>

                          </p>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="rows && !rows.length && !processing">
              <div class="alert alert-danger">
                No data found
              </div>
            </template>

            <template v-if="!loading && !processing && no_results">
              <div class="alert alert-warning">
                End of file
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="!loading && !no_results">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="loadMore"
              >
                Load more
              </button>
              <button type="button" class="btn btn-outline-primary" @click="scrollUp()" :disabled="processing"><i class="fa fa-arrow-up"></i> Scroll Up</button>
              </div>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import confetti from "canvas-confetti";
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      loading: false,
      no_results: false,
      form: {},
      template: {},
      rows: [],
      headers: [],
      counts: {},
      queries: {},
      upload_statuses: [
      {"id": 1, "status": "processing file"},
      {"id": 2, "status": "processing rows"},
      {"id": 3, "status": "processing data"},
      {"id": 4, "status": "scheduling messages"},
      {"id": 5, "status": "notifying user"},
      {"id": 6, "status": "upload complete"}
      ],
      search_operators: ['contains', '=', '<', '<=', '>', '>='],
      count_options: ['clients', 'contacts', 'events', 'cases', 'contacts', 'payments', 'forms', 'warrants'],
      sftps: []
    }
  },

  computed: {

    upload_progress: function() {
      if(!this.form.rows || !this.form.current_row) {
        return 0;
      }

      return (this.form.current_row / this.form.rows) * 100;
    }

  },

  mounted() {

    var self = this;
  
    this.$eventBus.$on(this.$route.meta.base_url, function (data) {
      self.updateRecord(data);
    });
    
  },


  created () {
    this.resetQueries();
    this.setURLparams();
    this.getRecord();
  },

  methods: {

    resetQueries() {
      this.queries = {
        search_column: '',
        search_operator: 'contains',
        search: null,
        limit: 1000,
        skip: 0
      }
    },

    setURLparams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

        this.updateURLParams();
    },

    updateURLParams() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.queries));
    },

    setProgressClass() {

      if(this.upload_progress <= 10) {
        return 'bg-danger';
      }

      if(this.upload_progress <= 20) {
        return 'bg-secondary';
      }

      if(this.upload_progress <= 40) {
        return 'bg-primary';
      }

      if(this.upload_progress <= 80) {
        return 'bg-info';
      }

      if(this.upload_progress == 100) {
        return 'bg-success';
      }
    },

    setRowClass(index) {

      if(!this.form.rows) {
        return 'bg-secondary';
      }

      if(this.form.status == 'complete') {
        return;
      }

      if(index > this.form.current_row) {
        return 'bg-secondary';
      }

      if(index == this.form.current_row) {
        return 'bg-primary';
      }

      if(index < this.form.current_row) {
        return 'bg-success';
      }
    },

    setProcessTime() {

      var diff = this.$moment.utc(this.form.updated_at).diff(this.$moment.utc(this.form.created_at), 'minutes');

      if(diff >= 60) {
        return (diff / 60).toLocaleString() + ' h';
      }

      return diff.toLocaleString() + ' m';
    },

    setRowTime() {

      if(!this.form.current_row) {
        return 0;
      }

      var diff = this.$moment.utc(this.form.updated_at).diff(this.$moment.utc(this.form.created_at), 'seconds');

      return (diff / this.form.current_row).toLocaleString() + ' s';
    },

    getSFTP() {

      if(!this.form.created_by || !this.isUUID(this.form.created_by)) {
        return;
      }

      this.$http.get('/sftps/'+this.form.created_by+'?slim=true&limit=1000&fields=data,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data && response.data.name) {
          this.form.sftp_creator = response.data.name;
        }
      })
    },

    updateRecord(data) {
      if(!this.form.uuid || !data.uuid || !data.updated_at) {
        return;
      }

      if(data.uuid != this.form.uuid) {
        return;
      }

      this.form = {...this.form, ...data};

      this.shouldCelebrate();

      this.setTitle();

      this.setCounts();
    },

    shouldCelebrate() {

      if(this.upload_progress < 100) {
        return;
      }

      if(this.form.status != 'complete') {
        return;
      }

      confetti({
        particleCount: 150
      });

      setTimeout(() => {
        confetti.reset();
      }, 10000);    

    },

    getRecord () {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/' + this.$route.params.id)
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          this.form = response.data;

          this.getCounts();

          if(this.form.name) {
            this.setTitle();
          }

          this.getSFTP();

          if(response.data.template) {
            this.getTemplate(response.data.template);
          }

          if(response.data.counts) {
            this.counts = response.data.counts;
          }

          this.getUploadRows();

        }
      })
    },

    setTitle() {
        document.title = this.form.name + ' | ' + this.$route.meta.title;
    },

    getTemplate(template) {
      this.processing = true;
      this.$http.get('/upload_templates/'+template)
      .then(response => {
        if(response && response.data) {
          this.template = response.data;
          this.processing = false;
        }
      })
    },

    getField (key) {

      if(this.template && this.template.fields && this.template.fields[key]) {
        return this.template.fields[key];
      }

      return key;

    },

    setCounts() {
      this.counts['file rows'] = this.form.rows;
    },

    getCounts() {

      if(!this.form.uuid) {
        return;
      }

      this.setCounts();

      var self = this;

      this.count_options.forEach(count => {

        self.$http.get('/'+count+'/total?upload='+self.form.uuid)
        .then(response => {
          if(response && response.status === 200) {
            self.counts[count] = response.data;
          }
        })

      });

    },

    getUploadRows () {

      this.processing = true;

      if(this.queries.search && this.queries.search.length > 0) {
        this.rows = []
        this.queries.skip = 0;
      }

      this.updateURLParams();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/rows', this.queries))
      .then(response => {
        if (response && response.data && response.data.rows && response.data.rows.length > 0) {

          this.headers = Object.keys(response.data.rows[0]);

          response.data.rows.forEach(row => {
            this.rows.push(row);
          })

          this.queries.skip = parseInt(this.queries.skip) + response.data.rows.length;
          
          this.processing = false;

        } else {
          this.no_results = true;
          this.processing = false;
        }

        if(response && response.data && response.data.warnings && response.data.warnings.length > 0) {
          this.form.warnings = response.data.warnings;
        } else {
          this.form.warnings = [];
        }
      })
    },

    loadMore() {
      this.queries.skip = this.rows.length || 0;

      if(this.queries.skip >= 1) {
        this.queries.skip++;
      }

      this.getUploadRows();
    },


    downloadFile () {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/save/' + this.form.file)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          window.open(response.data, '_blank');
        }
      })
    },

    replayRecord() {
      this.form.rows = 0;
      this.form.current_row = 0;
      this.form.status = 'queued';
      this.$eventBus.$emit('show-alert', {
        text: 'Replaying '+this.$route.meta.title
      });
      this.postForm();
    },

    syncRecord() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/sync')
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              if(response.data.message) {
                this.$eventBus.$emit('show-alert', {
                  text: response.data.message
                });
              }
            }
          })
    },

    postForm() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          if(response.data.updated_at) {
            this.form.updated_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    createBulkAction() {
      if(!this.form.name) {
        return;
      }

      this.processing = true;

      this.$http.post('/bulk_actions', {
        name: this.form.name,
        type: 'Send a Message',
        status: 'draft',
        search: {
          created_by: this.form.name,
          limit: this.counts['file rows'] || this.counts['clients'] || 1000
        }
      })
      .then(response => {
        if(response && response.data) {
          this.processing = false;

          if(response.data.uuid) {
            this.$router.push('/bulk_actions/'+response.data.uuid);
          }
        }
      })
    }
  }
}
</script>

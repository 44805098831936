<template>
<div>

<page-tabs page="setup" />

      <div class="row">
        <div class="col-6 mx-auto text-center">
          <div class="card">
            <div class="card-body">

              <h1>Refer eCourtDate ❤️</h1>
              <h3>Get free message credits with every referral</h3>

              <template v-if="$root.current_user && $root.current_user.current_agency">
                
                <h4 class="pt-5">What You Get:</h4>

                <p><i class="fa-solid fa-check"></i> When trial is activated: 1K Message Credits</p>

                <p><i class="fa-solid fa-check"></i> When plan is activated: 10K Message Credits</p>

                <h4>What Your Referral Gets:</h4>

                <p><i class="fa-solid fa-check"></i> Free 30 day trial and 100 free message credits</p>

                <p><i class="fa-solid fa-check"></i> 10K message credits with any <a href="https://ecourtdate.com/pricing" target="_blank">Subscription Plan</a></p>

                <h4>Share Your Referral Link</h4>

                <p><a :href="referralLink" target="_blank"><mark>{{ referralLink }}</mark></a></p>

              <button type="button" class="btn btn-secondary" @click="copyToClipboard(referralLink)" :disabled="processing"><i class="fa fa-copy"></i> Copy Referral Link</button>

              <form @submit.prevent="postForm()">

              <div class="form-group mt-3 pt-5">
                <label for="agency_name">Agency Name</label>
                <input id="agency_name" type="text" class="form-control" v-model="form.agency_name" required>
              </div>

              <div class="form-group">
                <label for="contact_name">Contact Name</label>
                <input id="contact_name" type="text" class="form-control" v-model="form.contact_name" required>
              </div>

              <div class="form-group">
                <label for="contact_email">Contact Email</label>
                <input id="contact_email" type="email" class="form-control" v-model="form.contact_email" required>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Send Referral Invite</button>

              </form>

              <p class="mt-5 text-muted"><a href="https://billing.ecourtdate.com/referrals" target="_blank">Track referral history here</a>.</p>

              </template>

            </div><!--Body-->
          </div><!--Card-->


        </div>
    </div>
</div>
</template>
<script>
import confetti from "canvas-confetti";

export default {

    data() {
        return {
          processing: false,
          form: {},
          customer: {}
        }
    },

    computed: {


      referralLink: function() {

        if(!this.$root.current_user) {
          return;
        }

        let ref = this.$root.current_user.current_agency.reference;

        if(this.customer && this.customer['referral_code']) {
          ref = this.customer['referral_code'];
        }

        return 'https://ecourtdate.com/sign-up?r='+ref;
      }
    },



    created () {
      this.resetForm();
      this.getCustomer();
    },

    methods: {

      resetForm() {
        this.form = {
          referral_code: null
        }
      },

      copyToClipboard(value) {
          this.processing = true;
          navigator.clipboard.writeText(value);
          this.$eventBus.$emit('show-alert', {
            text: 'Referral link copied to clipboard'
          });
          this.processing = false;
      },

      getCustomer() {
        this.customer = {};
        this.processing = true;
        this.$http.get('/agencies/customer')
            .then(response => {
              if(response && response.data) {
                this.customer = response.data;
                this.processing = false;
              }
            })
      },

      postForm() {
        this.processing = true;

        if(this.customer && this.customer['referral_code']) {
          this.form.referral_code = this.customer['referral_code'];
        }

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
            this.$eventBus.$emit('show-alert', {
              text: 'Thank you for your referral!'
            });

            this.processing = false;

            confetti({
              particleCount: 150
            });

            setTimeout(() => {
              confetti.reset();
            }, 10000);   
    
            this.resetForm();
          }
        })
      }

    }

}
</script>
<template>
  <div>
    
    <div class="row mb-3">
      <div class="col-12 col-md-4">
        <h1>{{ form.name || $route.meta.title }}</h1>
        <p class="my-1">
          <router-link :to="{name: 'events.index'}">Events</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->
      <div class="col-12 col-md-4">
        <template v-if="currentPortal && currentPortal.link && form.status != 'draft'">
          <a :href="setCalendarLink()" target="_blank" class="btn btn-primary"><i class="fa fa-external-link-alt"></i> Open Portal Link</a>
        </template>
      </div><!--Col-->
      <div class="col-12 col-md-4 text-end">
        <div class="btn-group">

          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">

            <a
              v-if="!form.archived_at"
              class="dropdown-item"
              @click="archiveRecord(form)"
            >Archive</a>
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord(form)"
            >Restore</a>
            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord(form)"
            >Trash</a>
          </div>

          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">

              <p class="card-title">Edit</p>

              <form @submit.prevent="postForm()">

                <div class="form-group">
                  <label for="name">Name</label>
                  <input id="name" type="text" class="form-control" v-model="form.name" required>
                </div>

                <div class="form-group">
                  <label for="status">Status</label>
                  <select id="status" class="form-control" v-model="form.status" :disabled="processing" required>
                    <option v-for="status in statuses" :value="status">{{ status }}</option>
                  </select>
                  <template v-if="form.status">
                    <small class="form-text text-muted">{{ status_descriptions[form.status] }}</small>
                  </template>
                </div>

                <template v-if="form.status == 'passcode'">
                  
                  <div class="form-group">
                    <label for="code">Passcode</label>
                    <input id="code" type="text" class="form-control" v-model="form.code">
                  </div>

                  <div class="btn-group mt-0 mb-3">
                      <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard(form.code)" :disabled="processing">
                        <i class="fa fa-copy"></i> Copy
                      </button>
                      <button type="button" class="btn btn-secondary btn-sm" @click="genPasscode()" :disabled="processing">
                        <i class="fa fa-random"></i> Generate
                      </button>
                  </div>
                
                </template>

                <div class="form-group">
                  <label for="portal">Portal</label>
                  <select id="portal" class="form-control" v-model="form.portal" :disabled="processing" required>
                    <option value="">Choose Portal</option>
                    <option v-for="portal in portals" :value="portal.uuid">{{ portal.name }}</option>
                  </select>
                  <template v-if="form.portal">
                    <p class="form-text text-muted"><a :href="'/portals/'+form.portal" target="_blank">Edit Portal</a></p>
                  </template>
                  </div>

                <div class="form-group">
                  <label for="url">URL</label>
                  <input id="url" type="text" class="form-control" v-model="form.url" required>
                </div>

                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-language"></i> Multilingual Fields</p>

            <form @submit.prevent="postForm()">

            <template v-for="language in languages">

            <div class="form-group">
              <label 
              :for="'description_'+language.key"
              >{{ language.label }} Description
              
                <template v-if="language.key == 'en'">
                  <span class="badge badge-primary">DEFAULT</span>
                </template>

              </label>
              <textarea 
              :id="'description_'+language.key"
              class="form-control" v-model="form.description[language.key]"></textarea>
              <button type="button" class="btn btn-success btn-sm mt-1 tooltip-right" @click="postForm()" data-bs-toggle="tooltip" :data-tooltip="'Save '+language.label" :disabled="processing"><i class="fa fa-check"></i> <span class="sr-only">Save</span></button>
            </div>              

            </template>

            <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs class="my-3" :form="form" :base_url="$route.meta.base_url" />

      </div><!--Col-->
      <div class="col-sm-12 col-md-9">
        <div class="row">
          <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-calendar"></i> Calendar Options</p>

            <form @submit.prevent="postForm()">

            <p class="lead m-0">Event Types</p>
            <p class="form-text text-muted m-0">Choose the event types to display on the calendar.</p>

            <template v-if="!processing && event_types && event_types.length == 0">
              <div class="alert alert-warning">No event types found.</div>
            </template>

            <template v-for="event_type in event_types">
            <span class="badge badge-pill link-hover my-3" :class="{'badge-primary': isEnabledType(event_type) }" @click="toggleType(event_type)">{{ event_type.name }}</span>
            </template>


            <p><button type="button" class="btn btn-secondary btn-sm" @click="form.event_types = null" :hidden="!form.event_types">
              <i class="fa fa-times"></i> <span class="sr-only">Clear</span>
            </button></p>

            <p class="lead m-0">Event Statuses</p>
            <p class="form-text text-muted m-0">Choose the event statuses to display on the calendar.</p>

            <template v-if="!processing && event_statuses && event_statuses.length == 0">
              <div class="alert alert-warning">No event statuses found. <a href="/statuses" class="alert-link" target="_blank">Create statuses here</a>.</div>
            </template>

            <template v-for="event_status in event_statuses">
            <span class="badge badge-pill link-hover my-3" :class="{'badge-primary': isEnabledStatus(event_status) }" @click="toggleStatus(event_status)">{{ event_status.name }}</span>
            </template>

            <p><button type="button" class="btn btn-secondary btn-sm" @click="form.event_statuses = null" :hidden="!form.event_statuses">
              <i class="fa fa-times"></i> <span class="sr-only">Clear</span>
            </button></p>

            <div class="form-group">
              <label for="max_past">Max Past</label>
              <input type="number" class="form-control" v-model="form.max_past" step="1" placeholder="Max Past">
              <p class="form-text text-muted">In number of days from the current day. Defaults to 14 days ago.</p>
            </div>

            <p class="lead m-0">Calendar Title Fields</p>
            <p class="form-text text-muted m-0">Choose the fields to display in the calendar event titles.</p>

            <template v-if="form.fields && form.fields.length > 0">
            
            <p class="my-3">{{ form.fields.length }} of {{ availableFields.length }} Enabled Fields</p>

            <draggable
                v-model="form.fields"
                group="fields"
                @start="drag=true"
                @end="drag=dragEnd($event)"
              >
            <template v-for="field in form.fields">
              <button class="btn btn-primary btn-sm" @click="toggleField(field)" :disabled="processing">{{ field }}</button>
            </template>
            </draggable>

            </template>

            <template v-if="availableFields && availableFields.length > 0">

              <p class="my-3">Available Fields</p>

            <template v-for="field in availableFields">
              <button class="btn btn-light btn-sm" @click="toggleField(field)" :hidden="form.fields && form.fields.includes(field)" :disabled="processing">{{ field }}</button>
            </template>

            </template>

            <br>

            <button type="submit" class="btn btn-success my-3" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        </div><!--Col-->

        <div class="col-sm-12 col-md-3">

          <template v-if="form && form.status">

          <div class="card">
            <div class="card-body">
              <p class="card-title">Subscribe URL</p>
              <p class="card-text">Automatically sync this calendar with your favorite calendar app.</p>

              <template v-if="['private', 'draft'].includes(form.status)">
                <div class="alert alert-danger">{{ form.status }} calendars cannot be subscribed to.</div>
              </template>

              <!--Tabs-->
              <ul class="nav nav-tabs mb-3">

                <template v-for="sub_calendar in sub_calendars">
                <li class="nav-item">
                  <button class="nav-link text-uppercase text-primary"
                  :class="{'active': pub_instructions == sub_calendar.url}"
                  @click="pub_instructions = sub_calendar.url">
                  {{ sub_calendar.title }}
                  </button>
                </li>
                </template>

              </ul>

              <template v-for="sub_calendar in sub_calendars">
                <div v-if="pub_instructions == sub_calendar.url">
                  <ul class="list-styled">
                    <template v-for="instruction in sub_calendar.instructions">
                      <li>{{ instruction }}</li>
                    </template>
                  </ul>
                </div>
              </template>

              <div class="input-group">
                <input type="text" class="form-control" :value="setCalendarLink()+'/events/ics'" :disabled="processing" readonly>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" @click="copyToClipboard(setCalendarLink()+'/events/ics')" :disabled="processing">
                    <i class="fa fa-copy"></i> Copy
                  </button>
                </div>
              </div>

            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">Import URL</p>

              <p class="card-text">Configure an external calendar URL to auto sync with this calendar.</p>

              <div class="form-group">
                <label for="external_url">External Calendar URL</label>
                <input id="external_url" type="text" class="form-control" v-model="form.external_url" @keyup.enter="postForm()"
                :disabled="processing">
              </div>

            </div>
          </div>

          </template>

        </div><!--Col-->

        </div><!--Row-->

        <div class="card my-3">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-12">

                <h4>{{ currentMonthName }} {{ currentYear }}</h4>

                <div class="btn-group">
                <button @click="prevMonth" class="btn btn-light" :disabled="processing">&lt; Prev</button>
                <button @click="nextMonth" class="btn btn-light" :disabled="processing">&gt; Next</button>
                </div><!--BtnGroup-->

                </div><!--Col-->
              </div><!--Row--->


            
            <div class="row week" v-for="week in computedWeeks">
                <div class="col-sm-12 col-md day" v-for="day in week" :class="setDayClass(day)">
                      {{ day.short_date }}
                  <div class="events">
                    <div class="event w-100" v-for="event in day.events">
                      <a :href="'/events/'+event.uuid" class="text-decoration-none" target="_blank">
                        {{ setEventName(event)}}
                      </a>
                    </div>
                  </div>
                </div>
            </div>
            
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
    </div><!--Row-->

</div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';
import draggable from 'vuedraggable';

export default {

components: { draggable },

    mixins: [ queries ],

    data() {
        return {
            processing: false,
            loading: false,
            form: {
              description: {
                'en': ''
              }
            },
            events: [],
            records: [],
            portals: [],
            statuses: ['draft', 'private', 'passcode', 'public'],
            event_statuses: [],
            event_types: [],
            queries: {},
            lang: 'en',
            languages: [],
            currentYear: null,
            currentMonth: null,
            availableFields: ['type', 'description', 'case_number', 'event_reference', 'end_date', 'end_time', 'status', 'appeared', 'judge_name', 'virtual', 'location_notes', 'notes'],
            status_descriptions: {
              'draft': 'Accessible only here',
              'private': 'Accessible only to logged in clients',
              'passcode': 'Accessible only with a passcode',
              'public': 'Accessible to anyone'
            },
            pub_instructions: 'office',
            sub_calendars: [
              {
                title: 'Office',
                url: 'office',
                instructions: [
                  'Go to Office 365 Calendar in your web browser.',
                  'Click "Add Calendar" and select "From Internet."',
                  'Copy and paste the URL below into the "Link to the calendar" field.',
                  'Click "Save."'
                ]
              },
              {
                title: 'Google',
                url: 'google',
                instructions: [
                  'Go to Google Calendar in your web browser.',
                  'On the left side, next to "Other calendars," click the plus sign (+) and select "From URL."',
                  'Copy and paste the URL below into the "URL of calendar" field.',
                  'Click "Add Calendar."'
                ]
              },
              {
                title: 'Apple',
                url: 'apple',
                instructions: [
                  'Go to Apple Calendar in your web browser.',
                  'Click "File" and select "New Calendar Subscription."',
                  'Copy and paste the URL below into the "Calendar URL" field.',
                  'Click "Subscribe."'
                ]
              },
              {
                title: 'Other',
                url: 'other',
                instructions: [
                  'Open your calendar app.',
                  'Find the option to subscribe to a calendar.',
                  'Copy and paste the URL below into the "URL" field.',
                  'Click "Subscribe."'
                ]
              }
            ]
        }
    },


    computed: {
      currentPortal() {
        if(!this.form.portal) {
          return;
        }

        if(!this.portals) {
          return;
        }

        var portal = this.portals.find(portal => portal.uuid == this.form.portal);

        if(!portal) {
          return;
        }

        return portal;
      },

      currentMonthName() {
      return this.$moment().month(this.currentMonth).format('MMMM');
      },

    computedWeeks() {
      const days = this.createMonth();

      const weeks = [];
      let week = [];
      days.forEach(day => {
        if (new Date(day.year, day.month, day.date).getDay() === 0 && week.length > 0) {
          weeks.push(week);
          week = [];
        }
        week.push(day);
      });
      if (week.length > 0) {
        weeks.push(week);
      }
      return weeks;
    }
    },

    mounted() {
        this.resetQueries();
    },

    watch: {
      currentMonth() {
          this.getEvents();
      }
    },

    created () {

      this.currentYear = this.$moment().year();

      this.currentMonth = this.$moment().month();

      this.getLanguages();
      this.getRecord();
      this.getPortals();
      this.getStatuses();
      this.getTypes();
    },

    methods: {

        resetQueries() {
            this.queries = {
                limit: 20,
                skip: 0,
                from_date: '',
                to_date: '',
                locations: '',
                flows: '',
                types: '',
                sort: 'date',
                sortDirection: 'asc',
                slim: false
            }
        },

        prevMonth() {
          if (this.currentMonth === 0) {
            this.currentMonth = 11;
            this.currentYear -= 1;
          } else {
            this.currentMonth -= 1;
          }
        },

        nextMonth() {
          if (this.currentMonth === 11) {
            this.currentMonth = 0;
            this.currentYear += 1;
          } else {
            this.currentMonth += 1;
          }
        },

        setCalendarLink() {

          if(!this.form || !this.form.portal) {
            return;
          }

          if(!this.currentPortal) {
            return;
          }
          
          let url = 'https://'+this.currentPortal.link+'.'+this.currentPortal.domain+'/cals/'+this.form.url;

          if(this.form.status == 'passcode') {
            url += '?code='+this.form.code;
          }

          return url;
        },

        getRecord() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if (response && response.data) {
              this.form = response.data;

              if(!this.form.description || !this.form.description['en']) {
                this.form.description = {
                  'en': ''
                }
              }

              this.setTitle();
              this.processing = false;

              this.getEvents();

            }
          })
        },

        genPasscode() {
          this.form.code = this.genRandomCode(24);
        },

        postForm() {

          if(!this.form.code && this.form.status == 'passcode') {
            this.form.code = this.genPasscode();
          }

          this.processing = true;
          this.$http.patch('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.form)
          .then(response => {
            if (response && response.data) {
              this.processing = false;

              if(response.data.updated_at) {
                this.form = {...this.form, ...response.data};
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' updated'
                });
                this.setTitle();
                this.resetEvents();
                this.getEvents();
              }
            }
          })
        },

        resetEvents() {
          this.records = [];
          this.events = [];
        },

        setTitle() {
          if(!this.form.name) {
            return;
          }

          document.title = this.form.name + ' | ' + document.title;
        },

        getEvents() {

          if(!this.form || !this.form.uuid) { return; }

            this.processing = true;

            this.queries = {};

            this.queries['from_date'] = this.$moment().tz(this.timezone).month(this.currentMonth).startOf('month').locale('en').format('YYYY-MM-DD');

            this.queries['to_date'] = this.$moment().tz(this.timezone).month(this.currentMonth).endOf('month').locale('en').format('YYYY-MM-DD');

            if(!this.form.max_past) {
              this.form.max_past = 14;
            }

            if(this.queries['from_date'] < this.$moment().tz(this.timezone).subtract(this.form.max_past, 'days').locale('en').format('YYYY-MM-DD')) {
              this.queries['from_date'] = this.$moment().tz(this.timezone).subtract(this.form.max_past, 'days').locale('en').format('YYYY-MM-DD');
            }

            if(this.form.event_types) {
                this.queries['types'] = this.form.event_types;
            }

            if(this.form.event_statuses) {
                this.queries['statuses'] = this.form.event_statuses;
            }

            this.queries.slim = true;

            this.$http.get(this.buildQueries('/events', this.queries))
            .then(response => {
                if (response && response.data) {

                this.processing = false;
                
                if(!this.events) {
                    this.events = [];
                }

                if(response.data.length === 0) {
                    return;
                }

                response.data.forEach(event => {
                    var index = this.events.findIndex(item => item.uuid === event.uuid);

                    if(index === -1) {
                        this.events.push(event);
                    } else {
                        this.events[index] = event;
                    }
                });

                this.setEvents();

            }
        })
        },

        getPortals () {
        this.$http.get('/portals?slim=true&limit=1000&sort=default&sortDirection=desc&fields=uuid,name,link,domain')
            .then(response => {
              if (response && response.data) {
                this.portals = response.data;
              }
            })
      },

        getLanguages() {
          this.$http.get('/languages')
          .then(response => {
            if (response && response.data) {
              this.languages = response.data;
            }
          })
        },

        copyLanguage(language) {
          this.form.description[language.key] = 'testing new language ' + language.key;
        },


        getDaysInMonth(month, year) {
          return this.$moment().tz(this.timezone).month(month).daysInMonth();
        },

        createMonth() {
          const daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
          
          const days = [];
          for (let i = 1; i <= daysInMonth; i++) {
            
            const date = this.$moment().tz(this.timezone).year(this.currentYear).month(this.currentMonth).date(i).toDate();

            const dayEvents = this.records.filter(event => {
            // if multi-day event
            if(event.start != event.end) {
              var start = this.$moment(event.date).tz(this.timezone).toDate();
              var end = this.$moment(event.end).tz(this.timezone).toDate();

              if(date >= start && date <= end) {
                return true;
              }
            } else {
            const eventDate = this.$moment(event.start).tz(this.timezone).toDate();
            return eventDate.getFullYear() === this.currentYear && eventDate.getMonth() === this.currentMonth && eventDate.getDate() === i;
            }
            });

            dayEvents && dayEvents.sort((a, b) => {
                return this.$moment(a.start).toDate() - this.$moment(b.start).toDate();
            });

            days.push({
              date: i,
              short_date: this.$moment(date).tz(this.timezone).format('ddd Do'),
              month: this.currentMonth,
              year: this.currentYear,
              events: dayEvents
            });
          }
          return days;
        },

        convertToTimezone(dateString, timezone) {
            return this.$moment(dateString).format('YYYY-MM-DD HH:mm');
        },

        setEvents() {

            if(!this.events) {
                return;
            }

            this.records = this.events.map(event => {
                event.start = this.convertToTimezone(event.date + ' ' + event.time, this.timezone);

                var end_date = event.end_date || event.date;

                var end_time = event.end_time || event.time;

                event.end = this.convertToTimezone(end_date + ' ' + end_time, this.timezone);
                return event;
              });
        },
        
        updateDateTimeNow() {
            this.dateTimeNow = this.$moment().tz(this.timezone).format('ddd MMMM Do YYYY, h:mm:ss a');

            setTimeout(this.updateDateTimeNow, 1000);
        },

        setDayClass(day) {
          var today = this.$moment().tz(this.timezone).format('YYYY-MM-DD');

          var date = this.$moment(day).format('YYYY-MM-DD');

          if(today === date) {
              return 'bg-primary text-white';
          }

          return '';
        },


        getTypes() {
          this.$http.get('/event_types')
          .then(response => {
            if (response && response.data && response.data.length > 0) {
              this.event_types = response.data;

              this.event_types = this.event_types.map(type => {
                return {
                  name: type.type,
                  uuid: type.type
                }
              });

            }
          })
        },

        getStatuses() {
          this.$http.get('/statuses?type=event&limit=1000&slim=true&fields=name')
          .then(response => {
            if (response && response.data) {
              this.event_statuses = response.data;
            }
          })
        },

        isEnabledType(type) {
          if(!this.form['event_types']) {
            return false;
          }

          if(this.form['event_types'].includes(type.name)) {
            return true;
          }

          return false;
        },

        toggleType(type) {

          if(!this.form['event_types']) {
            this.form['event_types'] = [];
          }

          if(this.form['event_types'].includes(type.name)) {
            var index = this.form['event_types'].indexOf(type.name);
            this.form['event_types'].splice(index, 1);
          } else {
            this.form['event_types'].push(type.name);
          }
        },

        isEnabledStatus(status) {
          if(!this.form['event_statuses']) {
            return false;
          }

          if(this.form['event_statuses'].includes(status.name)) {
            return true;
          }

          return false;
        },

        toggleStatus(status) {

          if(!this.form['event_statuses']) {
            this.form['event_statuses'] = [];
          }

          if(this.form['event_statuses'].includes(status.name)) {
            var index = this.form['event_statuses'].indexOf(status.name);
            this.form['event_statuses'].splice(index, 1);
          } else {
            this.form['event_statuses'].push(status.name);
          }
        },

        isEnabledField(field) {
          if(!this.form['fields']) {
            return false;
          }

          if(this.form['fields'].includes(field)) {
            return true;
          }

          return false;
        },

        toggleField(field) {

          if(!this.form['fields']) {
            this.form['fields'] = [];
          }

          if(this.form['fields'].includes(field)) {
            var index = this.form['fields'].indexOf(field);
            this.form['fields'].splice(index, 1);
          } else {
            this.form['fields'].push(field);
          }
        },

        setEventName(event) {

          var options = this.form.fields || [];

          if(!options || options.length === 0) {
            options = ['type', 'description', 'status'];
          }

          var result = '';

          options.forEach(field => {
            if(event[field]) {

              var value = event[field];

              if(field == 'virtual') {
                value = event[field] == true ? 'Virtual' : 'In Person';
              }

              if(['date', 'end_date'].includes(field)) {
                value = this.$options.filters.date(event[field]);
              }

              if(['time', 'end_time'].includes(field)) {
                value = this.$options.filters.time(event[field]);
              }

              if(field == 'end_date' && event['date'] == event['end_date']) {
                return;
              }

              result += value + ' ';
            }
          });

          if(event['end_date'] && event['date'] != event['end_date']) {
            result += '(MULTI-DAY)';
          }

          return result;
        },

        dragEnd(event) {
          this.postForm();
        },

    },
}
</script>
